import React, { useEffect, useState } from 'react';
import { useDato } from './modelos/auth';
import  "./theme/pages.css";
import estiloJuegos from "./theme/Juegos.module.css";
import style from "./theme/facturas.module.css"
import { useNavigate } from 'react-router-dom'
import { agregarAlCarroFacebook, compraRealizadaFacebook } from './ConfigPixel';
import { useAppSelector } from './redux/store';
import BotonBordeDegradado from './vista/components/BotonBordeDegradado';

interface DatosVacios {
  x_id_invoice:string,
  x_transaction_date:string,
  x_response:string,
  x_response_reason_text:string,
  x_bank_name:string,
  x_transaction_id:string,
  x_amount:number,
  x_currency_code:string,
  x_ref_payco:number,
  x_signature: string,
  x_approval_code: string,
  x_cod_response: number,
  x_fecha_transaccion:string
}
const ResponseePayco: React.FC = () =>  {

  const loggedIn = useAppSelector((state) => state.auth.loggedIn)
  
  const navigate = useNavigate();
  const {
    state,
  } = useDato(); 
  
  const nombre = state.nombres ? state.nombres : 'usuario';
  const [transactionData, setTransactionData] = useState<DatosVacios>({
    x_id_invoice: '',
    x_transaction_date: '',
    x_response: '',
    x_response_reason_text: '',
    x_bank_name: '',
    x_transaction_id: '',
    x_amount: 0,
    x_currency_code: '',
    x_ref_payco: 0,
    x_signature: '',
    x_approval_code: '',
    x_cod_response: 0,
    x_fecha_transaccion: ""
  });
  // const userId = useAppSelector((state) => state.auth.userId);
  const p_cust_id_cliente = '53828'; 
  const p_key = 'be8591903c02797356a40de1348a323c65d9a0a8';
  const [bandera,setBandera] = useState(false);

  const handlePrint = () => {
    window.print();
  }
  function getQueryParam (param:string){
    const query = new URLSearchParams(window.location.search);
    return query.get(param);
  }
  function getTitulo (){
    let titulo;
    
    switch (transactionData.x_cod_response) {
      case 1:
        titulo = (
          <h1 className={style.Titulo}>
            ¡PAGO EXITOSO!
          </h1>
        );
        break;
      case 2:
        // Transacción rechazada
        titulo = (
          <h1 className={style.Titulo}>
            ¡TU PAGO FUE <span style={{ color: '#D74286' }}>RECHAZADO</span>!
          </h1>
        );
        break;
      case 3:
        // Transacción pendiente
        titulo = (
          <h1 className={style.Titulo}>
            "¡TU PAGO ESTÁ PENDIENTE!"
          </h1>
        );
        break;
      case 4:
        // Transacción fallida
        titulo = (
          <h1 className={style.Titulo}>
            ¡TU PAGO FUE <span style={{ color: '#D74286' }}>FALLIDO</span>!
          </h1>
        );
        break;
      default:
        titulo = (
          <h1 className={style.Titulo}>
            "TU PAGO"
          </h1>
        );
        break;
    }
    return(
      titulo
    );
  }
  
  useEffect(() => {
      // Referencia de payco que viene por URL
      const ref_payco = getQueryParam('ref_payco');
      
      // URL REST para obtener datos de la transacción
      const urlapp = `https://secure.epayco.co/validation/v1/reference/${ref_payco}`;
  
      console.log("🚀 ~ file: RespuestaEpayco.tsx:25 ~ useEffect ~ urlapp:", urlapp)
      fetch(urlapp)
        .then((response) =>response.json())
        .then((data) =>{
          console.log(data)
          if(data.success===true){
            setBandera(true);
            setTransactionData(data.data);
            console.log("🚀 ~ .then ~ data.data.x_cod_response:", data.data.x_cod_response)
            if(data.data.x_cod_response === 1){
              console.log('opcion 1')
              compraRealizadaFacebook();
            }else{
              console.log('opcion 2')
              agregarAlCarroFacebook();
            }
          }else{
            console.error("error en traer los datos")
          }
        })
        
  }, []);
  return (
    <div id='page' style={loggedIn?{}:{height:'100%'}}> 
      <div id='toolbar_default'>
        <h1 className="title">RECIBO</h1>
      </div>
			<div id='content_default' style={{overflow:'auto'}}>
        {Object.keys(transactionData).length > 0 ?(
          <div className={style.contenedor}>
            {getTitulo()}
            <div className={style.recibo}>
              <h4 className={style.tituloComprobante}> Comprobante de pago </h4>
              <hr style={{background: '#868189',height:"0.5px"}}></hr>
              <div>
                
                <div className={style.tituloItem}>Nombre:</div>
                <div className={style.valorItem}>{nombre}</div>
              </div>
              <hr style={{background: '#868189',height:"0.5px"}}></hr>
              <div>
                <div className={style.tituloItem}>No. Factura:</div>
                <div className={style.valorItem}>{transactionData.x_transaction_id}</div>
              </div>
              <hr style={{background: '#868189',height:"0.5px"}}></hr>
              <div >
                <div className={style.tituloItem}>Banco:</div>
                <div className={style.valorItem}>{transactionData.x_bank_name}</div>
              </div>
              <hr style={{background: '#868189',height:"0.5px"}}></hr>
              <div>
                <div className={style.tituloItem}>Fecha:</div>
                <div className={style.valorItem}>{transactionData.x_transaction_date}</div>
              </div>
              <hr style={{background: '#868189',height:"0.5px"}}></hr>
              <div >
                <div className={style.tituloItem}>Estado</div>
                <div className={style.valorItem}>{transactionData.x_response}</div>
              </div>
            </div>
            <hr className={style.linea_punteada}></hr>
            <div className={style.resumen}>
              <h4 className={style.tituloComprobante}>Resumen de Pago</h4>
              <div className={style.itemResumen}>
                <div>Entrenamiento</div>
                <hr style={{width:"100%",borderBottom: "1px dotted #868189"}}></hr>
                <div>${transactionData.x_amount.toLocaleString('es-ES')} {transactionData.x_currency_code}</div>
              </div>
              <div className={style.itemResumen}>
                <div style={{textTransform:"uppercase"}}>Total</div>
                <hr style={{width:"100%",borderBottom: "1px dotted #868189"}}></hr>
                <div>${transactionData.x_cod_response===1 ? transactionData.x_amount.toLocaleString('es-ES') :0} {transactionData.x_currency_code}</div>
              </div>
            </div>
            <div className={style.triangulosFactura}></div>
            <div className={style.botones}>
              <BotonBordeDegradado style={{height:"4.5vh", width:"47%"}} onClick={handlePrint}>IMPRIMIR</BotonBordeDegradado>
              <button id='button_rosado_default' style={{height:"4.5vh", width:"47%",margin:'0'}}  onClick={() => navigate("/my/entrenamiento")}>VOLVER</button>
          </div>
          </div>
          
          ):<div>loading</div>}
			</div>
		</div>
    
      
  );
}

export default ResponseePayco;
