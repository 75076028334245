import { ProductoReferencia, aFactura } from "../../modelos/Facturas";
import { crearFactura } from "../../repositorio/controlador/useFacturas";


export interface Compra{
    id_productos: ProductoReferencia[];
    userId: string;
    nombre: string;
    telefono: string;
    codigoTelefono: string;
    email: string;
    valorPagado: number;
    direccion?: string;
    documentoIdentidad?: number;
    campaña?: string;
    tipoModena?: string;
    pruebaGratuita?: number;
    urlCompra?: string;
}

export default async function compraEfectivo(requestUrl: string, data: Compra){

    const myFactura = aFactura(
        data.id_productos, data.userId, "Aceptada", data.valorPagado, data.valorPagado > 0?"Efectivo":"Regalo",
        data.campaña,
        {
            nombre_usuario: data.nombre, telefono_usuario: data.telefono, codigo_telefono: data.codigoTelefono,
            correo_usuario: data.email, pruebaGratuita: data.pruebaGratuita, url_compra: data.urlCompra,
            direccion_usuario: data.direccion, documento_identidad: data.documentoIdentidad
        }
        // data.nombre, data.telefono, data.codigoTelefono,
        // data.email, null, null, null, null, null, data.pruebaGratuita
    );
    
    console.log(myFactura);

    const idFactura = await crearFactura(myFactura);
    console.log(idFactura);
    
    console.log('Factura creada exitosamente');

    const payloadURLSearchString = await checkoutPromocion(idFactura, data);

    console.log('payloadURLSearchString: ',payloadURLSearchString);

    await fetch(`${requestUrl}?${payloadURLSearchString}`, {mode: 'no-cors'})
        .then(() => {
            console.log("llamada correcta:", `${requestUrl}?${payloadURLSearchString}`);
        })
        .catch(err => {
            throw new Error("Error calling requestUrl", err);
        });
}

const addLeadingZero = (num: number) => ("0" + num.toString()).slice(-2);
async function checkoutPromocion(idFactura: string, data: Compra) {
        
    const fecha = new Date()

    const msg = new TextEncoder().encode(`${0}${idFactura}${data.email}`)
    const hashBuffer = await crypto.subtle.digest("SHA-256", msg);
    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string                  
    const id_regalo = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

    const payload: any = {
        x_ref_payco: "0",
        x_id_factura: idFactura,
        x_amount: data.valorPagado.toString(),
        x_fecha_transaccion: `${fecha.getFullYear()}-${addLeadingZero(fecha.getMonth()+1)}-${addLeadingZero(fecha.getDate())} ${addLeadingZero(fecha.getHours())}:${addLeadingZero(fecha.getMinutes())}:${addLeadingZero(fecha.getSeconds())}`,
        x_response: "Aceptada",
        x_transaction_id: "0",
        x_currency_code: "COP",
        x_response_reason_text: "00-Aprobada",
        x_signature: "0",
        x_cod_response: "1",
        oferta_regalo: id_regalo,
    };

    if(data.pruebaGratuita) payload.free_pass = data.pruebaGratuita;

    return (new URLSearchParams(Object.entries(payload))).toString();
}
