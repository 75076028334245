import { PayloadAction } from "@reduxjs/toolkit";
import { AuthRedux } from "../models/auth";

export const reducerUtils = {
    initialNeuronas: (state: AuthRedux, action: PayloadAction<{ cantidad: number }>) => {
        state.neuronas = action.payload.cantidad;
    },
    sumaNeuronas: (state: AuthRedux, action: PayloadAction<{ cantidad: number }>) => {
        state.neuronas += action.payload.cantidad;
    },
    modTestInicial: (state: AuthRedux, action: PayloadAction<{ condicion: boolean }>) => {
        state.tests_inicial_realizado = action.payload.condicion;
    },
}