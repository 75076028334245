import "../../../../../../theme/entrenamiento/entrenamiento.css";
import styleFirstTimeEntrenamiento from "../../../../../../theme/entrenamiento/FirstTimeEntrenamiento.module.css";

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';

import { Entrenamiento } from '../../models/Entrenamiento';
import useRegistroEntrenamiento from "../../repository/useRegistroEntrenamiento";
import VideoPlayerIOs from '../components/VideoPlayerIOs';
import { nomUsuarios, nomUsuarioEntrenamiento } from "../../../../../../datos/nombresBD";
import { paises } from "../../../../../../datos/paises";
import { useBaseDeDatos } from "../../../../../../generalUtils/repositoryUtils/useBaseDeDatos";
import { useToast } from "../../../../../../generalUtils/useToast";
import { useDato } from "../../../../../../modelos/auth";
import { RegistroEntrenamiento } from "../../../../../../modelos/entrenamiento/RegistroEntrenamiento";
import { useAppSelector } from "../../../../../../redux/store";
import Loading from "../../../../../components/Loading";
import Selector from "../../../../../components/Selector";
import { opcion } from "../../../juegos/componentes/SelectorNivel";


type Params = {
    titleEntrenamiento:string;
}

const FirstTimeEntrenamiento:React.FC  = () => {
    const currentEntrenamiento = useAppSelector((state) => state.entrenamiento.current_entrenamiento)
    const currentRegistroUsuarios = useAppSelector((state) => state.entrenamiento.current_registro_usuario)

    const {titleEntrenamiento} = useParams<Params>();
    const [entrenamiento,setEntrenamiento] = useState<Entrenamiento>();
    const [registro,setRegistro] = useState<RegistroEntrenamiento>(currentRegistroUsuarios);
    
    const [paginaActual,setPaginaActual] = useState(false);
    const [aceptaMensaje,setAceptaMensaje] = useState(currentEntrenamiento?.id === 'neurobics' ? false : true);
    const [aceptaLlamada,setAceptaLlamada] = useState(currentEntrenamiento?.id === 'neurobics' ? false : true);
    const [codigoTel,setCodigoTel] = useState<string>(null);
    const [telefono,setTelefono] = useState<number>(null);
    const [videoUrl,setVideoUrl] = useState<string|string[]>(null);
    const [hora,setHora] = useState<number>(8);
    const [amPm,setAmPm] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { crearPrimerRegistroEntrenamiento, modificarConfiguracion } = useRegistroEntrenamiento();
    const { presentMensajePersonalizado } = useToast();
    const {state} = useDato();
    const { recogerDoc } = useBaseDeDatos();
    const userId = useAppSelector((state) => state.auth.userId)
    const idTrain = useAppSelector((state) => state.activity.id_program)

    useEffect(() => {
      return () => {
        componentWillUnmount()
    }
}, [])

    const componentWillUnmount = () => {
        setVideoUrl(null)
    }
    

    const consulta = async () => {
        setLoading(true)
        const registroConsulta = {...currentRegistroUsuarios}
        if(registroConsulta.id){
            setHora(registroConsulta.horaEntrenamiento>12 ? registroConsulta.horaEntrenamiento-12 : registroConsulta.horaEntrenamiento)
            setAmPm(registroConsulta.horaEntrenamiento>12 ? 12 : 0)
            setAceptaMensaje(registroConsulta.aceptoEnvioWA ?? true)
            setAceptaLlamada(registroConsulta.aceptoLlamada ?? true)
            setTelefono(registroConsulta?.telefono)
            setCodigoTel(registroConsulta?.codigoPais)
        }else{
            setHora(8)
            setAmPm(0)
            setAceptaMensaje(true)
            setAceptaLlamada(true)
            setTelefono(state?._usuarioDato?.telefono)
            setCodigoTel(state?._usuarioDato?.codigo)
        }
        setRegistro(registroConsulta)
        setEntrenamiento(currentEntrenamiento)
        setVideoUrl(currentEntrenamiento?.ruta)

        console.log(entrenamiento, "entrenamiento");
        if(currentEntrenamiento?.tipo === "actividades"){
            setAceptaMensaje(false)
            setAceptaLlamada(false)
        }
        setLoading(false)
    }

    const HandleClick = () => {
        if(
            registro.aceptoEnvioWA !== aceptaMensaje || registro.aceptoLlamada !== aceptaLlamada ||
            registro.horaEntrenamiento !== hora+amPm ||registro.codigoPais !== codigoTel ||
            registro.telefono !== telefono
        ){
            if((aceptaLlamada || aceptaMensaje) && telefono){
                UpdateRegistro()
            }else if( aceptaLlamada || aceptaMensaje){
                presentMensajePersonalizado("Telefono invalido.")
            } else{
                UpdateRegistro()
            }
        }
        return navigate(`/my/entrenamiento/${entrenamiento?.id}`)
    }
    const UpdateRegistro = async() => {
        if(registro.id !== userId){
            let fechaSesionDate = new Date(Date.now() - 86400000)
            let resp = await recogerDoc(nomUsuarios + '/' + userId + '/' + nomUsuarioEntrenamiento + '/' + entrenamiento?.id);
            let tempRegistro = {
                id:userId,
                idEntrenamiento: entrenamiento.id,
                sesionActual:null,
                ordenSesion:1,
                actividadActual:null,
                ordenActividad:0,
                completadoEntrenamiento:false,
                completadoSesion:false,
                fechaSesion: new Timestamp(fechaSesionDate.getTime()/1000,0),
                fechaInicio: Timestamp.now(),
                aceptoEnvioWA: entrenamiento?.id === 'neurobics' ? false : aceptaMensaje,
                aceptoLlamada: entrenamiento?.id === 'neurobics' ? false : aceptaLlamada,
                horaEntrenamiento: hora+amPm,
                mensajesEnviados: 0,
                llamadasRealizadas: 0,
                telefonoCompleto: codigoTel+telefono,
                nombre: state._usuarioDato.nombres,
                telefono: telefono,
                codigoPais: codigoTel,

            } as RegistroEntrenamiento
            if( resp.data().fechaInicioGrupo) {tempRegistro.fechaInicioGrupo = resp.data().fechaInicioGrupo as Timestamp}
            if(entrenamiento.pruebaGratuita) tempRegistro.pruebaGratuita = entrenamiento.pruebaGratuita;
            await crearPrimerRegistroEntrenamiento(userId,entrenamiento?.id, tempRegistro);
        }else{
            let nuevoRegistro = {...registro}
            nuevoRegistro.aceptoEnvioWA = entrenamiento?.id === 'neurobics' ? false : aceptaMensaje
            nuevoRegistro.aceptoLlamada = entrenamiento?.id === 'neurobics' ? false : aceptaLlamada
            nuevoRegistro.horaEntrenamiento = hora+amPm
            nuevoRegistro.telefonoCompleto = codigoTel+telefono
            nuevoRegistro.codigoPais = codigoTel
            nuevoRegistro.telefono = telefono 
            if(entrenamiento.pruebaGratuita) nuevoRegistro.pruebaGratuita = entrenamiento.pruebaGratuita
            await modificarConfiguracion(userId,entrenamiento?.id,nuevoRegistro);
        }
        return navigate(`/my/entrenamiento/${entrenamiento?.id}`)
    }

    useEffect(() => {
        consulta()
        setVideoUrl(currentEntrenamiento?.ruta);
      }, [idTrain,titleEntrenamiento, entrenamiento, currentEntrenamiento])

    useEffect(() => {
        consulta();
        setPaginaActual(true)
        
    },[]);

    useEffect(() => {
        console.log(currentEntrenamiento?.ruta);
        setVideoUrl(currentEntrenamiento?.ruta);
    },[currentEntrenamiento]);



  return (
    <div className={styleFirstTimeEntrenamiento.page}>
        <Loading isOpen={loading}/>
        <div className={styleFirstTimeEntrenamiento.header}>
            <div className={styleFirstTimeEntrenamiento.toolbar}>
                <div>
                    <p className='titulo'> {entrenamiento?.titulo} </p>
                </div>
            </div>
        </div>
        <div className={'contenedorVideoFisrtTime'} >
            {videoUrl !== null && paginaActual && <VideoPlayerIOs videoUrl={videoUrl}  autoplay={true}/>}
        </div>
        <div className={styleFirstTimeEntrenamiento.content}>
            <div style={{height:'fit-content',width:'100%', display:'flex',flexDirection:'column'}}>
                <div className={'descripcionEntrenamiento'}>
                    <p style={{color:'#512362',textAlign:'center',fontFamily: 'Montserrat',fontSize:'1.25rem',fontWeight:'700'}}>{entrenamiento?.subtitulo}</p>
                    <div className='linea' style={{backgroundColor:'#512362',width:'100%'}}></div>
                    <br></br>
                    <p style={{textAlign:'center'}}>{entrenamiento?.descripcion}</p>
                </div>
                {entrenamiento?.tipo === "sesiones" && 
                    <>
                        {currentEntrenamiento?.id && 
                        <>
                        <div style={{marginLeft:'5%',display:'grid',justifyItems:'start',alignItems:'center',gridTemplateColumns:'0.25fr 2fr', width:'95%',rowGap:'2vh'}}>
                            
                            <div className='checkmarkPersonalizada'onClick={() => setAceptaMensaje((state) => !state)}>
                                {aceptaMensaje && <img src='/assets/icon/chuloMorado.svg' style={{height:'150%',width:'150%',position:'relative',top:'-50%',right:'-20%',maxWidth:'none'}}></img>}
                            </div>
                            <label style={{fontSize:'0.75rem'}}>Acepto que SuperCerebros me envíe mensajes y notificaciones del entrenamiento</label>
                        </div>
                        <br></br>

                        <div style={{marginLeft:'5%',display:'grid',justifyItems:'start',alignItems:'center',gridTemplateColumns:'0.25fr 2fr', width:'95%',rowGap:'2vh'}}>
                            
                            <div className='checkmarkPersonalizada'onClick={() => setAceptaLlamada((state) => !state)}>
                                {aceptaLlamada && <img src='/assets/icon/chuloMorado.svg' style={{height:'150%',width:'150%',position:'relative',top:'-50%',right:'-20%',maxWidth:'none'}}></img>}
                            </div>
                            <label style={{fontSize:'0.75rem'}}>Acepto que SuperCerebros me llame para motivarme si llevo varios días sin entrenar</label>
                            <br></br>
                        </div>


                        <div className='expand-div' style={( aceptaMensaje || aceptaLlamada)?{height:'fit-content',padding:'2vh'}:{height:'0vh',padding:'0vh'}}>
                            <p style={{color:'#512362',textTransform:'uppercase',fontWeight:'900',margin:'0'}}>*confirma tu telefono</p>
                            <br></br>
                            <div style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'center',gap:'2%'}}>
                                <div style={{width:'30%'}}>
                                    <Selector setSelection={setCodigoTel} selection={codigoTel} opciones={paises} showValues={true} style_contain={{border:'1px solid var(--color-Pink-Light)'}}/>
                                </div>
                                <input type='number' placeholder='Ejemplo: 3124567890' value={telefono} onChange={(e) => setTelefono(Number(e.target.value))} className={styleFirstTimeEntrenamiento.inputPersonalizado2}></input>
                            </div>
                            <br></br>
                            <p style={{color:'#512362',textTransform:'uppercase',fontWeight:'600',margin:'0'}}>*¿A qué hora prefieres recibir recordatorios para tu entrenamiento?</p>
                            <br></br>
                            <div style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'flex-end'}}>
                                <div style={{width:'20%'}}>
                                    <Selector setSelection={setHora} selection={hora} opciones={
                                        Array.from({ length: 12 }, (_, index) => ({ name: index + 1, value: index+1 } as opcion))
                                    } style_contain={{border:'1px solid var(--color-Pink-Light)'}}/>
                                </div>
                                <p style={{fontSize:'5vh',fontWeight:'900',margin:'0 1vh',color:'#512362'}}>:</p>
                                <div style={{width:'20%'}}>
                                    <Selector setSelection={setAmPm} selection={amPm} opciones={
                                        [{name: 'AM', value: 0} as opcion, {name:'PM' , value : 12} as opcion]
                                    } style_contain={{border:'1px solid var(--color-Pink-Light)'}}/>
                                </div>
                            </div>
                            <br></br>
                            <label style={{display:'flex',justifyContent:'flex-end',marginTop:'1vh',marginBottom:'1vh',fontSize:'0.75rem'}}>
                                *A esta hora enviaremos un recordatorio para que ingreses a tu sesion diaria
                            </label>
                        </div>
                        <label style={{display:'flex',justifyContent:'flex-end',marginTop:'1vh',marginBottom:'1vh',fontSize:'0.75rem'}}>
                            *Campos obligatorios
                        </label>
                        </>}
                    </>
                    }
                <div style={{display: 'flex',width:'100%',justifyContent:'center',marginBottom:'7svh',color:'white'}}>
                    <button className={styleFirstTimeEntrenamiento.buttonOk}
                    style={{width:'35%'}}
                    onClick={() => {HandleClick()}}
                    >
                    Ok
                    </button>
                </div>
            </div>
        </div>        
    </div>
  )
}

export default FirstTimeEntrenamiento
