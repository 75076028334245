import React, { useContext } from 'react';

/**
 * Manejo de autenticacion y todos los contextos
 */

/**
 * Interfaz para autenticacion
 */
interface Auth {
    loggedIn : boolean;
    creatingUsers: boolean;
    userId? : string;
}

/**
 * Interfaz para autenticacion initializacion
 */
interface AuthInit {
    loading : boolean;
    auth? : Auth;
}

export interface DatoInfo {
    dato : boolean;
    init : boolean;
}

export const AuthContext = React.createContext({ loggedIn : false, creatingUsers: false},);
export const DatoContext = React.createContext(null);

export function useAuth() : Auth {
    return useContext(AuthContext);
}

export function useDato() {
    return useContext(DatoContext);
}

