import React, { CSSProperties } from 'react'
import styleFirstTimeEntrenamiento from "../../theme/entrenamiento/FirstTimeEntrenamiento.module.css";

interface Props{
    isOpen:Boolean;
    backgroundColor?: string;
}

const Loading:React.FC<Props> = (props) => {
  return (
    <div className={styleFirstTimeEntrenamiento.svg_loader}
      style={props.isOpen === true ? {display:'flex', backgroundColor: (props.backgroundColor ?? ""),top:'0'}:{display:'none', backgroundColor: (props.backgroundColor ?? ""),top:'0'}}>
        <svg className={styleFirstTimeEntrenamiento.svg_container} height="100" width="100" viewBox="0 0 100 100">
            <circle className={`${styleFirstTimeEntrenamiento.loader_svg} ${styleFirstTimeEntrenamiento.bg}`} cx="50" cy="50" r="45"></circle>
            <circle className={`${styleFirstTimeEntrenamiento.loader_svg} ${styleFirstTimeEntrenamiento.animate}`}  cx="50" cy="50" r="45"></circle>
        </svg>
    </div>
  )
}

export const Spinner = ({style}:{style?:CSSProperties} ) => 
  <svg className={styleFirstTimeEntrenamiento.svg_container} style={style} height="100" width="100" viewBox="0 0 100 100">
    <circle className={`${styleFirstTimeEntrenamiento.loader_svg} ${styleFirstTimeEntrenamiento.bg}`} cx="50" cy="50" r="45"></circle>
    <circle className={`${styleFirstTimeEntrenamiento.loader_svg} ${styleFirstTimeEntrenamiento.animate}`}  cx="50" cy="50" r="45"></circle>
  </svg>

export default Loading
