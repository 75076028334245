import React, { useState } from "react";
import  "../../../../../../theme/main.css"; 
import "../../../../../../theme/MemoriaNumeros.css";

interface PropUserInputs{
    userInputs : string[];
    guardaInput: (event: any)  => void;
    inputType:"number" | "text";
    longitudInput?: string[];
}
const UserInputs:React.FC<PropUserInputs> = (props) => {
    const [values, setValues] = useState<string[]>([]);
    const handleNumeroInput = (event: any) => {
        if(props.longitudInput && event.target.value % 1 === 0){
            const indice = Number(event.target.id.split('-')[1]);
            const inputValue = event.target.value;
            let maxLength = props.longitudInput ? props.longitudInput[indice].length : undefined;

        
            if (maxLength && inputValue.length > Number(maxLength)) {
            event.target.value = inputValue.slice(0, Number(maxLength));
            }
        
            const updatedValues = [...values];
            updatedValues[indice] = event.target.value;
            setValues(updatedValues);
            props.guardaInput(event.target);
        }else{
            props.guardaInput(event);
        }
        
    };
  
    return (
        <div className="contenedorInputs" >
            {props.userInputs.map((element, index) => (
                <div key={index} id="inputs">
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <p className="numeracion_inputs">{index + 1}</p>
                        <div style={{width:'60%',display:'flex',justifyContent:'center', alignItems:'center'}}>
                            <input
                                type={props.inputType}
                                inputMode={props.inputType === "number" ? "numeric" : "text"} 
                                pattern={props.inputType === "number" ? "[0-9]*" :"[A-Za-z]+"} 
                                className="inputsJuego"
                                onChange={   handleNumeroInput}
                                id={'input-' + index}
                                placeholder={"Respuesta aquí"}
                                
                            ></input>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
export default UserInputs;