
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// estilos
import styleRecorrerValores from "../../../../theme/programas/cuestionarios/recorrerValores.module.css";
import style2 from "../../../../theme/Juego2.module.css";
import styleJuegos from "../../../../theme/Juegos.module.css";

import RecorrerValoresAsociados from '../componentes/RecorrerValoresAsociados';
import { obtenerMensaje } from '../../../utils/resultadoUtil';
import { useAppSelector } from '../../../../redux/store';
import BotonesJuegos from '../../../components/BotonesJuegos';
import Loading from '../../../components/Loading';
import useRegistroEntrenamiento from '../../rutasPrivadasMy/entrenamiento/repository/useRegistroEntrenamiento';

type Params = {
  limit:string;
}

interface Preguntas{
  codigo: number[];
  opcion1: number[];
  opcion2: number[];
  opcion3: number[];
  opcionSeleccionada:number;
}

const JuegoValoresAsociados = () => {
  const {limit} = useParams<Params>();
  const [preguntas,setPreguntas] = useState<Preguntas[]>([]);
  const [posicion, setPosicion] = useState(0);
  const [loading, setLoading] = useState(false);
  const [correctas, setCorrectas] = useState(0);
  const inicialValorNumero = -1;
  const navigate = useNavigate();
  const [etapa, setEtapa] = useState<number>(inicialValorNumero);
  const [tipoPregunta, setTipoPregunta] = useState<string>('');
  const [tipoRespuesta, setTipoRespuesta] = useState<string>('');
  const userId = useAppSelector((state) => state.auth.userId);
  const idEntrenamiento = useAppSelector((state) => state.entrenamiento.current_entrenamiento.id);
  const indiceSesion = useAppSelector((state) => state.entrenamiento.current_sesion);
  const indiceActividad = useAppSelector((state) => state.entrenamiento.current_actividad);
  const esEntrenamiento = useAppSelector((state) => state.activity.id === "entrenamiento");
  const parametro = useAppSelector((state) => state.activity.tiene_bloqueo);
  const lenghtActividades = useAppSelector((state) => state.entrenamiento.current_actividades?.length);
  const emailUsuario = useAppSelector((state) => state.auth.email);

  const{
    actualizarRegistroEntrenamiento,
  } = useRegistroEntrenamiento();

  let opciones = [0,1,2,3,4,5,6,7,8,9]

  const APrimeraPantalla = () => {
    setPosicion(0)
    setCorrectas(0)
    setPreguntas([])
    ASegundaPantalla()
  }

  const ASiguientePantalla = (id: number) => {
    setEtapa(id)
  }

  const ASegundaPantalla = () => {
    setPreguntas([])
    SetPreguntasAleatorias()  
 }

  const GetDescripcion = () => {
    return (
      <div>
        <h2>Resuelve las 10 preguntas entre los 100 posibles letras o numeros a aparecer. </h2> 
        <br/> <br/>
      </div>
    );
  }

  const CompararAleatoriamente = (a:number,b:number) =>{
    return Math.random() - 0.5;
  }

  const SetPreguntasAleatorias = () => {
    opciones.sort(CompararAleatoriamente);
    let cuestionario : Preguntas[] = [];
    for(let i = 0; i<10;i++){
      let nuevaPregunta: Preguntas = {
        codigo: [-1,-1],
        opcion1: [0,0],
        opcion2: [0,0],
        opcion3: [0,0],
        opcionSeleccionada: -1,
      }
      nuevaPregunta = SetOpcionesRespuestas(nuevaPregunta,opciones)
      cuestionario.push(nuevaPregunta)
    }
    setPreguntas(cuestionario)
    if(esEntrenamiento){
      setEtapa(2)
    }else{
      setEtapa(2)
    }
  }

  const SetSeleccion = (opcion:number) =>{
    let copy = preguntas
    copy[posicion].opcionSeleccionada = opcion
    setPreguntas(copy)
  }

  const SetOpcionesRespuestas = (pregunta:Preguntas,opciones:number[]) =>{
    const seleccionada = opciones[0]
    const opcionDentro = Math.floor(Math.random() * 6)
    opciones.shift();
    pregunta.codigo = [seleccionada,opcionDentro];

    let tresOpciones = []

    tresOpciones = ObtenerTresOpciones(seleccionada,tresOpciones)
    tresOpciones.sort(CompararAleatoriamente)

    pregunta.opcion1 = tresOpciones[0]
    pregunta.opcion2 = tresOpciones[1]
    pregunta.opcion3 = tresOpciones[2]

    return pregunta
  }
  
  const ObtenerTresOpciones = (seleccionada:number,tresOpciones:number[][]) =>{
    const opcionDentro = Math.floor(Math.random() * 6)
    if(tresOpciones.length === 3){
      return tresOpciones
    }
    if(tresOpciones.length === 0){
      tresOpciones.push([seleccionada,opcionDentro])
      return ObtenerTresOpciones(seleccionada,tresOpciones)
    }else{
      const opcionAleatoria =  Math.floor(Math.random() * 10) + Number(limit)
      if(tresOpciones.length === 1){
        if(tresOpciones[0][0] !== opcionAleatoria){
          tresOpciones.push([opcionAleatoria,opcionDentro])
          return ObtenerTresOpciones(seleccionada,tresOpciones)
        }else{
          return ObtenerTresOpciones(seleccionada,tresOpciones)
        }
      }else{
        if(tresOpciones[0][0] !== opcionAleatoria && tresOpciones[1][0] !== opcionAleatoria){
          tresOpciones.push([opcionAleatoria,opcionDentro])
          return ObtenerTresOpciones(seleccionada,tresOpciones)
        }else{
          return ObtenerTresOpciones(seleccionada,tresOpciones)
        }

      }
    }
  }

  useEffect(() => {
    if(etapa === 3){
      preguntas.map((element,index) => {
        if(preguntas[index].codigo[0] === Verificar(index)){
          setCorrectas((state) => state+1)
      }
      })
      setEtapa(4)
    }
  }, [etapa]);
    
  const Verificar = (index:number) => {
    let opcion = -1;
    if(preguntas[index].opcionSeleccionada === 0){
      opcion = preguntas[index].opcion1[0]
    }
    if(preguntas[index].opcionSeleccionada === 1){
      opcion = preguntas[index].opcion2[0]
    }
    if(preguntas[index].opcionSeleccionada === 2){
      opcion = preguntas[index].opcion3[0]
    }
    return opcion
  }

  useEffect(() => {  
    setPosicion(0)
    setCorrectas(0)
    setPreguntas([])
    ASegundaPantalla()
  }, []);  

  const HandleBackButtonClick = async() => {
    if(correctas>=parametro){
      actualizarRegistroEntrenamiento(emailUsuario,userId,idEntrenamiento,indiceSesion+1,indiceActividad+1);
      navigate(lenghtActividades === 1 ? `/my/entrenamiento/${idEntrenamiento}` : `/my/entrenamiento/${idEntrenamiento}/${indiceSesion}`);
    }else{
      navigate(lenghtActividades === 1 ? `/my/entrenamiento/${idEntrenamiento}` : `/my/entrenamiento/${idEntrenamiento}/${indiceSesion}`);
    }
  }

  return (
    <div id='page'>
        <Loading isOpen={loading}/>
        <div id='toolbar_default'>
          <button className={styleJuegos.backJuegos} style={{position:'absolute',left:'5%',height:'2.5%'}} onClick={HandleBackButtonClick}></button>
          <h1 className={styleJuegos.tituloJuegos}>Cuestionario </h1> 
        </div> 
        <div id='content_default'>
            {etapa === 1 &&
              <>
                <div className="ion-text-center" >
                  <button  className={style2.btnPlay} onClick={ASegundaPantalla}>
                    <img className={style2.playIcon} src='/assets/icon/playCircleOutline.svg'></img>
                  </button>
                </div>
                {GetDescripcion()}                  
              </>
            }
            {etapa === 2 &&
              <RecorrerValoresAsociados tipoPregunta={tipoPregunta} tipoRespuesta={tipoRespuesta} setSeleccion={SetSeleccion} setPosicion={setPosicion}aSiguientePantalla={ASiguientePantalla} posicion={posicion} preguntas={preguntas[posicion]} setPreguntas={setPreguntas}/>
            }
            {etapa === 4 &&
                <>
                  <div className={style2.contenido} style={{margin:'15%',display:'flex',flexDirection:'column',gap: '20%'}}>
                    <div> 
                      <p className="titulo" style={{color:'var(--color-purple-dark)'}}>Resultado</p>
                    </div>
                      <p className={
                        correctas >=7 ?
                        styleRecorrerValores.resultado2 : correctas >=5 ?
                        styleRecorrerValores.resultado1 : styleRecorrerValores.resultado0
                      }>{`${correctas}/${10}`}</p>
                      <p className={`${correctas >=7 ?
                        styleRecorrerValores.resultado2 : correctas >=5 ?
                        styleRecorrerValores.resultado1 : styleRecorrerValores.resultado0
                      } ${styleRecorrerValores.mensajePersonalizado}`}>{obtenerMensaje(correctas/10)} 
                      </p>
                      <p style={{textAlign:'center',width:'100%',fontSize:'2vh',padding:'0 10%',fontWeight:'400',color: 'var(--color-Medium-Grey)'}}>
                        {correctas>=parametro ? "¡Felicidades ya puedes continuar con tu entrenamiento!":`¡Lastima necesitas ${parametro} correctas!`}
                      </p>
                      {esEntrenamiento && parametro!==0 && <p className={styleRecorrerValores.mensajeResultado} >
                        {`¡Obtuviste ${correctas} respuestas correctas y ${10-correctas} respuestas incorrectas, se necesitan ${parametro} para continuar!`}
                      </p>}
                  </div>
                  {((esEntrenamiento && (correctas<parametro || parametro==0)) || !esEntrenamiento )&& <BotonesJuegos funcionReiniciar={APrimeraPantalla} funcionVolver={HandleBackButtonClick} esJuego={false} esEntrenamiento={esEntrenamiento}/>}
                  {esEntrenamiento && parametro!==0 && correctas>=parametro && 
                  <div style={{width:'50%',height:'8vh',margin:'5% 0'}}>
                    <button id='button_rosado_default' style={{height:'6vh'}} onClick={HandleBackButtonClick}>Listo</button>
                  </div>}
                </>
            }

        </div>
    </div>
  )
}

export default JuegoValoresAsociados
