import { useEffect } from "react";

// JuegoCodificacionPage.tsx
import style from "../../../../../theme/programas/cuestionarios/juegoCodificacion.module.css";
import styleGame2 from "../../../../../theme/Juego2.module.css";
import estiloJuegos from "../../../../../theme/Juegos.module.css";

import { useJuegoCodificacion } from "./hooks/useJuegoCodificacion";
import Loading from "../../../../components/Loading";
import MostrarResultados from "../../../rutasPrivadasMy/juegos/componentes/MostrarResultados";


const JuegoCodificacionPage = () => {
    const {
        etapa,
        loading,
        palabras,
        palabrasAPreguntar,
        arrayBooleanCorrectas,
        inputsUsuario,
        indicePalabraAMostrar,
        LayoutCalculator,
        HandleBackButtonClick,
        HandleInput,
        GetMensajes,
        ContarRespuestas,
        HandleEmpezarJuego,
        getPalabraWrapper,
        GenerarArrayResultadosAMostrar
    } = useJuegoCodificacion();

    useEffect(() => {
        if(etapa === 0) HandleEmpezarJuego();
    }, [etapa])

    return (
        <div id='page'>
            <Loading isOpen={loading}/>
            <div id='toolbar_default' className={style.toolbar}>
                <div className={estiloJuegos.backJuegos} onClick={() => HandleBackButtonClick()} />
                <h1 className="title">Cuestionario</h1>
            </div>
            <div id='content_default'>
                {etapa === 0 && <div>
                    <button onClick={HandleEmpezarJuego}>Empezar</button>
                </div>}
                {etapa === 1 && <div className={style.containWindow_1}>
                    <label className={`${style.frasePrincipal_codify} ${style.frasePrincipal}`}>
                        {palabras[palabrasAPreguntar[indicePalabraAMostrar]]}
                    </label>
                    <div style={{ display: 'flex', gap: '2svh' }}>
                        <input
                            type={'text'}
                            readOnly
                            style={{ color: 'var(--color-Dark-Grey)', textAlign: 'center', fontSize: '3svh', fontWeight: '700' }}
                            value={inputsUsuario[indicePalabraAMostrar]}
                            onChange={(e) => HandleInput(e, e.target.value)}
                        />
                    </div>
                    {LayoutCalculator()}
                </div>}
                {etapa === 2 && <div style={{ width: '100%', margin: '3svh 0', height: "100svh" }}>
                    <div className={styleGame2.contenido} style={{ padding: `0 5% ${ContarRespuestas <= 0 ? "5%" : ""}`, height: "84%", display: "flex", flexDirection: "column" }}>
                        {GetMensajes()}
                        <MostrarResultados 
                        arrayAMostrar={GenerarArrayResultadosAMostrar()} 
                        calificaciones={[null,...arrayBooleanCorrectas]}
                        styleContenedorTabla={{maxHeight:'50svh',margin:'4% 2%'}}
                        />
                        <div style={{
                            display: "grid", gridAutoFlow: "column", gridTemplateRows: "auto auto",
                            justifyContent: "space-evenly", alignItems: "center"
                        }}>
                            <p className={styleGame2.textoTuviste}>tuviste</p>
                            <p className={styleGame2.resultado}>{`${ContarRespuestas}/${10}`}</p>
                            <button
                                className={estiloJuegos.buttonPlayAgain}
                                style={{
                                    color: "white", fontSize: "1.2rem", fontWeight: "600",
                                    maxHeight: "3rem", padding: "0 2rem",
                                    border: "none", borderRadius: "0.8rem",
                                    gridRow: "span 2"
                                }}
                                onClick={HandleBackButtonClick}>
                                {ContarRespuestas > 0 ? "Finalizar" : "Volver a Jugar"}
                            </button>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default JuegoCodificacionPage;
