import { getFunctions, httpsCallable } from 'firebase/functions';

export interface CallMessageData {
  idTemplateLlamada?: string;      // Opcional
  telefono: string;         // Obligatorio
  variablesMensaje?: object;       // Opcional
  variablesCorreo?: object;       // Opcional
  realizarLlamada?: boolean; // Opcional
  urlAudio?: string;        // Opcional
  idTemplateCorreo?:string;
  correo?: string;
}

export const CallSendCallMessage = async (data: CallMessageData) => {
  const functions = getFunctions();
  const sendCallMessage = httpsCallable(functions, 'SendCallMessageCallable');

  try {
    const response = await sendCallMessage({
      idTemplateLlamada: data.idTemplateLlamada || null,
      telefono: data.telefono,
      variablesCorreo: data.variablesCorreo || null,
      variablesMensaje: data.variablesMensaje || null,
      realizarLlamada: data.realizarLlamada || false,
      urlAudio: data.urlAudio || null,
      correo: data.correo || null,
      idTemplateCorreo: data.idTemplateCorreo || null,
    });

    console.log('Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error calling sendCallMessage:', error);
    throw error;
  }
};