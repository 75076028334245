import CodImagenes from '../../../../../../datos/DatosCodigoPalabras';
import { useJuegoCodigodePalabrasHooks } from '../hooks/useJuegoCodigodePalabrasHooks';
import { Preguntas } from '../models/modelsCodigoPalabras';

export const useLogicaJuegoCodigoPalabras = () => {
  const {
    preguntas,
    index,
    limit,
    seleccionada,
    opciones,
    setObjetoDescripcion,
    setOpenDescripcion,
  } = useJuegoCodigodePalabrasHooks();

  const Descripcion = (objeto: number) =>{
    setOpenDescripcion(true);
    setObjetoDescripcion(objeto)
  }

  const ScrollLeft = (index:number) => {
    if (index > 0) {
      let referenciaFicha = document.getElementById(`ficha${index - 1}`);
      if (referenciaFicha) {
        referenciaFicha.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
        return true;
      }
    }
  };

  const ScrollRight = (index:number,preguntas:Preguntas[]) => {
      console.log("🚀 ~ ScrollRight ~ preguntas.length:", preguntas.length)
      console.log("🚀 ~ ScrollRight ~ index:", index)
    if (index < preguntas.length - 1) {
      let referenciaFicha = document.getElementById(`ficha${index + 1}`);
      if (referenciaFicha) {
        referenciaFicha.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
        });
        return true;
      }
    }
  };

    const VerificarRespuesta = (preguntas:Preguntas[]) => {
        let count = 0;
        for (let i = 0; i < preguntas.length; i++) {
        if (preguntas[i].codigo === preguntas[i].opcionSeleccionada) {
            count++;
        }
        }
        return(count);
    };

    const GenerarArrayImages = (preguntas:Preguntas[]) => {
        let imgs = []
        preguntas.forEach((element) => {
        imgs.push(<img src={`assets/images/fichas/_${element.codigo}.png`}></img>)
        })
        return(
        imgs
        )
    }

    const GenerarArrayCalificaciones = (preguntas:Preguntas[]) => {
        let array = preguntas.map((e)=> e.codigo === e.opcionSeleccionada)
        array.unshift(null)
        return array
    }

    const GenerarArrayResultadosAMostrar = (preguntas:Preguntas[]) => {
        const opcionesSeleccionadas = preguntas.map((e) => CodImagenes.getName(e.codigo));
        const arrayWord = ['Nombre', ...opcionesSeleccionadas];
        const arrayNumeros = preguntas.map((e) => e.codigo > 99 ? `0${(e.codigo-100).toString()}` : e.codigo.toString());
        const arrayCodeWord = ['Numero', ...arrayNumeros];
        const arrayInputs = preguntas.map((e) => {return e.opcionSeleccionada === -1 ? '#': e.opcionSeleccionada > 99 ? `0${(e.opcionSeleccionada-100).toString()}` : e.opcionSeleccionada.toString()});
        const arrayInputUser = ['Respuesta', ...arrayInputs];
        let array = [arrayWord,arrayCodeWord,arrayInputUser]
        return array
    }

    const HandleClick = (preguntas:Preguntas[],index:number,opcion: number, botonSeleccionada: number) => {
        preguntas[index].opcionSeleccionada = opcion;
        const nuevaSeleccionada = [...seleccionada];
        nuevaSeleccionada[index] = botonSeleccionada;
        return nuevaSeleccionada
    };

  const RandomNumero = () => {
    if (Number(limit) < 110) {
      if (Number(limit) < 10) {
        return Math.floor(Math.random() * 10);
      }
      if (Number(limit) > 50) {
        return Math.floor(Math.random() * (Number(limit)-50 === 0 ? 10 : Number(limit)-50)) + 50;
      }
      return Math.floor(Math.random() * Number(limit));
    } else {
      return Math.floor(Math.random() * 110);
    }
  };

  const SetOpcionesRespuesta100 = (pregunta: Preguntas, anterioresPreguntas: Preguntas[]) => {
    const randomNumber = RandomNumero();
    const existeCodigoIgual = anterioresPreguntas.some((pregunta) => pregunta.codigo === randomNumber);
    if (!existeCodigoIgual) {
      pregunta.codigo = randomNumber;
      return pregunta;
    } else {
      return SetOpcionesRespuesta100(pregunta, anterioresPreguntas);
    }
  };

  const SetTresOpcionesAleatorias = (seleccionada:number, tresOpciones = []) => {
    if (tresOpciones.length === 3) {
      return tresOpciones;
    }

    if (tresOpciones.length === 0) {
      tresOpciones.push(seleccionada);
    }else{
      const opcionAleatoria = RandomNumero();
      if(!tresOpciones.includes(opcionAleatoria)){
        tresOpciones.push(opcionAleatoria);
      }
    }
    return SetTresOpcionesAleatorias(seleccionada, tresOpciones);
  };

  function CompararAleatoriamente() {
    return Math.random() - 0.5;
  }

  const SetPreguntasRandom = () => {
    let count = 0;
    let anterioresPreguntas = [];
    opciones.sort(CompararAleatoriamente);
    while (count< 10) {
      let nuevaPregunta: Preguntas = {
        codigo: -1, 
        opcion1: 0,
        opcion2: 0,
        opcion3: 0,
        opcionSeleccionada: -1,
      };

      nuevaPregunta = SetOpcionesRespuesta100(nuevaPregunta, anterioresPreguntas);
      let tresOpciones = [];
      
      tresOpciones = SetTresOpcionesAleatorias(nuevaPregunta.codigo);
      tresOpciones.sort(CompararAleatoriamente)
      
      nuevaPregunta.opcion1 = tresOpciones[0];
      nuevaPregunta.opcion2 = tresOpciones[1];
      nuevaPregunta.opcion3 = tresOpciones[2];
      anterioresPreguntas.push(nuevaPregunta);
      count++;
    }
    return anterioresPreguntas
  }



  return {
    ScrollLeft,
    ScrollRight,
    VerificarRespuesta,
    HandleClick,
    RandomNumero,
    SetOpcionesRespuesta100,
    Descripcion,
    GenerarArrayImages,
    GenerarArrayCalificaciones,
    GenerarArrayResultadosAMostrar,
    SetPreguntasRandom,
  };
};