import { createSlice } from '@reduxjs/toolkit';
import { ShopppingCart } from './models/ShoppingCart';
import { ShoppingCartReducers } from './actions/ReducerCarrito';

const initialState:ShopppingCart = {
    infoCliente:null,
    productos: [],
    total:0,
}

export const sliceShoppingCart = createSlice({
    name: 'shopppingCart',
    initialState,
    reducers: ShoppingCartReducers
});

export const { addProductoCarrito, eliminarProductoCarrito, cantidadProducto, agregarInfoCarrito, limpiarCarrito } = sliceShoppingCart.actions;