import { PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "../../auth/reducerAuth";

export const juegoSimultaneoReducers = {
    addIntegrante: (state, action: PayloadAction<{ nombre: string; telefono: string; sala: string }>) => {
        state.nombre = action.payload.nombre;
        state.telefono = action.payload.telefono;
        state.sala = action.payload.sala;
    },
    addCantidadIntegrantes: (state, action: PayloadAction<{ cantidadIntegrates: number; sala: string }>) => {
        state.cantidadIntegrates = action.payload.cantidadIntegrates;
        state.sala = action.payload.sala;
    },
    resincronizarJuego: (state, action: PayloadAction<{ ultimoResultado: number; total: number; tiempo: number; finalizado: boolean }>) => {
        state.ultimoResultado = action.payload.ultimoResultado;
        state.total = action.payload.total;
        state.tiempo = action.payload.tiempo;
        state.finalizado = action.payload.finalizado;
    },
    terminadoJuego: (state, action: PayloadAction<{ ultimoResultado: number; tiempo: number; finalizado: boolean }>) => {
        state.ultimoResultado = action.payload.ultimoResultado;
        state.total += Number(action.payload.ultimoResultado) || 0;
        state.tiempo = action.payload.tiempo;
        state.finalizado = action.payload.finalizado;
    },
    changePuntaje: (state, action: PayloadAction<{ verPuntaje: boolean }>) => {
        state.verPuntaje = action.payload.verPuntaje;
    },
    cleanJuego: (state) => {
        Object.assign(state, initialState);
    },
    clear: (state) => {
        Object.assign(state, initialState); // Resetea el estado a su valor inicial
    },
};
