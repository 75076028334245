import { paises } from "../../../../../../datos/paises";
import { useToast } from "../../../../../../generalUtils/useToast";
import Selector from "../../../../../components/Selector";
import estiloLanding from "../../theme/landing.module.css";

import { useMemo, useState } from "react";
interface Props{
    setLoading:React.Dispatch<React.SetStateAction<boolean>>;
    setMensaje:React.Dispatch<React.SetStateAction<boolean>>;
    ref:React.RefObject<HTMLDivElement>;
}

const ComponenteCortesia = ({setLoading, setMensaje, ref}:Props) => {
    const [nombre_usuairo, setNombre_usuairo] = useState('')
    const [telefono, setTelefono] = useState('')
    const [codigoTel, setCodigoTel] = useState('+57')

    const {presentMensajePersonalizado,presentMensajePersonalizadoSquareCenter} = useToast()

    const payUrl = useMemo(()=> {
      if (process.env.VITE_APP_ENVIRONMENT === 'development')  
        return 'desarrollo'       
      else if (process.env.VITE_APP_ENVIRONMENT === 'production')
        return 'produccion'
  
    }, [process.env.VITE_APP_ENVIRONMENT]);
    
    const HandleEnviarInfo = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setLoading(true)
        if(nombre_usuairo && codigoTel && telefono){
            try {
                // Construct the URL with query parameters
                const url = new URL(`https://us-central1-supercerebros-${payUrl}.cloudfunctions.net/enviarMensajePruebaGratuita`);
                url.searchParams.append('telefono', telefono.trim());
                url.searchParams.append('codigo', codigoTel);
                url.searchParams.append('nombre', nombre_usuairo.trim());
                
                // Process the result
                setLoading(false)
                setMensaje(true)
                presentMensajePersonalizadoSquareCenter('Revisa tu whatsapp',4);
              } catch (error) {
                console.error("Error al llamar la Cloud Function:", error);
                setLoading(false)
                presentMensajePersonalizado('Hubo un error al procesar tu solicitud.',4);
            }
        }else{
            setLoading(false)
            presentMensajePersonalizado('Complete la informacion, por favor.')
        }
    }

    return (
        <div ref={ref} id='inputInscripcion'>
            <div className={`${estiloLanding.cajaMensaje_cortesia}`}>
                OBTÉN UN VIDEO GRATIS DE NUESTRO CURSO "NEUROBICS"
            </div>
            <h2 className={`${estiloLanding.tituloMorado}`} style={{padding:'0 10%',margin:'5% 0'}}>¿A QUÉ NÚMERO DEBERÍAMOS ENVIARTE EL VIDEO?</h2>
            <div style={{display:'grid'}}>
                <p className={`${estiloLanding.campoObligatorio} ${estiloLanding.campoObligatorio_cortesia}`} >NOMBRE COMPLETO*</p>
                <input type='text' style={{width:'100%'}} placeholder='Ingrese su nombre' value={nombre_usuairo} onChange={(e) => setNombre_usuairo(e.target.value)} className='inputPersonalizado2'></input>
                <div style={{display:'flex',flexDirection:'row',gap:'5%'}}>
                    <p className={`${estiloLanding.campoObligatorio} ${estiloLanding.campoObligatorio_cortesia}`} style={{width:'45.5%'}}>PAÍS*</p>
                    <p className={`${estiloLanding.campoObligatorio} ${estiloLanding.campoObligatorio_cortesia}`} >NÚMERO DE TELÉFONO*</p>
                </div>
                <div style={{display:'flex',flexDirection:'row',height:'4.97svh',gap:'5%'}}>
                    <div style={{height:'100%'}}>
                    <Selector setSelection={setCodigoTel} selection={codigoTel} opciones={paises} showValues={true}
                    style={{backgroundColor:'#D9D9D9'}}/>
                    </div>
                    <input type='number' style={{width:'100%'}} placeholder='Ejemplo: ' value={telefono} onChange={(e) => setTelefono(e.target.value)} className='inputPersonalizado2'></input>
                </div>
                <label style={{color:'var(--color-Pink-Light)',alignSelf:'flex-end',textAlign:'end',width:'100%'}}>
                    *Campos obligatorios</label>
                <button className={'buttonMorado'} style={{margin:'0 auto',width:'fit-contain',padding:'2% 15%'}} type="submit" onClick={HandleEnviarInfo}>ENVIAR</button>
            </div>
        </div>
    )
}

export default ComponenteCortesia