import React, { ReactNode } from 'react';

// useJuegoCodificacionLogica.ts
export const useJuegoCodificacionLogica = () => {
    const GenerarIndicesValidosRandom = (cantidad: number, rangoInicial: number, rangoFinal: number): number[] => {
        let indices: number[] = [];
        while (indices.length < cantidad) {
            let indice = Math.floor(Math.random() * (rangoFinal - rangoInicial)) + rangoInicial;
            if (!indices.includes(indice)) {
                indices.push(indice);
            }
        }
        return indices;
    };

    const CalcularRespuesta = (palabra: string) => {
        const diccionarioConsonantes = {
            'b': 9, 'c': 7, 'd': 1, 'f': 8, 'g': 6, 'j': 6,
            'k': 7, 'l': 5, 'm': 3, 'n': 2, 'ñ': 2, 'p': 9,
            'q': 7, 'r': 4, 's': 0, 't': 1, 'v': 8, 'x': 0,
            'z': 0
        };
    
        let resultado = "";
    
        for (let i = 0; i < palabra?.length; i++) {
            let letra = palabra[i].toLowerCase();
            if (diccionarioConsonantes.hasOwnProperty(letra) && (letra !== palabra[i-1]?.toLowerCase() || letra === 'z')
            ) {
                resultado += diccionarioConsonantes[letra];
            }
        }
    
        return resultado;
    };

    const obtenerMensaje = (ContarRespuestas: number): ReactNode => {
        if (ContarRespuestas >= 8) {
            return <p>¡Excelente trabajo!</p>;
        } else if (ContarRespuestas >= 5) {
            return <p>Buen esfuerzo, sigue practicando.</p>;
        } else {
            return <p>No te rindas, puedes hacerlo mejor.</p>
        }
    };

    return {
        GenerarIndicesValidosRandom,
        CalcularRespuesta,
        obtenerMensaje,
    };
};
