import style from "../../theme/Juego2.module.css";
import TiempoWrapper from "../rutasPublicas/rutasPrivadasMy/juegos/componentes/TiempoWrapper";

/**
 * Interfaz para la barra de herramientas
 */
interface prop {
    /** Nivel del juego  */
    nivel?: number;
    /** Etapa en la que se encuentran el juego */
    etapa: number;
    /** Gestion de los tiempos*/
    handleTiempo: () => void;
    esJuego? : Boolean;
    ascendente? : boolean;
    /** Tiempo maximo */
    maxTiempo: number;
    /** Función para actualizar el tiempo de memoria */
    setTiempoMemoria: (newTiempoMemoria: number) => void;
    
}

/**
 * Colocar el nivel en pantalla y 
 * llamar a la funcion gettiempo
 */
const Toolbar: React.FC<prop> = (props) => {
    
    return (
        <div className={`${style.toolbar} ${style.toolbar_centrado}`}>            
            {props.etapa === 2 || props.etapa === 3 ? (
                <TiempoWrapper
                    handleTiempo={props.handleTiempo}
                    maxTiempo={props.maxTiempo}
                    setTiempoMemoria={props.setTiempoMemoria}
                    bandera={props.etapa === 2}
                    ascendente={props.ascendente ? props.ascendente : false}
                />
                ) : null}
        </div>
    );
}
export default Toolbar;