import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntrenamientoRedux } from './models/EntrenamientoRedux';
import { actividadesReducers } from './actions/actividadesReducers';
import { entrenamientosReducers } from './actions/entrenamientosReducers';
import { registrosReducers } from './actions/registrosReducers';
import { sesionReducers } from './actions/sesionReducers';
import { uiReducers } from './actions/uiReducers';

const initialState: EntrenamientoRedux = {
    entrenamientos_app: null,
    entrenamientos_user: null,
    current_entrenamiento: null,
    registros_usuario: null,
    current_registro_usuario: null,
    current_sesion: null,
    current_actividad: null,
    actividades_entrenamiento: null,
    current_actividades: null,
    cargando: true,
    finishSesion: null,
    sesionesVistas: [] as number[]  // Inicializamos como un array vacío
};

export const sliceEntrenamiento = createSlice({
    name: 'entrenamiento',
    initialState,
    reducers: {
        ...entrenamientosReducers,
        ...registrosReducers,
        ...sesionReducers,
        ...actividadesReducers,
        ...uiReducers,

        // Reducer para agregar una sesión vista
        agregarSesionVista: (state, action: PayloadAction<number>) => {
            if (!state.sesionesVistas.includes(action.payload)) {
                state.sesionesVistas.push(action.payload);
            }
        },
    },
});

// Exportar las acciones
export const {
    ChangeRedux_entrenamientos_app,
    ChangeRedux_entrenamientos_user,
    ChangeRedux_current_entrenamiento,
    ChangeRedux_registros_usuario,
    ChangeRedux_current_registro_usuario,
    ChangeRedux_current_sesion,
    ChangeRedux_actividades_entrenamiento,
    ChangeRedux_current_actividades,
    ChangeRedux_registros_usuario_push,
    ChangeRedux_cargando,
    ChangeRedux_current_indice_actividad,
    ChangeRedux_finishSesion,
    CleanEntrenamiento,
    agregarSesionVista,  // Exportamos la nueva acción
} = sliceEntrenamiento.actions;

export default sliceEntrenamiento.reducer;
