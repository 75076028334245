import { useState, useRef, useCallback } from 'react';
import { NIVELINICIAL, CAMBIONIVELBASE } from '../costants/juegoSumasConstants';
import { useJuegos } from '../../../../../../../../repositorio/controlador/useJuegos';

export const useJuegoSumas = (userId: string, idActividad: string) => {
    const {
		getCalificacionGeneral
	} = useJuegos();

    const sumaTotal = useRef(0);
    const diferenciaTotal = useRef(0);

    const [estado, setEstado] = useState<number>(0);
    const [partida, setPartida] = useState<number>(0);
    const [cambio, setCambio] = useState<number>(0);
    const [nivel, setNivel] = useState<number>(NIVELINICIAL);
    const [nivelGanado, setNivelGanado] = useState<number>(1);
    const [puntaje, setPuntaje] = useState<number>(0);
    const [cargado, setCargado] = useState<boolean>(false);

    // Función para calcular el nuevo nivel y racha
    const getNuevoNivelRacha = useCallback((resultado: number) => {
        let nuevoCambio = cambio;

        if (resultado === 1) {
            setNivelGanado(nivel);
            nuevoCambio = nuevoCambio < CAMBIONIVELBASE ? CAMBIONIVELBASE : nuevoCambio * 1.5;
        } else {
            nuevoCambio = -(nuevoCambio / 3 + (2 * nuevoCambio / 3) * (1 - resultado));
        }

        let nuevoNivel = nivel + nuevoCambio;
        nuevoNivel = nuevoNivel < 1 ? 1 : nuevoNivel;

        return [nuevoNivel, nuevoCambio];
    }, [nivel, cambio]);

    const Constructor = useCallback(() => {
        if (idActividad !== 'juego_linea') {
            getCalificacionGeneral(userId, 'nomProgSumas1').then((calificacion) => {
                if (calificacion && calificacion.nivel) {
                    setNivel(calificacion.nivel);
                    setNivelGanado(calificacion.nivel);
                } else {
                    setNivel(NIVELINICIAL);
                    setNivelGanado(1);
                }
                setCargado(true);
            });
        } else {
            setNivel(NIVELINICIAL);
            setNivelGanado(1);
            setCargado(true);
        }
    }, [idActividad, userId]);

    return {
        estado,
        partida,
        nivel,
        cambio,
        puntaje,
        cargado,
        setEstado,
        setPartida,
        setNivel,
        setCambio,
        setPuntaje,
        getNuevoNivelRacha,
        sumaTotal,
        diferenciaTotal,
        Constructor,
        nivelGanado,
        setNivelGanado
    };
};
