

import { Timestamp, DocumentSnapshot, DocumentData } from "firebase/firestore";


export interface RegistroEntrenamiento {
    id : string; 
    idEntrenamiento?:string;
    sesionActual : string;
    ordenSesion : number;         
    actividadActual: string;
    ordenActividad: number;
    completadoSesion: boolean;
    completadoEntrenamiento: boolean;
    fechaSesion: Timestamp | {seconds:number,nanoseconds:number};
    fechaInicio: Timestamp | {seconds:number,nanoseconds:number}; 
    fechaInicioGrupo?: Timestamp | {seconds:number,nanoseconds:number}; 
    aceptoEnvioWA: boolean;
    aceptoLlamada: boolean;
    horaEntrenamiento: number;
    mensajesEnviados: number;
    telefonoCompleto?:string;
    llamadasRealizadas?: number;
    nombre?: string;
    telefono?: number;
    codigoPais?: string;
    pruebaGratuita?: number;
}

export const toFormatoFirebaseEntrenamiento = (reg:RegistroEntrenamiento) => {
    if(reg){
        const formattedReg: Partial<RegistroEntrenamiento> = {
            id: reg?.id,
            sesionActual: reg.sesionActual,
            ordenSesion: reg.ordenSesion,
            actividadActual: reg.actividadActual,
            ordenActividad: reg.ordenActividad,
            completadoSesion: reg.completadoSesion,
            completadoEntrenamiento: reg.completadoEntrenamiento,
            fechaSesion: new Timestamp(reg.fechaSesion?.seconds,reg.fechaSesion?.nanoseconds),
            aceptoEnvioWA: reg.aceptoEnvioWA,
            aceptoLlamada: reg.aceptoLlamada,
            horaEntrenamiento: reg.horaEntrenamiento,
            mensajesEnviados: 0,
            llamadasRealizadas: 0,
        };

        if (typeof(reg.fechaInicioGrupo?.seconds) === typeof(0)) {
            formattedReg.fechaInicioGrupo = new Timestamp(reg.fechaInicioGrupo?.seconds,reg.fechaInicioGrupo?.nanoseconds);
        }
        if (typeof(reg.fechaInicio?.seconds) === typeof(0)) {
            formattedReg.fechaInicio = new Timestamp(reg.fechaInicio?.seconds,reg.fechaInicio?.nanoseconds);
        }else{
            formattedReg.fechaInicio = Timestamp.now();
        }
        if (typeof(reg.llamadasRealizadas) === typeof(0)) {
            formattedReg.llamadasRealizadas = reg.llamadasRealizadas;
        }
        if (reg.nombre) {
            formattedReg.nombre = reg.nombre;
        }
        if (reg.telefonoCompleto) {
            formattedReg.telefonoCompleto = reg.telefonoCompleto;
        }
        if (reg.telefono) {
            formattedReg.telefono = reg.telefono;
        }
        if (reg.codigoPais) {
            formattedReg.codigoPais = reg.codigoPais;
        }
        if (reg.pruebaGratuita) {
            formattedReg.pruebaGratuita = reg.pruebaGratuita;
        }
        return formattedReg;
    }
    return reg
}

type FireBaseDocument = DocumentSnapshot<DocumentData>

export const toRegistroEntrenamiento = (doc : FireBaseDocument) : RegistroEntrenamiento => {
    console.log(doc.data())
    const data = doc.data() as RegistroEntrenamiento;
    return {   
        id : data.id,
        sesionActual : data.sesionActual,
        ordenSesion : data.ordenSesion,
        actividadActual: data.actividadActual,
        ordenActividad: data.ordenActividad,
        completadoSesion: data.completadoSesion,
        completadoEntrenamiento: data.completadoEntrenamiento,
        fechaSesion: data.fechaSesion,
        fechaInicio: data.fechaInicio,
        aceptoEnvioWA: data.aceptoEnvioWA,
        aceptoLlamada: data.aceptoLlamada,
        horaEntrenamiento: data.horaEntrenamiento,
        mensajesEnviados: data.mensajesEnviados,
        llamadasRealizadas: data.llamadasRealizadas,
        nombre: data.nombre,
        telefono: data.telefono,
        codigoPais: data.codigoPais,
        telefonoCompleto: data.telefonoCompleto,
        pruebaGratuita: data.pruebaGratuita,
    } as RegistroEntrenamiento;
}