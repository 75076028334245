
import { functions } from "../../firebase";
import { httpsCallable } from 'firebase/functions';
import { useBaseDeDatos } from "../../generalUtils/repositoryUtils/useBaseDeDatos";

import {Configuration, OpenAIApi} from "openai"
import { nomMensajes, nomMensajesConfiguracion, nomMensajesCurso } from "../../datos/nombresBD";


const configuration =  new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_KEY,
  })

const openai = new OpenAIApi(configuration)


export const useIA = () => {
    const {
        recogerDoc 
    } = useBaseDeDatos();

    const consultarChat = async (historial: Array<{role: string, content: string}>): Promise<string> => {
        try {
            const consultarChatFunction = httpsCallable(functions, 'consultarChat');
            const result = await consultarChatFunction(historial);
            if (typeof result.data === 'string') {
                return result.data;
            } else {
                console.error("La respuesta no es una cadena:", result);
                throw new Error("Respuesta inesperada de la función en la nube.");
            }
        } catch (error) {
            console.error("Error al llamar a la función:", error);
            throw error;
        }
    }
    
    const obtenerConfiguracionMensaje = async (id:string, traerRespuestas?: boolean) => {    
        const docRef = await recogerDoc(nomMensajes + "/" + nomMensajesCurso + '/' + nomMensajesConfiguracion + '/' + id);
        if (!docRef.exists) {
            console.log('No se encontró ningún mensaje con el id:', id);
            return null;
        }
        
        const mensaje = docRef.data();    
        if(traerRespuestas){
            return [mensaje?.respuesta1,mensaje?.respuesta2,mensaje?.respuesta3];
        }else{
            return mensaje?.contenido;    
        }
    }

    const generateImage = async (prompt:string):Promise<string> => {
        try {
            const requestImagen = httpsCallable(functions, 'requestImagen');
            const result = await requestImagen(prompt);
            if (typeof result.data === 'string') {
                return result.data;
            } else {
                console.error("La respuesta no es una cadena:", result);
                throw new Error("Respuesta inesperada de la función en la nube.");
            }
        } catch (error) {
            console.error("Error al llamar a la función:", error);
            throw error;
        }
      }
    

    return {
        consultarChat,
        obtenerConfiguracionMensaje,
        generateImage,
    };

}




