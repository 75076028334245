import estiloTest from "../../../../../theme/testInicial.module.css";
import estiloJuegos from "../../../../../theme/Juegos.module.css";

import { useEffect, useState } from 'react';
import { format, fromUnixTime } from "date-fns";
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from "react-router-dom";

import CircularChart from "./CircularChart";
import RankingJuegos from "./RankingJuegos";
import { test_general } from "../../../../../datos/DatosTests";
import { nomProgMemNombres, nomTestMemNumerosInicial, nomTestMemPalabrasInicial } from "../../../../../datos/nombresBD";
import { useDato } from "../../../../../modelos/auth";
import { Calificacion } from "../../../../../modelos/Calificacion";
import { useAppSelector } from "../../../../../redux/store";
import { useJuegos } from "../../../../../repositorio/controlador/useJuegos";
import BotonBordeDegradado from "../../../../components/BotonBordeDegradado";
import Loading from "../../../../components/Loading";


type Params = {
    nombre:string;
}

export const ShowResults:React.FC = () =>{
    
    const {nombre} = useParams<Params>();
    const [selectCalificacion,setSelectCalificacion] = useState<Calificacion>();
    const [rankingData,setRankingData] = useState<Calificacion[]>([]);
    const [tipoDato, setTipoDato] = useState<string>("");
    // var { searchTest } = useParams<Params>();
    const [ resultado, setResultado] = useState<Calificacion>();
    const [ printFecha, setPrintFecha ] = useState<string>();
    const idTest = useAppSelector((state) => state.activity.id);
    const userId = useAppSelector((state) => state.auth.userId);
    const torneo = useAppSelector((state) => state.activity.torneo);
    const idProgram = useAppSelector((state) => state.activity.id_program);
    const [loading,setLoading] = useState(false);
    const { getCalificacion } = useJuegos();
    const [posicion,setPosicion] = useState(0);
    const [type, setType] = useState('test');
    const navigate = useNavigate();

    const [porcentaje, setPorcentaje] = useState<number>(0);
    const [cantidadDatos, setCantidadDatos] = useState<number>(0);
    const [mensaje, setMensaje] = useState<string>("");
    const [segundoMensaje, setSegundoMensaje] = useState<string>("");
    
    const [shouldRender, setShouldRender] = useState(true);
    const {
		state,
	} = useDato();
    
    
    const consultaResultado = async () => {
        const result = await getCalificacion(userId,idTest,idProgram);
        setResultado(result);
    }
    
    const convertirFecha = () =>{
        
        if(selectCalificacion?.fecha){
            const timestamp = selectCalificacion?.fecha.seconds + selectCalificacion?.fecha.nanoseconds / 1e9;
            const date = fromUnixTime(timestamp);
            const dateString = format(date, 'yyyy-MM-dd HH:mm:ss');
            setPrintFecha(dateString)
        }
        if(torneo && resultado?.fecha){
            const timestamp = resultado?.fecha?.seconds + resultado?.fecha?.nanoseconds / 1e9;
            const date = fromUnixTime(timestamp);
            const dateString = format(date, 'dd/MM/yyyy');
            setPrintFecha(dateString)
        }
    }  
    
    useEffect(() => {
        console.log("🚀 ~ resultado:", resultado)
        convertirFecha();
        if(resultado?.total || resultado?.total >= 0 || resultado?.resultado >= 0){
            let porcentaje = 0;
            console.log("🚀 ~ useEffect ~ idProgram:", idProgram)
            if (idProgram === nomProgMemNombres){
                const cantidadDatos = 10;
                console.log("🚀 ~ useEffect ~ cantidadDatos:", cantidadDatos)
                setCantidadDatos(cantidadDatos);
                porcentaje = resultado?.resultado/cantidadDatos*100;
                console.log("🚀 ~ useEffect ~ porcentaje:", porcentaje)
                setTipoDato("Nombres");
            }
            else if(idProgram === nomTestMemNumerosInicial){
                const cantidadDatos = 20;
                porcentaje = resultado?.resultado/cantidadDatos*100;
                setCantidadDatos(cantidadDatos);
                setTipoDato("Números");
            }
            else if(idProgram === nomTestMemPalabrasInicial){
                const cantidadDatos = 20;
                porcentaje = resultado?.resultado/cantidadDatos*100;
                setCantidadDatos(cantidadDatos);
                setTipoDato("Palabras");
                
            } 
            setPorcentaje(porcentaje);
            if(porcentaje>40){
                setMensaje("¡Excelente trabajo!");
                setSegundoMensaje("Mantén ese nivel y sigue desafiandote");
            }else if(porcentaje>10){
                setMensaje("¡Vas por buen camino!");
                setSegundoMensaje("Continúa entrenando y superarás este resultado");
            }else{
                setMensaje("¡Buen intento!");
                setSegundoMensaje("Con práctica, verás grandes mejorías");
            }
        }        
    }, [resultado]);
    
    
    const consultaRanking = async () => {
        setLoading(true)
        consultaResultado()
        setLoading(false)
    }
    const volver = () =>{
        navigate(-1);
    }
    useEffect(() => {
        setRankingData([]);
        consultaRanking();
    }, []);

    return(
        <div id='page'>
            <Loading isOpen={loading}/>
            <div id='toolbar_default'>
                <Link className={estiloJuegos.backJuegos} to="/my/testInicialPage/id" relative='path'></Link>
                <h1 className="ion-text-center title">Entrenamientos</h1>
            </div>
            {torneo ? 
                <div id='content_default'>
                    <div style={{display:'grid',gridTemplateColumns:'1fr 1fr 1fr'}}>
                        <div className="table-col">{posicion}</div>
                        <div className="table-col">{resultado?.nombre}</div>
                        <div className="table-col">{resultado?.resultado}</div>
                    </div>
                    <RankingJuegos  rankingData={rankingData} lectura={false} noMostrarResultado={false} setOpenDetalle={()=>{}} setSelectCalificacion={setSelectCalificacion} type={type}/>
                </div>
                :<div id='content_default'>
                    <div className={estiloTest.showResult}>
                        <p className={estiloTest.tituloResultado}>RESULTADO</p>
                        <label className={estiloTest.resultado}> Lograste memorizar {resultado?.resultado} de {cantidadDatos} {tipoDato}</label>
                        <div style={{margin:'5%'}}>
                            <CircularChart porcentaje={porcentaje}/>
                        </div>
                        <p style={porcentaje>40 ?{color:"#D74286"}:porcentaje>10?{color:"#512362"}:{}} className={estiloTest.mensaje}>{mensaje}</p>
                        <div style={{textTransform:"none"}}className={estiloTest.resultado}>{segundoMensaje}</div>
                    
                        {idTest == test_general  && <label>Nivel: {resultado?.nivel}<br/></label>}         
                        <BotonBordeDegradado onClick={volver}>
                            Volver
                        </BotonBordeDegradado>
                    </div>
                </div> 
            }
        </div>
    );
}

export default ShowResults;