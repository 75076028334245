import { getDay, isLeapYear } from 'date-fns';

const valoresMes = [4, 0, 0, 3, 5, 1, 3, 6, 2, 4, 0, 2]; // Valores de enero a diciembre

export const getRandomDateIn2024 = (start: Date, end: Date): Date => {
  const randomTime = start.getTime() + Math.random() * (end.getTime() - start.getTime());
  return new Date(randomTime);
};

export const obtenerDiaDeLaSemana = (fecha: Date): number => {
  const mes = fecha.getMonth(); // Índice del mes (0 = enero, 11 = diciembre)
  const valorMes = valoresMes[mes]; // Obtener el valor del mes

  // Verificamos si es año bisiesto usando la función `isLeapYear`
  const year = fecha.getFullYear();
  const ajusteBisiesto = isLeapYear(year) && (mes === 0 || mes === 1) ? -1 : 0;

  // Obtenemos el día de la semana directamente con `getDay`
  return getDay(fecha) + ajusteBisiesto;
};
