import { Route, Routes } from "react-router-dom";
import AreaPage from "./AreaPage";
import EntidadesPage from "./EntidadesPage";
import EntidadPage from "./EntidadPage";

const TabsEntidades = () =>{
    
    return(
        <Routes>
            {/* datosUsuario */}
            <Route path="/" element={<EntidadesPage/>}/>	
            <Route path=":nombreEntidad/:id" element={<EntidadPage/>}/>								
			<Route path=":nombreEntidad/:idEntidad/:idArea" element={<AreaPage/>}/>
        </Routes>
    );
}

export default TabsEntidades;