import { nomNeuronas } from "../../../../../datos/nombresBD";
import { Calificacion } from "../../../../../modelos/Calificacion";
import { useAppSelector } from "../../../../../redux/store";
import "../../../../../theme/main.css";
import style from "../../../../../theme/ranking.module.css"
import "../../../../../theme/ranking.module.css"


interface Props {
    rankingData: Calificacion[];
    noMostrarResultado:Boolean;
    lectura:Boolean;
    type: string;
    setOpenDetalle: (boolean) => void;
    setSelectCalificacion: (value:Calificacion) => void;
}

export const RankingJuegos:React.FC<Props> = (props) => {
    const idProgram = useAppSelector((state) => state.activity.id_program)


    const handleClick = (value:Calificacion) => {
        props.setSelectCalificacion(value);
        props.setOpenDetalle(true);
    }
    const getDatos = (id:number) =>{
        let topRank = ""
        let topNombre = ""
        if(id===1){
            topRank = style.topRank1;
            topNombre =  style.topNombre1;
        }else if(id===2){
            topRank = style.topRank2;
            topNombre =  style.topNombre2;
        }else{
            topRank = style.topRank3;
            topNombre =  style.topNombre3;
        }
        
        return(
            <>
            <div className={[style.topRank,topRank].join(" ")}  >{id}</div>
            {props.lectura ? 
                <>
                    <div className={[style.topNombre,topNombre].join(" ")}>{props.rankingData[id-1]?.nombre} </div>
                    <div className={style.topResultado}>{props.rankingData[id-1]?.resultado2}/{props.rankingData[0]?.resultado}</div>
                </>:    
                <>
                    {props.type !==  "test" ?
                        <>
                        {props.noMostrarResultado ? 
                            <>
                                <div className={[style.topNombre,topNombre].join(" ")}>{props.rankingData[id-1]?.nombre}</div>
                                <div className={style.topNivel}>{idProgram === nomNeuronas ? 'Total' : 'Nivel'}: {props.rankingData[id-1]?.nivel}</div> 
                            </>:
                            <>
                                <div className={[style.topNombre,topNombre].join(" ")}>{props.rankingData[id-1]?.nombre}</div>
                                <div className={style.topNivel}>Nivel: {props.rankingData[id-1]?.nivel}</div>
                                <div className={style.topResultado}>Resultado: {props.rankingData[id-1]?.resultado}</div>
                            </>
                        }
                        </>:
                        <>
                            <div className={[style.topNombre,topNombre].join(" ")}>{props.rankingData[id-1]?.nombre}</div>
                            <div className={style.topNivel}>  {idProgram === nomNeuronas ? 'Total' : 'Nivel'}: {props.noMostrarResultado ? props.rankingData[id-1]?.nivel :props.rankingData[0]?.resultado}</div>
                        </>
                    }
                </>
            }
            </>
            
        );
        
    }

    return(
        <div className={style.containerRanking}>
            {props.rankingData.length !== 0 ? (
                <div className={style.topTres}>
                    <div className={style.numeroUno} onClick={() => handleClick(props.rankingData[0])}>
                    {getDatos(1)}
                    </div>
                    <div  className={style.numeroDos} onClick={() => handleClick(props.rankingData[1])}>
                        {getDatos(2)}
                    </div>
                    <div className={style.numeroTres} onClick={() => handleClick(props.rankingData[2])}>
                        {getDatos(3)}
                    </div>
                </div>
            ): ""}

            <div className={`${style.titulos} ${!props.lectura && props.type !== "test" && !props.noMostrarResultado && style.titleConResultado}`}>
                <div className="table-col">Rank</div>
                {props.lectura ? 
                    <>
                        <div className="table-col">Nombre</div>
                        <div className="table-col">{"COMP/PPM"}</div>
                    </>:
                    <>
                    {props.type !==  "test" ?
                        <>
                        {props.noMostrarResultado ? 
                            <>
                                <div className="table-col">Nombre</div>
                                <div className="table-col">{idProgram === nomNeuronas ? 'Total' : 'Nivel'}</div> 
                            </>:
                            <>
                                <div className="table-col">Nombre</div>
                                <div className="table-col">Nivel</div>
                                <div className="table-col">Resultado</div>
                            </>
                        }
                        </>:
                        <>
                            <div className="table-col">Nombre</div>
                            <div className="table-col">{props.noMostrarResultado ? "Nivel":"Resultado"}</div>
                        </>
                    }
                </>}
            </div>
            {props.rankingData.length !== 0 ? (
                <>
                    
                    {props.rankingData.slice(3).map((item,index) => (
                        <div className={`${style.filaRanking} ${!props.lectura && props.type !== "test" && !props.noMostrarResultado && style.filaRankingConResultado}`}key={index} onClick={() => handleClick(item)}>
                            <div className={[style.rankNumeral,"table-col"].join(" ")}>#{index+4}</div>
                            {props.lectura ? 
                                <>
                                    <div className="table-col">{item.nombre}</div>
                                    <div className="table-col">{item.resultado2}/{item.resultado}</div>
                                </>:    
                                <>
                                    {props.type !==  "test" ?
                                        <>
                                        {props.noMostrarResultado ? 
                                            <>
                                                <div className="table-col">{item.nombre}</div>
                                                <div className="table-col">{item.nivel}</div> 
                                            </>:
                                            <>
                                                <div className="table-col">{item.nombre}</div>
                                                <div className="table-col">{item.nivel}</div>
                                                <div className="table-col">{item.resultado}</div>
                                            </>
                                        }
                                        </>:
                                        <>
                                            <div className="table-col">{item.nombre}</div>
                                            <div className="table-col">{props.noMostrarResultado ? item.nivel :item.resultado}</div>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    ))
                    }
                </>
                
                
            ) : "No hay resultados"}
        </div>
            
    );
}

export default RankingJuegos;