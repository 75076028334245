import { createSlice } from '@reduxjs/toolkit';
import { AuthRedux } from './models/auth';
import { reducerAuth } from './actions/reducerAuth';
import { reducerUtils } from './actions/reducerUtils';

export const initialState: AuthRedux = {
    loadingAuth: false,
    loading: false,
    loggedIn:false,
    creatingUsers:false,
    userId: null,
    email:null,
    neuronas: 0,
    tests_inicial_realizado: false,
}

export const authSliceAuth = createSlice({
    name: 'auth',
    initialState,
    reducers:{
        ...reducerUtils,
        ...reducerAuth,
    }
});

export const { modTestInicial, sumaNeuronas, initialNeuronas, loadAuth, load, creater, login, logout} = authSliceAuth.actions;