import { PayloadAction } from "@reduxjs/toolkit";
import { RegistroEntrenamiento } from "../../../modelos/entrenamiento/RegistroEntrenamiento";

export const registrosReducers = {
    ChangeRedux_registros_usuario_push: (state, action: PayloadAction<{ registro: RegistroEntrenamiento, entrenamientoID: string }>) => {
        const copyRegistros = [...(state.registros_usuario || [])];
        const copy = { ...action.payload.registro };
        copy.idEntrenamiento = action.payload.entrenamientoID;
        copyRegistros.push(copy);
        state.registros_usuario = copyRegistros;
    },
    ChangeRedux_registros_usuario: (state, action: PayloadAction<{ registros: RegistroEntrenamiento[] }>) => {
        const tempRegistros = action.payload.registros.map(element => ({
            ...element,
            fechaInicioGrupo: { seconds: element.fechaInicioGrupo?.seconds, nanoseconds: element.fechaInicioGrupo?.nanoseconds },
            fechaSesion: { seconds: element.fechaSesion?.seconds, nanoseconds: element.fechaInicio?.nanoseconds },
            fechaInicio: { seconds: element.fechaInicio?.seconds, nanoseconds: element.fechaInicio?.nanoseconds },
        }));
        state.registros_usuario = tempRegistros;
    },
};
