import estiloJuegos from "../../theme/Juegos.module.css";
import BotonBordeDegradado from "./BotonBordeDegradado";

interface Props {
  funcionReiniciar: () => void;
  funcionVolver: () => void;
  esJuego: boolean;
  esEntrenamiento?: boolean;
  jugarDeNuevo?: boolean;
  volver?: boolean;
}

export const BotonesJuegos: React.FC<Props> = ({
  funcionReiniciar,
  funcionVolver,
  esJuego,
  esEntrenamiento,
  jugarDeNuevo = true,
  volver = true,
}) => {
  return (
    <div className={estiloJuegos.div_botones_grid}>
      {/* Renderizamos el botón "Volver" o "Finalizar" si `volver` es true */}
      {volver && (
        <>
          {esJuego || esEntrenamiento ? (
            <BotonBordeDegradado
              onClick={funcionVolver}
              paddingInterno="5.55% 5%"
              style={{ padding: "2px 0" }}
            >
              Volver
            </BotonBordeDegradado>
          ) : (
            <button
              onClick={funcionVolver}
              className={estiloJuegos.buttonPlayAgain}
            >
              Finalizar
            </button>
          )}
        </>
      )}

      {/* Renderizamos el botón "Jugar de nuevo" si `esJuego`, `esEntrenamiento` o `jugarDeNuevo` es true */}
      {((esJuego || esEntrenamiento) && jugarDeNuevo) && (
        <button
          onClick={funcionReiniciar}
          className={estiloJuegos.buttonPlayAgain}
        >
          Jugar de nuevo
        </button>
      )}
    </div>
  );
};

export default BotonesJuegos;
