import { ReactNode } from "react";
import { opcion } from "../../../../../../components/Selector";

export const convertirBinarioADecimal = (numeroBinario: any): string => {
    const numeroBinarioStr = numeroBinario.props.children

    // Asegúrate de que el número binario tiene 6 cifras
    console.log("🚀 ~ convertirBinarioADecimal ~ numeroBinarioStr:", numeroBinarioStr)
    if (numeroBinarioStr.length !== 6) {
        throw new Error('El número binario debe tener exactamente 6 cifras.');
    }

    // Separar las partes del número binario
    const decimasBinario = numeroBinarioStr.slice(0, 3);
    const unidadesBinario = numeroBinarioStr.slice(3);

    // Convertir a decimal
    const decimasDecimal = parseInt(decimasBinario, 2);
    const unidadesDecimal = parseInt(unidadesBinario, 2);

    // Devolver el número total en decimal
    return `${decimasDecimal}${unidadesDecimal}`;
};

export const obtenerOpcionesDesdeEnum = (enumObject: any): opcion[] => {
    const opciones = Object.keys(enumObject).map((key) => ({
      value: enumObject[key],
      name: key.charAt(0).toUpperCase() + key.slice(1) // Capitaliza la primera letra
    }));
  
    // Agregar la opción inicial "No seleccionado"
    return [...opciones];
};


export const generarCartaPoker = (): React.ReactNode => {
    const palos = [
      <img src='/assets/icon/corazon.png' alt="Corazón" style={{ width: '1.2em', height: '1.2em' }} />,
      <img src='/assets/icon/trebol.png' alt="Trébol" style={{ width: '1.2em', height: '1.2em' }} />,
      <img src='/assets/icon/diamante.png' alt="Diamante" style={{ width: '1.2em', height: '1.2em' }} />,
      <img src='/assets/icon/picas.png' alt="Picas" style={{ width: '1.2em', height: '1.2em' }} />
    ];
    
    const valores = ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K'];
    
    const paloAleatorio = palos[Math.floor(Math.random() * palos.length)];
    const valorAleatorio = valores[Math.floor(Math.random() * valores.length)];
    
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <p style={{ fontSize: '1em', margin: 0 }}>{valorAleatorio}</p>
        <div style={{ marginTop: '0.2em' }}>{paloAleatorio}</div>
      </div>
    );
  };

export const generarNumeroBinario = (): string => {
    const numeroBinario = Math.floor(Math.random() * 64).toString(2).padStart(6, '0');
    return numeroBinario;
}

export const generarNumeroDecimal = (): string => {
    const numeroDecimal = Math.floor(Math.random() * 99).toString().padStart(2, '0');
    return numeroDecimal;
}