import React from 'react';
import styles from '../../../../../../../../theme/Juego2.module.css';

interface Props {
  total: number;
  reiniciar: () => void;
}

const Resultados: React.FC<Props> = ({ total, reiniciar }) => {
  return (
    <div className={styles.resultado}>
      <p style={{}}>
        TUVISTE {total} RESPUESTAS CORRECTAS
      </p>
      <button id='button_rosado_default' style={{width:'fit-content',alignSelf:'center'}}
      className={styles.botonReiniciar} onClick={reiniciar}>
        JUGAR DE NUEVO
      </button>
    </div>
  );
};

export default Resultados;
