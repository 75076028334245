import React, { useEffect, useState, useCallback } from 'react';
import { TiposJuegos } from '../models/models';
import { generarCartaPoker, generarNumeroBinario, generarNumeroDecimal } from '../utils';

export const useJuegoRepaso = () => {
  const [loading, setLoading] = useState(false);
  const [autoplay, setAutoplay] = useState(true);
  const [tipoJuego, setTipoJuego] = useState("3");
  const [indice, setIndice] = useState(-1);
  const [etapa, setEtapa] = useState(0);  
  const [cantidad, setCantidad] = useState(1);
  const [nivel, setNivel] = useState(1);
  const [preguntas, setPreguntas] = useState<React.ReactNode[]>([]);
  const [empezar, setEmpezar] = useState(false);

  const HandleTiempo = useCallback(() => {
    if (etapa === 1) {
      setEtapa(2);
    } else {
      throw new Error("Tiempo iniciado fuera de lugar");
    }
  }, [etapa]);

  const ConstruirPregunta = useCallback(() => {
    let newPregunta: React.ReactNode;
    if (tipoJuego === TiposJuegos.cartas) {
      newPregunta = generarCartaPoker();
    } else if (tipoJuego === TiposJuegos.decimal) {
      newPregunta = generarNumeroDecimal();
    } else {
      newPregunta = generarNumeroBinario();
    }
    return newPregunta;
  }, [tipoJuego]);

  const EncolarNuevaPreguntas = useCallback(() => {
    setPreguntas((prev) => [
      ...prev,
      <div key={prev.length} style={{ display: 'flex', flexDirection: tipoJuego === TiposJuegos.decimal ? 'row' : 'column', gap: '0.2em' }}>
        {Array.from({ length: cantidad }).map((_, index) => (
          <div key={index} style={{
            height: tipoJuego === TiposJuegos.decimal ||TiposJuegos.cartas ? 'auto' : '10svh',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: tipoJuego === TiposJuegos.decimal || TiposJuegos.binario ? "'Kaushan Script', cursive" : "inherit",
            width:tipoJuego === TiposJuegos.decimal ? '1.5em' : '5em', 
          }}>
            {ConstruirPregunta()}
          </div>
        ))}
      </div>,
    ]);
  }, [cantidad, ConstruirPregunta, tipoJuego]);


  const iniciarJuego = useCallback(() => {
    EncolarNuevaPreguntas();
    setEtapa(1);
  }, [EncolarNuevaPreguntas]);

  const HandleSiguiente = useCallback(() => {
    EncolarNuevaPreguntas();
  }, [EncolarNuevaPreguntas]);

  const HandleReiniciar = useCallback(() => {
    setTipoJuego("3");
    setPreguntas([]);
    setIndice(-1);
    setEtapa(0);    
    setEmpezar(false);
  }, []); 

  const HandleEmpezar = useCallback(() => {
    if (nivel && cantidad && tipoJuego) {
      setEmpezar(true);
    } else {
      console.warn("Por favor, selecciona el nivel, la cantidad y el tipo de juego antes de empezar.");
    }
  }, [nivel, cantidad, tipoJuego]);

  // Función para obtener la duración del intervalo según el nivel
  const getIntervalDuration = useCallback((nivelAdjusted) => {
    const maxLevel = 9; // Último nivel
    const minDuration = 0.3; // Duración mínima en segundos
    const maxDuration = 3;   // Duración máxima en segundos
    const a = (minDuration - maxDuration) / (maxLevel - 1); // Calcula la pendiente
    const b = maxDuration - a * 1; // Calcula la intersección

    let intervalDurationInSeconds = a * nivelAdjusted + b;  
    intervalDurationInSeconds = Math.max(intervalDurationInSeconds, minDuration); // No permitir menos que la duración mínima

    return intervalDurationInSeconds * 1000; // Convertir a milisegundos
  }, []);

  useEffect(() => {
    if (etapa === 1 && autoplay) {
      const nivelAdjusted = Math.max(nivel, 1);
      const intervalDuration = getIntervalDuration(nivelAdjusted);

      const intervalId = setInterval(() => {
        HandleSiguiente();
      }, intervalDuration);

      return () => clearInterval(intervalId);
    }
  }, [etapa, autoplay, nivel, HandleSiguiente, getIntervalDuration]);

  useEffect(() => {
    if (preguntas.length > 1) {
      const ultimaPregunta = preguntas[preguntas.length - 1];
      const penultimaPregunta = preguntas[preguntas.length - 2];
  
      let ultimaPreguntaValor;
      let penultimaPreguntaValor;
  
      // Verificar si es un componente JSX o un nodo simple
      if (React.isValidElement(ultimaPregunta)) {
        ultimaPreguntaValor = ultimaPregunta.props.children;
      } else {
        ultimaPreguntaValor = ultimaPregunta;
      }
  
      if (React.isValidElement(penultimaPregunta)) {
        penultimaPreguntaValor = penultimaPregunta.props.children;
      } else {
        penultimaPreguntaValor = penultimaPregunta;
      }
  
      // Si hay similitud, generar una nueva pregunta
      if (ultimaPreguntaValor === penultimaPreguntaValor) {
        console.log("similitud, generando nueva pregunta");
  
        // Llamar a la función que genera una nueva pregunta
        EncolarNuevaPreguntas();
      }
    }
  
    setIndice(preguntas.length - 1); // Actualizar el índice
  }, [preguntas, EncolarNuevaPreguntas]);
  

  useEffect(() => {
    if (empezar && nivel && cantidad && tipoJuego) {
      iniciarJuego();
    }
  }, [empezar, nivel, cantidad, tipoJuego, iniciarJuego]);

  return {
    loading,
    etapa,
    preguntas,
    indice,
    tipoJuego,
    autoplay,
    nivel,
    cantidad,
    setCantidad,
    setAutoplay,
    setTipoJuego,
    iniciarJuego,
    setNivel,
    HandleTiempo,
    HandleSiguiente,
    setEtapa,
    setPreguntas,
    setIndice,
    HandleReiniciar,
    HandleEmpezar,
  };
};
