import { Link} from 'react-router-dom';

import  "../../../../../theme/pages.css";

const TabJuegos = () => {

  return (
    <div id="page" className='pageJuegos'>
      <div id='toolbar_default'>
        <h1 className="title">Juegos</h1>
      </div>
      <div id='content_default' style={{overflow: 'hidden'}}>
        <div className='contenedor_juegos'>
          <Link className='card_juegos_memoria' to='Memoria'>
            <p className='text_juegoDe'>JUEGOS DE</p>
            <p className='nombre_grupo_juegos'>MEMORIA</p>
          </Link>
          <Link className='card_juegos_agilidad' to='AgilidadMental'>
            <p className='text_juegoDe'>JUEGOS DE</p>
            <p className='nombre_grupo_juegos'>AGILIDAD <br />   MENTAL</p>
          </Link>
          <div className='contenedor_globito_sc'>
            <div className='rato_para_jugar'> TÓMATE UN RATO <br /> PARA JUGAR</div>

          </div>
          <img src='/assets/images/supercerebro/sc_nice.png' alt="Imagen SuperCerebros Nice" loading="eager" className='imagen_sc_nice' />
        </div>
      </div>
    </div>
    
  );
};

export default TabJuegos;
