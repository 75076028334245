import { format, fromUnixTime } from 'date-fns';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from "react-router-dom";

import { nomTestSumas1, nomProgLecturaVelocidad } from '../../../../../datos/nombresBD';
import { useDato } from '../../../../../modelos/auth';
import { Calificacion } from '../../../../../modelos/Calificacion';
import { useAppSelector, useAppDispatch } from '../../../../../redux/store';
import { useControl } from '../../../../../repositorio/controlador/useControl';
import { useJuegos } from '../../../../../repositorio/controlador/useJuegos';
import { useTests } from '../../../../../repositorio/controlador/useTests';


type Params = {
    nombre:string;
}

export const ShowInfoTest:React.FC = () =>{
    const {nombre} = useParams<Params>();
    const [openDetalle,setOpenDetalle] = useState(false);
    const { consultarRanking } = useTests();
    const [selectCalificacion,setSelectCalificacion] = useState<Calificacion>();
    const [rankingData,setRankingData] = useState<Calificacion[]>([]);
    const location = useLocation();
    // var { searchTest } = useParams<Params>();
    const [ resultado, setResultado] = useState<Calificacion>();
    const [ redireccionar, setRedireccionar] = useState<boolean>(false);
    const [ printFecha, setPrintFecha ] = useState<string>();
    const [ suma, setSuma ] = useState<boolean>(false);
    const [ lectura, setLectura ] = useState<boolean>(false);
    const idTest = useAppSelector((state) => state.activity.id);
    const userId = useAppSelector((state) => state.auth.userId);
    const torneo = useAppSelector((state) => state.activity.torneo);
    const idProgram = useAppSelector((state) => state.activity.id_program);
    const dispatch = useAppDispatch();
    const [loading,setLoading] = useState(false);
    const { getCalificacion } = useJuegos();
    const { recogerDatos, recogerDatosConstante } = useControl();
    const [posicion,setPosicion] = useState(0);
    const [type, setType] = useState('test');
    const {
		state,
	} = useDato();
    
    
    // const consultaResultado = async () => {
    //     console.log("🚀 ~ file: ShowResults.tsx:62 ~ consulta ~ searchTest:", idProgram)
    //     const result = await getCalificacion(userId,idTest,idProgram);
    //     let retorno: Calificacion = null;
    //     setResultado(result);
    //     convertirFecha();
    //     console.log("🚀 ~ file: ShowResults.tsx:29 ~ printFecha:", printFecha)
    //     console.log("🚀 ~ file: ShowResults.tsx:56 ~ consultaResultado ~ result:", result.resultado2)
    // }
    
    const convertirFecha = () =>{
        if(selectCalificacion?.fecha){
            const timestamp = selectCalificacion?.fecha.seconds + selectCalificacion?.fecha.nanoseconds / 1e9;
            const date = fromUnixTime(timestamp);
            const dateString = format(date, 'yyyy-MM-dd HH:mm:ss');
            console.log("🚀 ~ file: ShowResults.tsx:46 ~ convertirFecha ~ dateString:", dateString)
            setPrintFecha(dateString)
        }
        if(!torneo && resultado?.fecha){
            const timestamp = resultado?.fecha?.seconds + resultado?.fecha?.nanoseconds / 1e9;
            const date = fromUnixTime(timestamp);
            const dateString = format(date, 'yyyy-MM-dd HH:mm:ss');
            console.log("🚀 ~ file: ShowResults.tsx:46 ~ convertirFecha ~ dateString:", dateString)
            setPrintFecha(dateString)
        }
    }  
    
    useEffect(() => {
        convertirFecha();
    }, [resultado])
    
    
    const consultaRanking = async () => {
        setLoading(true)
        const respuesta = await consultarRanking(idTest,idProgram);
        console.log("🚀 ~ file: ShowInfoTest.tsx:87 ~ consultaRanking ~ respuesta:", respuesta)
        
        const array: Calificacion[] = [];
        respuesta.forEach((item,index) => {
            if(item.id == userId){
                setPosicion(index+1)
                setResultado(item)
            }
            setRankingData((prevState) => [...prevState,item])
        })
        setLoading(false)
    }
    
    useEffect(() => {
        if(location.pathname == `/my/ShowInfoTest/${nombre}`){
            setRankingData([]);
            if(idProgram == nomTestSumas1){
                setSuma(true);
            }
            if(idProgram == nomProgLecturaVelocidad){
                setLectura(true);
            }
            consultaRanking();
        }
    }, [location]);
    
    // <PruebaGrafica value={resultado?.resultado2} />
    
    return(
        <></>
        // <IonPage>
        //     <IonLoading isOpen={loading}/>
        //     <IonHeader>
        //         <IonToolbar>
        //             <IonButtons  slot="start">
        //                 <IonBackButton/>
        //             </IonButtons>
        //             <h1 className="ion-text-center title">{nombre}</h1>
        //         </IonToolbar>
        //     </IonHeader>
        //     <IonContent>
        //         <RankingJuegos  rankingData={rankingData} lectura={lectura} noMostrarResultado={suma} setOpenDetalle={setOpenDetalle} setSelectCalificacion={setSelectCalificacion} type={type}/>
        //     </IonContent>
        //     <IonModal isOpen={openDetalle} className="custom-modal">
        //             <IonHeader>
        //                 <IonToolbar>
        //                     <IonTitle>Detalles</IonTitle>
        //                     <IonButtons slot="end">
        //                         <IonButton onClick={() => setOpenDetalle(false)}>
        //                             <IonIcon icon={closeCircle} />
        //                         </IonButton>
        //                     </IonButtons>
        //                 </IonToolbar>
        //             </IonHeader>
        //             <IonContent className="ion-padding">
        //                 <div className='ion-text-center ion-margin-bottom'>
        //                     <IonLabel>{selectCalificacion?.nombre}<br/></IonLabel>
        //                     <IonLabel>{selectCalificacion?.dependencia}<br/></IonLabel>
        //                     <IonLabel>Realizado el: {printFecha} <br/> </IonLabel>
        //                     {lectura && <IonLabel>PalabrasPorMinuto: {selectCalificacion?.resultado} <br/></IonLabel>}
        //                     {lectura && <IonLabel>Comprension: {selectCalificacion?.resultado2} % <br/></IonLabel>}
        //                     {(!suma && !lectura) && <IonLabel>Resultado: {selectCalificacion?.resultado} <br/></IonLabel>}
        //                     {idTest == test_general || suma && <IonLabel>Nivel: {selectCalificacion?.nivel}<br/></IonLabel>}
        //                     {suma && <IonLabel>Diferencias: {selectCalificacion?.resultado} <br/></IonLabel>}                            
        //                     {(!suma && !lectura) && <IonLabel>En {selectCalificacion?.tiempo/1000} segundos<br/></IonLabel>}
        //                 </div>
        //             </IonContent>
        //         </IonModal>
        // </IonPage>
    );
}

export default ShowInfoTest;