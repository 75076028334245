    // /features/juegoFechas/components/TiempoJuego.tsx
import React from 'react';
import styles from '../../../../../../../../theme/codigoPalabras.module.css';

interface Props {
  children: React.ReactNode;
}

const TiempoJuego: React.FC<Props> = ({ children }) => {
  return <div className={styles.contenedorCuestionario} style={{padding:'20% 10% 0'}}>{children}</div>;
};

export default TiempoJuego;
