import '../../../../../../../theme/MemoriaParejas.css';

interface Props{
  imagen: string;
  volteada: boolean;
  onClick: () => void;
  onLoad?: () => void;
  index: number;
}

const Card = ({imagen, volteada, onClick, onLoad, index }: Props) => {

  return (
    <div
      className={`cardParejas ${volteada ? 'flipped' : ''}`}
      onClick={onClick}
    >
      <div className="card-inner">
        <div className="card-front">
          <div className={`card-cover degradado${index}`} />
        </div>
        <div className="card-back">
          <img src={imagen} alt="Card" onLoad={onLoad} onError={e => {console.error("error en carga image carta:", e)}}/> 
        </div>
      </div>
    </div>
  );
};

export default Card;