import { PayloadAction } from "@reduxjs/toolkit";
import { auth } from "../../../firebase";
import { AuthRedux } from "../models/auth";
import { initialState } from "../reducerAuth";

export const reducerAuth = {
    login: (state: AuthRedux, action: PayloadAction<{ userId: string; email: string }>) => {
        state.loading = true;
        if (!state.creatingUsers) {
            state.userId = action.payload.userId;
            state.email = action.payload.email;
            state.loggedIn = true;

            auth.currentUser.getIdToken()
                .then(token => localStorage.setItem('accessToken', token));
        }
        state.loading = false;
    },
    logout: (state: AuthRedux) => {
        state.loading = true;
        if (!state.creatingUsers) {
            state.email = initialState.email;
        }
        state.loggedIn = initialState.loggedIn;
        state.creatingUsers = initialState.creatingUsers;
        state.userId = initialState.userId;
        state.loading = initialState.loading;
        localStorage.removeItem('accessToken');
    },
    creater: (state: AuthRedux) => {
        state.loading = !state.loading;
        state.creatingUsers = !state.creatingUsers;
    },
    loadAuth: (state: AuthRedux) => {
        state.loadingAuth = !state.loadingAuth;
    },
    load: (state: AuthRedux) => {
        state.loading = !state.loading;
    },
}