
/* importaciones componentes externos*/
import { Link } from "react-router-dom";

/* importaciones para css*/
import "../../../../../theme/tiposJuegos.css";
import estiloJuegos from "../../../../../theme/Juegos.module.css";

import { test_general } from "../../../../../datos/DatosTests";
import { nomProgSumas1 } from "../../../../../datos/nombresBD";
import { newActivity, changePrograma } from "../../../../../redux/actividad/reducerActividad";
import { useAppDispatch } from "../../../../../redux/store";



const AgilidadMental = () => {
    const dispatch = useAppDispatch();
    const inicializarTestJuegos = () =>{
        dispatch(newActivity({id:test_general, name:'Juegos'}))
    }
    function InicializarJuegos (name) {
        inicializarTestJuegos();
        dispatch(changePrograma({id:name}))
    }
    return(
        <div id='page'>
            <div className='toolbar_tipos_juegos'>
                <div className='contenedor_titulo'>
                    <Link  className={estiloJuegos.backJuegos} to=".."></Link>
                    <h1 className={`ion-text-center title ${estiloJuegos.tituloJuegos}`}>
                        AGILIDAD MENTAL
                    </h1>
                </div>
            </div>
            <div id='content_default'>
                <div className="contenedor_cards_juegos">
                    {/** carta del juego memoria numeros */}
                    <Link to="Sumas1" onClick={() =>InicializarJuegos(nomProgSumas1)} className='card_calculo_matematico'>
                        <h3>CÁLCULO MATEMÁTICO</h3>   
                    </Link>
                    <div 
                    // onClick={InicializarJuegos}
                    className={`${estiloJuegos.card_blocked} card_calculo_fecha`}>
                        <h3>CÁLCULO DE LA FECHA</h3>
                    </div>
                    <div 
                    // onClick={InicializarJuegos} 
                    className={`${estiloJuegos.card_blocked} ${estiloJuegos.card_blocked_backNeural} card_neural_trainer`}>
                        <h3>NEURAL TRAINER</h3> 
                    </div>

                </div>
            </div>

        </div>
    );
}
export default AgilidadMental;