/** Importaciones externales */
import { format, parseISO } from 'date-fns'
/** Importaciones de Ionic */

/** Importaciones de React */
import React, { useState, useEffect } from 'react';
import { compararFechas, Fecha } from '../../../../../../modelos/usuario';

/** Importaciones de Firebase */

/** Importaciones de modulos personalizados */

/** Importaciones Hooks personalizados */

/** Importaciones Interfaces */

/** Interfaces Locales*/

interface Props {
    setDatoLocal : (a : string , b : string) => void;
    datoAnterior? : string;
}



const FechaDeNacimientoComponent: React.FC<Props> = ({ setDatoLocal, datoAnterior }) => {
	const today = new Date();
	const origDate = format(new Date(), 'yyyy-MM-dd') ;

	const [dateValue, setDateValue] = useState(datoAnterior ? datoAnterior :  origDate);
	const [buttonValue, setButtonValue] = useState(datoAnterior ? format(parseISO(datoAnterior), 'dd/MM/yyyy') :  format(new Date(), 'dd/MM/yyyy') )
	// const popover = useRef<HTMLIonPopoverElement>(null);
	const [popoverOpen, setPopoverOpen] = useState(false);

	const openPopover = (e: any) => {
		// popover.current!.event = e;
		setPopoverOpen(true);
	};
	const formatDate = (date) => {
		return format(parseISO(date), 'yyyy-MM-dd');
	}
	const formatDateDisplay = (date) => {
		return format(parseISO(date), 'dd/MM/yyyy');
	}
	
	const checarFDN = (date) => {
		const fechaSeleccionado = sacarFecha(date)
		const hoy = sacarFecha(origDate)
		return compararFechas(fechaSeleccionado, hoy)
	}
	
	const sacarFecha = (date) : Fecha => {
		date = date.split("T")[0].split("-").map((el) => Number(el))
		return {
			ano : date[0],
			mes : date[1],
			dia : date[2],
		} as Fecha;
		}
		
	const dateChanged = (date) => {
		console.log(date);
		if(checarFDN(date)) {
			setDateValue(date);
			setButtonValue(formatDateDisplay(date));
			console.log("🚀 ~ dateChanged ~ date:", date)
			console.log("🚀 ~ dateChanged ~ formatDate(date):", formatDate(date))
			setDatoLocal("fechaDeNacimiento", formatDate(date))
		} else {
			console.error("fecha no cambiado");
		}
	}
	useEffect(() => {
	if(datoAnterior) {
		setDateValue(datoAnterior);
	}
	}, [datoAnterior]);
	
	
    return (
	<>
		 <div className='select-contenedor'>
  			<input style={{height:'100%', border: '2px solid #C0BCC2'}} type="date" id="birthdaytime" name="birthdaytime" value={dateValue} onChange={(e) => dateChanged(e.target.value)}></input>
	    </div>
	</>
    );
}

export default FechaDeNacimientoComponent;
