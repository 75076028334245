import { DocumentSnapshot, DocumentData, Timestamp } from "firebase/firestore";

export interface Campaña {
    id? : string;   
    nombre : string;
    idEntrenamiento: string;
    fechaVigente: Timestamp;
    tiempoVigencia?: number;
    descuento? : number;
    sesionesPrueba?: number
    enlacePago?: string;
}

/*
export const aEntidad = (nombre : string,tipo : string,idEntrenamiento: string,idEntidad: string,fechaVigente: Timestamp,id? : string) : Codigo => {
    const codigo: Codigo = codigoVacio;

    if (id !== null && id !== undefined) {
        codigo.id = id;
    }
    if (nombre !== null && nombre !== undefined) {
        codigo.nombre = nombre;
    }
    if (idEntrenamiento !== null && idEntrenamiento !== undefined) {
        codigo.idEntrenamiento = idEntrenamiento;
    }
    if (idEntidad !== null && idEntidad !== undefined) {
        codigo.idEntidad = idEntidad;
    }
    if (fechaVigente !== null && fechaVigente !== undefined) {
        codigo.fechaVigente = fechaVigente;
    }
    if (tipo !== null && tipo !== undefined) {
        codigo.tipo = tipo;
    }
    return codigo;    
}
*/

const codigoVacio = {
    id: null,
    nombre: null,    
    idEntrenamiento: null,
    idEntidad: null,
    fechaVigente: null,
}

type FireBaseDocument = DocumentSnapshot<DocumentData>

export const toCampaña = (doc : FireBaseDocument) : Campaña => {    
    if(doc.exists()){        
        const campaña = doc.data() as Campaña;
        campaña.id = doc.id;
        return campaña
    }
    return undefined;
    
}
