import { PayloadAction } from "@reduxjs/toolkit"
import { Pregunta } from "../../../modelos/Respuesta"

export const cuestionarioReduxReducers = {
    add: (state,action: PayloadAction<{cuestionario:Pregunta[],valores:number[]}>) => {
        console.log("🚀 ~ file: reducerCuestionario.ts:12 ~ action.payload.cuestionario :", action.payload.cuestionario )
        state.preguntas = action.payload.cuestionario            
        state.valor = action.payload.valores
    },
    clear: (state) =>{
        state = null
    }
}