import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDato } from '../../../../modelos/auth';
import { Programa } from '../../../../modelos/Programa';
import { changePrograma } from '../../../../redux/actividad/reducerActividad';
import { useAppSelector, useAppDispatch } from '../../../../redux/store';
import { useJuegos } from '../../../../repositorio/controlador/useJuegos';
import { useTests } from '../../../../repositorio/controlador/useTests';

  
  /**
   * Pagina principal de test
   */
  const TestPage: React.FC = () => {
    const {
          state,
      } = useDato();


    const {
      consultarProgramasTest,
      consultarRutaPrograma,      
    } = useTests();

    
    const {
      getCalificacion,
    } = useJuegos();

    const [programas, setProgramas] = useState<Programa[]>([])
    const [loading,setLoading] = useState(false);
    const userId = useAppSelector((state) => state.auth.userId);
    const testId = useAppSelector((state) => state.activity.id);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const consulta = async () => {
      setLoading(true)
      const respuesta = await consultarProgramasTest(testId);            
      respuesta.map(async (element) => {
        setProgramas([]);
        const programa = await consultarRutaPrograma(element.id);        
        const respuesta = await getCalificacion(userId,testId,element.id);        
        console.log("🚀 ~ file: TestPage.tsx:64 ~ respuesta.map ~ respuesta:", respuesta)
        const resultado = true === respuesta?.completado;
        if(resultado){
          programa.ruta = `/my/ResultsPage`
        }
        setProgramas((prevState) => [
          ...prevState,
          programa
        ]);
      })
      console.log(programas);
      setLoading(false)
    }

    const handleReduxProgram = (id:string,ruta:string) => {
      console.log("🚀 ~ file: TestPage.tsx:85 ~ handleReduxProgram ~ id:", id)
      dispatch(changePrograma({id}));
      navigate(ruta)
    }

    useEffect(() => {
        consulta();
    }, []);

  
    return (
      <></>
      // <IonPage>
      //   <IonLoading isOpen={loading}></IonLoading>
      //   <IonHeader>
      //     <IonToolbar>
      //       <IonButtons>
      //       </IonButtons>
      //       <h1 className="ion-text-center title">Tests</h1>
      //     </IonToolbar>
      //   </IonHeader>
      //   <IonContent className='ion-padding'>
  
      //     <IonGrid>

      //       {programas.map((element,index) =>{
      //         return(
      //           <IonRow key={index}>
      //             <IonCol>
      //               <IonCard style={{ width: '100%' }}>
      //                 <IonItem onClick={() => handleReduxProgram(element.id,element.ruta)}>
      //                   <IonIcon icon={tennisballOutline} slot="start" />
      //                   <IonLabel>{element.nombre}</IonLabel>
      //                 </IonItem>
      //               </IonCard>
      //             </IonCol>
      //           </IonRow>
      //         )
      //       })}
            
      //     </IonGrid>
      //   </IonContent>
      // </IonPage>
    );
  }
  
  export default TestPage;
  