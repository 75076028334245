import { PayloadAction } from "@reduxjs/toolkit";
import { Producto, UsuarioCliente } from "../../../modelos/Producto";

const calcularTotal = (productos: Producto[]) => {
    return productos.reduce((total, producto) => total + (producto.valor * producto.precio), 0);
};

export const ShoppingCartReducers = {
    addProductoCarrito:(state,action: PayloadAction<{producto:Producto}>) => {
        state.productos.push(action.payload.producto);
        state.total = calcularTotal(state.productos);
    },
    eliminarProductoCarrito: (state, action: PayloadAction<{ id: string }>) => {
        state.productos = state.productos.filter(producto => producto.id !== action.payload.id);
    },
    cantidadProducto: (state, action: PayloadAction<{ id: string, cantidad: number }>) => {
        const producto:Producto = state.productos.find(producto => producto.id === action.payload.id);
        if (producto) {
          producto.precio = action.payload.cantidad;
        }
        state.total = calcularTotal(state.productos);
    },
    agregarInfoCarrito: (state, action: PayloadAction<{ usuario: UsuarioCliente }>) => {
        state.infoCliente = action.payload.usuario;
    },
    limpiarCarrito:(state) => {
        state.productos = [];
        state.total = 0;
    }
}