
import { useEffect, useState } from 'react';
import style from "../../../../../../../theme/PopoverNeuronas.module.css";
import estiloJuegos from "../../../../../../../theme/Juegos.module.css";
import '../../../../../../../theme/MemoriaParejas.css';

import { test_general } from '../../../../../../../datos/DatosTests';
import { useAppSelector } from '../../../../../../../redux/store';
import DosBotonesColumna from '../../../../../../components/DosBotonesColumna';
import { obtenerMensaje } from '../../../../../../utils/resultadoUtil';

interface Props{
    tiempoTotal: number;
    tiempo: number;
    intentos : number;
    nivel: number;
    nivelFinal: boolean;
    estado: boolean;
    puntaje: number;
    enLinea: boolean;
    onContinuar?: () => void;
    onReiniciar?: () => void;
    onSalir?: () => void;
}
const PopoverNeuronasParejas = ({tiempoTotal, tiempo, intentos, nivel, nivelFinal, estado, puntaje, enLinea, onContinuar, onReiniciar, onSalir}: Props) => {

	const esJuego = useAppSelector((state) => state.activity.id === test_general);
    const [mensaje, setMensaje] = useState<string>("");


    useEffect(() => {
        setMensaje(obtenerMensaje(0.65));
    }, [nivel]);

    return (
        <>
        {(nivelFinal || !estado)?
            <>
                <div className={[style.contenedor].join(" ")} >
                    <div className={style.contenido}>
                        <div className={style.circulo}
                            style={{padding: 0, width: '20vh', height: '20vh'}}>

                            <img style={{height: '100%', width: '100%', zIndex: 1, borderRadius: '50%', backgroundColor: 'white'}}
                                src='/assets/icon/icon_neuronas_purple.svg'></img>
                        </div>
                            
                        
                        <div className={style.rectangulo} style={{marginTop: '-10.5vh', paddingTop: '11.71vh', paddingBottom: '6vh'}}>
                            <h2 
                                className={`${style.mensajePersonalizado} ${intentos > 6 ? style.marginMensaje : ""}`}
                                style={{textAlign: 'center', fontSize: "2.2rem"}}
                            >
                                {
                                    (estado)? obtenerMensaje(0.65): obtenerMensaje(0)
                                }    
                            </h2>
                            <p className="popOverMensaje" style={{color: 'black'}}>{enLinea ? 'Puntaje:':'Ganaste' }<span>{" " + puntaje.toFixed(0)}{enLinea ? '':' neuronas' }</span></p>

                            <div className="popOverBotones">
                                {enLinea?
                                    <button className={estiloJuegos.buttonEmpezar} onClick={onSalir}>FINALIZAR</button>:
                                    ((estado)?
                                    <DosBotonesColumna
                                        textoSuperior='JUGAR DE NUEVO'
                                        textoInferior={ esJuego ? 'VOLVER' : 'FINALIZAR'}
                                        onClickSuperior={onReiniciar}
                                        onClickInferior={onSalir}
                                        style={{width: "100%"}}
                                    />:
                                    <>
                                        <button className={estiloJuegos.buttonEmpezar} onClick={onReiniciar}>JUGAR DE NUEVO</button>
                                        <p className='linkSalir' onClick={onSalir}>salir</p>
                                    </>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>:
            <>
                <div className="coverturaSiguiente">
                    <div className="contenedorSiguiente">
                        <p className={`${style.mensajePersonalizado} popOverNextMensaje`}
                        >{"Impresionante Desempeño!"}</p>
                        {enLinea?
                            <button className={estiloJuegos.buttonEmpezar} style={{width: "95%"}} onClick={onContinuar}>
                                SIGUIENTE NIVEL
                            </button>:
                            <DosBotonesColumna
                                textoSuperior='SIGUIENTE NIVEL'
                                textoInferior={ esJuego ? 'VOLVER' : 'FINALIZAR'}
                                onClickSuperior={onContinuar}
                                onClickInferior={onSalir}
                                style={{width: "95%"}}
                            />
                        }
                    </div>
                </div>
            </>
        }
        </>
    );
};

export default PopoverNeuronasParejas;

