import React, { CSSProperties } from 'react'
import { Spinner } from 'react-bootstrap';

import estiloEntrenamiento from '../../../../../../theme/entrenamiento/EntrenamientoPage.module.css';

import { useAppSelector } from '../../../../../../redux/store';


interface Props{
    cargando: boolean;
    HandleRedirectEntrenamiento: () => void;
    titulo: string;
    idEntrenamiento: string;
    style?: CSSProperties;
}

const EntrenamientoBox:React.FC<Props> = ({cargando,HandleRedirectEntrenamiento,style,titulo,idEntrenamiento}) => {
    const entrenamientos_user = useAppSelector((state) => state.entrenamiento.entrenamientos_user);

    const BuscarEntrenamientoSiExiste = (nomEntrenamiento:string): string => {
        let bandera = entrenamientos_user?.find((element) => element === nomEntrenamiento)
        return !bandera ? estiloEntrenamiento.entrenamientoDiv_block : ''
    }

    const obtenerEstiloEntrenamiento = (idEntrenamiento:string) => {
        return estiloEntrenamiento[idEntrenamiento] || '';
      };

    return (
        <div style={style} className={`${estiloEntrenamiento.cardEntrenamiento} ${obtenerEstiloEntrenamiento(idEntrenamiento)} ${BuscarEntrenamientoSiExiste(idEntrenamiento)}`}
            onClick={HandleRedirectEntrenamiento}
            >
            {cargando?
                <Spinner></Spinner>:
                <label 
                    className={estiloEntrenamiento.label_entrenamiento} 
                    dangerouslySetInnerHTML={{ __html: titulo }} 
                />
            }
        </div>
    )
}

export default EntrenamientoBox