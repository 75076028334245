import React, { useState} from "react";
import { paises } from "../../../../../../datos/paises";
import Selector from "../../../../../components/Selector";

interface Props {
  setDatoLocal: (a: string, b: string) => void;
  datoAnterior: string;
  codigoAnterior: string;
}

const TelefonoComponent: React.FC<Props> = ({ setDatoLocal, datoAnterior, codigoAnterior }) => {
  const [telefono, setTelefono] = useState(datoAnterior);
  const [code, setCode] = useState(codigoAnterior);


  const handleEleccion = (respuesta: string) => {
    setTelefono(respuesta);
    setDatoLocal("telefono", respuesta);
  };

  const handleSelect = (respuesta: string) => {
    console.log("🚀 ~ file: TelefonoComponent.tsx:28 ~ handleSelect ~ respuesta:", respuesta)
    if (respuesta !== code) {
      setCode(respuesta)
      setDatoLocal("codigo", respuesta)
    }
  };

  return (
      <div className="item-telefono">
        <label>Telefono</label>
        <div className="select-nacimiento">
          <Selector opciones={paises} setSelection={handleSelect} selection={code} showValues={true}/>
          <input className="input-telefono"
              type="number"
              inputMode="numeric"
              value={telefono}
              onChange={(event) => handleEleccion(event.target.value)}
          />

        </div>
      </div>
  );
};

export default TelefonoComponent;
