import React, { CSSProperties, ReactNode, useState } from "react";
import styleJuegos2 from "../../../../../theme/Juego2.module.css";

interface PropResultados {
  // Array bidimensional a mostrar para esto la fila 0 lleva el cabezal de la columna
  // es un array de columnas => arrayAMostrar
  arrayAMostrar: string[][];
  calificaciones: boolean[];
  arrayItemImg?: ReactNode[];
  styleContenedorTabla?: CSSProperties;
}

const MostrarResultados: React.FC<PropResultados> = (props) => {
  const [tamano] = useState(
    props.arrayItemImg
      ? props.arrayAMostrar.length + 1
      : props.arrayAMostrar.length
  );

  const GetClassFilaText = (
    _indexColumn: number,
    _indexFila: number
  ): string => {
    let indiceColumna =
      _indexColumn - (props.arrayAMostrar.length === tamano ? 0 : 1);
    if (!_indexFila) {
      return styleJuegos2.tableItem_borderBottom;
    } else if (indiceColumna === props.arrayAMostrar.length - 1) {
      if (props.calificaciones[_indexFila]) {
        return styleJuegos2.correcta;
      } else {
        return styleJuegos2.incorrecta;
      }
    } else {
      return "";
    }
  };

  return (
    <div
      className={styleJuegos2.containTableResultados}
      style={props.styleContenedorTabla}
    >
      <table
        className={styleJuegos2.tableResultados}
        style={{
          gridTemplateColumns: `${props.arrayItemImg ? "20%" : ""} repeat(${
            props.arrayItemImg ? tamano - 1 : tamano
          },1fr)`,
        }}
      >
        {props.arrayAMostrar[0]?.map((_, _indexFila) => (
          <>
            {Array(tamano)
              .fill("")
              ?.map((_, _indexColumn) => (
                <>
                  {!_indexColumn && !_indexFila && props.arrayItemImg ? (
                    <div
                      key={`${_indexColumn}${_indexFila}`}
                      className={styleJuegos2.tableItem_borderBottom}
                    ></div>
                  ) : props.arrayItemImg && _indexColumn === 0 ? (
                    <div
                      key={`${_indexColumn}${_indexFila}`}
                      className={styleJuegos2.tableItem_img}
                    >
                      {props.arrayItemImg[_indexFila - 1]}
                    </div>
                  ) : (
                    <p
                      key={`${_indexColumn}${_indexFila}`}
                      className={GetClassFilaText(_indexColumn, _indexFila)}
                    >
                      {
                        props.arrayAMostrar[
                          _indexColumn -
                            (props.arrayAMostrar.length === tamano ? 0 : 1)
                        ][_indexFila]
                      }
                    </p>
                  )}
                </>
              ))}
          </>
        ))}
      </table>
    </div>
  );
};

export default MostrarResultados;
