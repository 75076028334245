
import { Route, Routes} from 'react-router-dom';
import AgilidadMental from './pages/AgilidadMental';
import JuegoSumas from './pages/calculo/JuegoSumas';
import JuegoSuperCerebrosDice from './pages/memoria/JuegoSuperCerebrosDice';
import JuegoMemoriaNumeros from './pages/memoria/MemoriaNumeros';
import TabJuegos from './pages/TabJuegos';
import RankingPage from './pages/RankingPage';
import JuegoMemoriaNombres from './pages/memoria/MemoriaNombres';
import JuegoMemoriaPalabras from './pages/memoria/MemoriaPalabras';
import Memoria from './pages/Memoria';
import JuegoDeMemoriaParejas from './pages/memoria/MemoriaParejas';
import Competencia from '../../PvP/Competencia';


const TabsJuegos = () => {

  return (
    <Routes>
      <Route path='/' element={<TabJuegos/>}/>
      <Route path="AgilidadMental" element={<AgilidadMental />} />
      <Route path="AgilidadMental/Sumas1" element={<JuegoSumas/>}/>							

	    <Route path="Memoria" element={<Memoria />} />
      <Route path="Memoria/SuperCerebrosDice" element={<JuegoSuperCerebrosDice />}/>					
      <Route path="Memoria/Nombres" element={<JuegoMemoriaNombres />}/>
      <Route path="Memoria/versus" element={<Competencia />}/>
      <Route path="Memoria/Palabras" element={<JuegoMemoriaPalabras />}/>
      <Route path="Memoria/Parejas" element={<JuegoDeMemoriaParejas />}/>	
      <Route path="Memoria/Numeros" element={<JuegoMemoriaNumeros  />}/>

      <Route path="ranking" element={<RankingPage />}/>
   </Routes>
    
  );
};

export default TabsJuegos;
