
/** Interna */
import  "../../../theme/Login-registro.css"; 

import { useEffect, useState } from 'react';

import { palabrasInvalidas } from '../../utils/NombresInvalidos';
import { useToast } from "../../../generalUtils/useToast";
import { useDato } from "../../../modelos/auth";
import { UsuarioDato, USUARIO_DATO_VACIO } from "../../../modelos/usuario";
import { useAppSelector, useAppDispatch } from "../../../redux/store";

const PruebaPage: React.FC = () => {

	console.log("prueba.tsx")
    const { presentDatosIncompletos, presentPasswordInvalido, presentDatosCompletos,presentNombresValidos,presentTelefonoValidos } = useToast();
	const loggedIn = useAppSelector(state => state.auth.loggedIn);
	const userId = useAppSelector(state => state.auth.userId);
    const [nombres, setNombres] = useState('');
    const [email, setEmail] = useState('');
	const dispatch = useAppDispatch();

	const {
		state,
		borrarCuenta,
		logOutCuenta,
		recogerDatos,
		guardarDatos
	} = useDato();
	useEffect(() => {
		agregarDatoLocal("telefono",1)
	}, []);	
    
	const [datosLocales, setDatosLocales] = useState<UsuarioDato>(state?._usuarioDato);
    const agregarDatoLocal = (campo, valor) => {
		const datosLocalesTmp = { ...datosLocales };
		datosLocalesTmp[campo] = valor;
		setDatosLocales(datosLocalesTmp)
	}
    const checkDatosLocal = () => {
		console.log(datosLocales)
		if (datosLocales === undefined) {
			return false;
		}
		if (Object.keys(datosLocales).length !== Object.keys(USUARIO_DATO_VACIO).length) {
			setDatosLocales(USUARIO_DATO_VACIO);
			return false;
		}
		if (!Object.values(datosLocales).every((dato) => dato !== null && dato !== undefined && dato !=="")) {
			return false;
		}

		return Object.values(datosLocales).every((dato) => dato?.trim()?.length > 0)
	}
    const comprobacionNombres = () =>{
		const nombres = datosLocales.nombres.split(" ");
		for (let i = 0; i < nombres.length; i++) {
			if(compararPalabras(nombres[i],palabrasInvalidas)){
				return true;
			}
		}

		return false;
	}
    const limpiarString = (str: string) => {
		return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();
	}
    const compararPalabras = (palabra: string, arrayPalabras: string[]): boolean => {
				
		for (let i = 0; i < arrayPalabras.length; i++) {			
		  if (limpiarString(palabra) === limpiarString(arrayPalabras[i])) {
			return true;
		  }
		}
		return false;
	};
    const checarGuardar = () => {
		  
		
		if (!checkDatosLocal()) {
			console.log(!checkDatosLocal());
			presentDatosIncompletos();
		}
		else {
			if(comprobacionNombres() ){
				presentNombresValidos();
			}
			}
						
	}
    const handleCorreo = (respuesta : string) => {
        setEmail(respuesta)
        agregarDatoLocal("correo",email)
    }
	

	return (
		<div id='page'> 
		</div >
	);
}
export default PruebaPage;