// /features/juegoFechas/components/Resultado.tsx
import React from 'react';
import styles from '../../../../../../../../theme/Juego2.module.css';

interface Props {
  correctas: number;
  total: number;
  reiniciar: () => void;
  resultadoFinal: string;
}

const Resultado: React.FC<Props> = ({ correctas, total, reiniciar, resultadoFinal }) => {
  return (
    <div className={styles.resultado}>
      <h2>{resultadoFinal}</h2>
      <p>
        TUVISTE {correctas}/{total - 1} RESPUESTAS CORRECTAS
      </p>
      <button id='button_rosado_default' style={{width:'fit-content',alignSelf:'center'}}
      className={styles.botonReiniciar} onClick={reiniciar}>
        JUGAR DE NUEVO
      </button>
    </div>
  );
};

export default Resultado;
