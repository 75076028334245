import { useNavigate } from "react-router-dom";

interface NavigateComponentProps {
  onclick?: () => any;
  className?: string;
  ruta: string;
  children: any;
  style?: any;
}

const NavigateComponent: React.FC<NavigateComponentProps> = ({
  className,
  ruta,
  children,
  onclick,
  style
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    onclick()
    navigate(ruta);
  };

  return (
    <div onClick={handleClick} className={className} style={style}>
      {children}
    </div>
  );
};

export default NavigateComponent;
