import {useNavigate } from 'react-router-dom'
import style from "./politicasPrivacidad.module.css"
const PoliticasPrivacidad: React.FC = () => {
    const navigate  = useNavigate();
    function salir()  {
        navigate(-1);
    }
    return(
        <div id='page' style={{zIndex:'12', width: '60%'}}>
            <div id='toolbar_default'>
                <h1 className="ion-text-center title">Política de Privacidad</h1>
            </div>
                
            <div id='content_default' style={{overflow:'scroll'}}>
                <div style={{paddingLeft:"5%",paddingRight:"5%",color:"black"}}>
                    <h1><strong>Política de Privacidad de SuperCerebros</strong></h1>
                    <p className={style.text}>Fecha de última actualización: 01/09/2024</p>
    
                    <h2 className={style.item}><strong>1. Introducción</strong></h2>
                    <p className={style.text}>En SuperCerebros, valoramos tu privacidad y estamos comprometidos con la protección de tu información personal. Esta Política de Privacidad tiene como objetivo explicarte cómo recopilamos, utilizamos y protegemos tu información. Cumplimos con las normativas de la Ley 1581 de 2012 de Colombia y el Reglamento General de Protección de Datos (GDPR) de la Unión Europea.</p>
    
                    <h2 className={style.item}><strong>2. Información que recopilamos</strong></h2>
                    <p className={style.text}>Podemos recopilar la siguiente información personal:</p>
                    <ul className={style.lista}>
                        <li>Nombres</li>
                        <li>Correo electrónico</li>
                        <li>Número de teléfono</li>
                        <li>Información relacionada con tu participación en nuestros talleres y eventos (asistencia, intereses, preferencias)</li>
                    </ul>
    
                    <h2 className={style.item}><strong>3. Uso de la información</strong></h2>
                    <p className={style.text}>Utilizamos tu información personal para los siguientes propósitos:</p>
                    <ul className={style.lista}>
                        <li>Crear y gestionar tu cuenta de usuario en SuperCerebros.</li>
                        <li>Enviarte comunicaciones sobre talleres, eventos y contenido educativo relevante.</li>
                        <li>Mejorar nuestra plataforma y adaptar los servicios a tus necesidades.</li>
                        <li>Responder a tus consultas y proporcionar soporte técnico.</li>
                    </ul>
                    <p className={style.text}>SuperCerebros no vende, comparte ni divulga tu información personal a terceros, salvo cuando sea necesario para prestarte los servicios o si es requerido por la ley.</p>
    
                    <h2 className={style.item}><strong>4. Derechos del usuario</strong></h2>
                    <p className={style.text}>De acuerdo con el GDPR y la Ley 1581 de 2012, tienes derecho a:</p>
                    <ul className={style.lista}>
                        <li>Acceder a la información que tenemos sobre ti.</li>
                        <li>Solicitar la corrección de información inexacta.</li>
                        <li>Solicitar la eliminación de tu información personal.</li>
                        <li>Oponerte al tratamiento de tus datos o solicitar restricciones en el uso de los mismos.</li>
                        <li>Solicitar la portabilidad de tus datos (transmitir tus datos a otro proveedor).</li>
                    </ul>
                    <p className={style.text}>Para ejercer estos derechos, puedes contactarnos en <a href="mailto:info@supercerebros.com">info@supercerebros.com</a>.</p>
    
                    <h2 className={style.item}><strong>5. Seguridad de la información</strong></h2>
                    <p className={style.text}>En SuperCerebros implementamos medidas técnicas y organizativas adecuadas para proteger tus datos personales contra accesos no autorizados, pérdida, robo o divulgación. Aun así, reconocemos que ninguna medida de seguridad es perfecta, y no podemos garantizar la completa seguridad de la información transmitida.</p>
    
                    <h2 className={style.item}><strong>6. Conservación de los datos</strong></h2>
                    <p className={style.text}>Conservamos tu información personal solo durante el tiempo necesario para cumplir con los fines descritos en esta Política de Privacidad o conforme a los requisitos legales aplicables.</p>
    
                    <h2 className={style.item}><strong>7. Cookies y tecnologías de seguimiento</strong></h2>
                    <p className={style.text}>Nuestro sitio web puede utilizar cookies y otras tecnologías de seguimiento para mejorar la experiencia del usuario y analizar el tráfico del sitio. Puedes gestionar las cookies a través de la configuración de tu navegador.</p>
    
                    <h2 className={style.item}><strong>8. Enlaces a sitios de terceros</strong></h2>
                    <p className={style.text}>Nuestro sitio web puede contener enlaces a sitios web de terceros. No somos responsables de las políticas de privacidad de esos sitios y te recomendamos revisar sus términos antes de proporcionar cualquier información.</p>
    
                    <h2 className={style.item}><strong>9. Cancelación de la Suscripción</strong></h2>
                    <p className={style.text}>Puedes darte de baja de nuestros mensajes en cualquier momento respondiendo con "STOP" a cualquier mensaje que te enviemos o contactándonos a través de <a href="mailto:info@supercerebros.com">info@supercerebros.com</a>.</p>
    
                    <h2 className={style.item}><strong>10. Contacto</strong></h2>
                    <p className={style.text}>Si tienes preguntas o inquietudes sobre esta Política de Privacidad o sobre cómo gestionamos tu información personal, puedes contactarnos en <a href="mailto:info@supercerebros.com">info@supercerebros.com</a>.</p>
    
                </div>
                <button id='button_rosado_default' style={{maxWidth:'60%',margin:'2% 0'}} onClick={salir} >Ok</button>
            </div>
        </div>
    );
    
}
export default PoliticasPrivacidad;
