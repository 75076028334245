import React, { ReactNode, useEffect, useState} from "react";
import NavegacionImagen from "./NavegacionImagen";
import CirculosDeSeleccion from "./CirculosObjetoSeleccionado";
import estiloJuegos2 from "../../../../../../../theme/Juego2.module.css"

interface TerceraPantallaConImagenesProp{
    resetContenido: () => void;
    contenidoIdIndice: number;
    cantidadDeValores: number;
    objetoIdAleatorio?: string[];
    leftSubmit: () => void;
    rightSubmit: () => void;
    aSiguientePantalla: () => void;
    getObjetos: () => ReactNode; 
    guardaInput: any;
    userInputsTemporal: string[];
}

/**
 * llama a la funcion que va a gestionar las respuestas de usuario. 
 * 
 * Falta cambiar diseño al eliminar ionic no hay un vista aun
 */

const TerceraPantallaConImagenes:React.FC<TerceraPantallaConImagenesProp> = (props) => {
    
    const [index,setIndex]=useState<number>(0);
    const left= () =>{
        props.leftSubmit();
        inputs()
    }
    useEffect(() => {
        if (props.objetoIdAleatorio) {
            setIndex(parseInt(props.objetoIdAleatorio[props.contenidoIdIndice]));
        } else {
            setIndex(index =>index+1);
        }
    }, [props.contenidoIdIndice, props.objetoIdAleatorio]);
    
    const right = () =>{
        props.rightSubmit();
        inputs()
    }

    const handleGuardarInput = (e:React.ChangeEvent<HTMLInputElement>) => {
        console.log("🚀 ~ useEffect ~ props.objetoIdAleatorio[props.contenidoIdIndice]:", props.objetoIdAleatorio)
        console.log("🚀 ~ useEffect ~ props.contenidoIdIndice:", props.contenidoIdIndice)
        console.log("🚀 ~ handleGuardarInput ~ index:", index)
        console.log("🚀 ~ inputs ~ props.userInputsTemporal:", props.userInputsTemporal)
        props.guardaInput(e.target.value,index)
    }

    const inputs = () =>{
        return(
            
            <div key={index} id="inputs" style={{height:'100%'}}>
                <div style={{height:'100%'}}>
                    <input style={{textAlign:'center',height:'100%'}} 
                    onChange={handleGuardarInput}
                    value={props.userInputsTemporal[index]}
                    id={'input-' + index}
                    placeholder={"Respuesta aqui"}
                    >
                </input>
                </div>
            </div>
            
            
        );
    }
    return (
        <>
            <div style={{display:'flex',flexDirection:'column',padding:'0% 0% 0% 0% !important', width:'100%', height: '-webkit-fill-available',overflowY: 'scroll'}}>
                <div>
                    <NavegacionImagen obtenerImagen={props.getObjetos} 
                    />
                </div>
                <CirculosDeSeleccion cantidadDeValores={props.cantidadDeValores} contenidoIdIndice={props.contenidoIdIndice} getObjetos={props.getObjetos}/>
                <div style={{display: "flex",flexDirection: "column", justifyContent: "flex-end",textAlign:'center',width:'80%',margin:'5% 10%',height:'10svh'}}>
                    {inputs()}                
                </div>
                <div style={{display:'flex',justifyContent:'center',gap: '0.55svh'}}>
                    <button className={estiloJuegos2.nv_button} onClick={left}>
                        <img src='/assets/icon/flechaBlancoSend.svg' alt='Go' style={{transform:'rotate(180deg)'}}></img>
                    </button>
                    {props.contenidoIdIndice < props.cantidadDeValores-1 ? 
                    <button className={estiloJuegos2.nv_button} onClick={right}>
                        <img src='/assets/icon/flechaBlancoSend.svg' alt='Go'></img>
                    </button> 
                    : <button className={estiloJuegos2.nv_button} style={{width:'24.2svh',marginLeft:'14.24px'}} onClick={props.aSiguientePantalla}>TERMINAR</button>}
                </div>
            </div>
        </>
    );
}

export default TerceraPantallaConImagenes;