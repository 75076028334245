import estiloJuegos from "../../../../../theme/Juegos.module.css";

import { useEffect, useState } from "react";

import TestMemoria from "./TestMemoriaInicial";
import { nomTestMemNumerosInicial } from "../../../../../datos/nombresBD";

const TestMemoriaNumeros = () =>{
    const [Datos, setDatos] = useState<string[]>([]);

    
    const titulo = "Memorización de números"
    
    const getDescripcion = () => {
        return (
            <div className={[estiloJuegos.descripcionJuego,estiloJuegos.descripcionMemoriaNumeros].join(" ")}>
                ¡Desafío de memoria en marcha! 
                <br/>
                <h2 style={{fontSize:"2.37vh"}}>Durante los próximos 60 segundos, te presentaremos números.<br></br> Concentra tu mente y ¡ve cuántos puedes recordar!</h2>
            </div>
        );
    }

    const getRandNum = (max : number, min : number) => {
        return String(Math.floor(Math.random() * (max - min + 1)) + min);
    }
    const ArrayDeNumeros = (cuenta: number,digitos: number) => {
        let i = 0;
        let j = 0;
        const max = 9
        const min = 1
        let num = "";
        const arr = [];
        while (i < cuenta) {
            num += getRandNum(max, min);
            
            j++;
            
            if (j === digitos) {
                if(!arr.includes(num)){
                    arr.push(num);
                    i++;
                }
                num = "";
                j = 0;
            }
        }
        if(num.length){
            arr.push(num);
        }
        return arr;	    
    }
    
    useEffect(() => {
        const numeros = [
          ...ArrayDeNumeros(14, 2),
          ...ArrayDeNumeros(3, 1),
          ...ArrayDeNumeros(3, 3),
        ];
        setDatos(numeros);
        
      }, []);
    return(
        <TestMemoria  
        getDescripcion={getDescripcion}
        idTest={nomTestMemNumerosInicial}
        nombreTests={titulo}
        datos={Datos}
        cantidadDatos={20}
        inputType="number"
        tituloDatos="Número"
        />
    );
};
export default TestMemoriaNumeros;