import style from "../../../../theme/Juego2.module.css";

import React from "react";

import { CuestionarioActividad } from "../../../../modelos/JuegoCuestionario";

interface PropResultados{
    cuestionario: CuestionarioActividad;
    getResultado: (index) => boolean;
    opcionesSeleccionada: number[];
}

const ResultadosCuestionario: React.FC<PropResultados> = (props) => {    
    const getResultado = (numeroIndice: number) => { 

        return(
            <div style={{display:'flex',flexDirection:'row', justifyContent:'center',marginLeft:'0%', height:"100%"}}>
                {
                    <div
                        className={props.getResultado(numeroIndice) ? style.correcta : style.incorrecta} 
                        style={{fontSize:'0.9em',padding:'0.5em'}}>
                        {props.opcionesSeleccionada[numeroIndice] === undefined ? 'No Respondio': props.cuestionario.preguntas[numeroIndice].respuestas[props.opcionesSeleccionada[numeroIndice]]}
                    </div>
                }
            </div>
        );
    }     
    return (
        <div className={style.grid_resultados} style={{gridTemplateColumns:'1fr 1fr',gap:'10px',maxHeight:'55svh',overflow:'auto'}}>
            <label>
                <h1 className={style.subtitleRespuestas} style={{fontSize:'1.4em'}}>Secuencia</h1>
            </label>
            <label>
                <h1 className={style.subtitleRespuestas} style={{fontSize:'1.4em'}}>Respuesta </h1>
            </label>
            <div className={style.linea} style={{gridColumn:'1/2 span'}}></div>
                {props.cuestionario?.preguntas.map((element,index) => (
                    <>
                        <div className={style.grid_resultados_div}>
                            <p style={{fontSize:'0.9em',fontWeight:'700'}}>
                                {element.textoPregunta}
                            </p>
                        </div>
                        {getResultado(index)}
                    </>
                )) }
        </div>
    );
}

export default ResultadosCuestionario;