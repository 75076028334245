import style from '../../../theme/programas/simultaneo/simultaneo.module.css'

import React, { useEffect, useState } from 'react'

import HostComponent from './HostComponent';
import PlayerComponent from './PlayerComponent';
import { useDato } from '../../../modelos/auth';
import { newActivity } from '../../../redux/actividad/reducerActividad';
import { useAppDispatch, useAppSelector } from '../../../redux/store';

const JuegoSimultaneo = () => {
    const dispatch = useAppDispatch();
    const loggedIn = useAppSelector(state => state.auth.loggedIn)

    const [host, setHost] = useState(null);
    const [sala, setSala] = useState('');
    const [reingresar, setReingresar] = useState(false);
    const {
        state
    } = useDato()

    const pageStyle = (): React.CSSProperties => {
        if(host === false){
            return (loggedIn)? {}: {height: "100%"};
        }
        return {overflowY:'scroll'};
    }

    const HandleReingresarSala = () => {
        console.log("🚀 ~ HandleReingresarSala ~ HandleReingresarSala:")
        setHost(true)
    }

    useEffect(() => {
        dispatch(newActivity({id:'juego_linea',name:'juegos simultaneos'}))
        if(!loggedIn || !state?._administrador) setHost(false)
    }, [])
    
    return (
        <div id='page' style={pageStyle()}>
            <div id='toolbar_default' style={host === false ? {background:'var(--color-Pink-Light)'} : {}}>
                <h1 className='title'>
                    {host === false ? 'JUGADOR' : `SALA:${sala??" ..."}`}
                </h1>
                <svg onClick={() => {setReingresar(true)}} className={style.buttonSync} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <g clip-path="url(#clip0_4600_6034)">
                    <path d="M10.861 17.7833C11.3523 17.4444 11.8425 17.1099 12.3293 16.7699C12.7213 16.4957 13.1025 16.2042 13.5013 15.9396C14.3499 15.3776 15.3595 15.5689 15.925 16.3856C16.4426 17.1343 16.2462 18.1628 15.4418 18.7331C13.4893 20.1187 11.5288 21.4919 9.5655 22.8616C8.79308 23.4006 7.77774 23.2402 7.20815 22.4505C6.24923 21.1219 5.32645 19.7657 4.38796 18.4225C4.00603 17.8751 3.61995 17.3299 3.23944 16.7812C2.6142 15.8838 2.78824 14.8502 3.66276 14.2454C4.47396 13.6848 5.52793 13.947 6.12319 14.8373C6.54443 15.467 6.98954 16.0805 7.47262 16.7702C7.55132 16.5687 7.61113 16.4244 7.66459 16.2782C10.8852 7.47608 21.6221 4.35419 28.8615 10.1148C34.7014 14.7609 35.3894 23.5952 30.3549 29.2903C25.4845 34.7996 17.259 35.5535 11.6687 30.9845C11.0614 30.4879 10.5206 29.9029 9.99492 29.3172C9.28971 28.5312 9.35449 27.4733 10.0985 26.8217C10.847 26.1651 11.9131 26.2953 12.6287 27.0822C15.4403 30.176 18.9291 31.2491 23.0084 30.1012C29.4179 28.2975 32.3235 20.6046 28.7343 15.1224C25.702 10.4902 19.7168 9.24216 14.933 12.2936C12.986 13.5349 11.6323 15.2892 10.876 17.4761C10.851 17.549 10.8329 17.6231 10.8149 17.6973C10.8119 17.7081 10.8285 17.7239 10.8616 17.784L10.861 17.7833Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_4600_6034">
                    <rect width="27.0537" height="28.7991" fill="white" transform="translate(0 17.2744) rotate(-39.6827)"/>
                    </clipPath>
                    </defs>
                </svg>
            </div>
            <div id='content_default' className={style.content_background} style={host === false? {height: "90%"}: {}} >
                <div className={style.begin} style={host === null ? {} : {display:'none'}}>
                    <h1>¡Bienvenido supercerebro!</h1>
                    <p>¿Listo para demostrar que eres el mejor en nuestro super torneo?</p>
                    <p style={{fontWeight:'700',fontSize:'2.576svh'}}>¿Qué deseas hacer?</p>
                    <button className={style.begin_button_host} onClick={() => setHost(true)}>Crear sala</button>
                    <div><p>O</p></div>
                    <p>REINGRESAR A SALA</p>

                    <input value={sala} id='room' onChange={(event) => {setSala(event.target.value)}} placeholder='CODIGO SALA'></input>
                    <br></br>
                    <button id='button_rosado_default' onClick={HandleReingresarSala} style={{width:'100%'}}>REINGRESAR</button>
                </div>
                {host === true && 
                    <HostComponent setIdSala={setSala} reingresar={sala} reboot={reingresar} setReboot={setReingresar}/>
                }
                {host == false && 
                    <PlayerComponent reboot={reingresar} setReboot={setReingresar}/>
                }
            </div>
        </div>
    )
}

export default JuegoSimultaneo
