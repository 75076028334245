import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createUserWithEmailAndPassword} from "firebase/auth";

import stylePrograma from '../../../../../theme/admin/programa.module.css'
import styleEntidad from '../../../../../theme/admin/entidad.module.css'

import { auth } from "../../../../../firebase";
import { useToast } from "../../../../../generalUtils/useToast";
import { useDato } from "../../../../../modelos/auth";
import { IntegranteEntidad, aIntegrante } from "../../../../../modelos/entidad/IntegranteEntidad";
import { createUsuario, USUARIO_DATO_VACIO_CON_CORREO } from "../../../../../modelos/usuario";
import { creater } from "../../../../../redux/auth/reducerAuth";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { useControl } from "../../../../../repositorio/controlador/useControl";
import { useEntidades } from "../../../../../repositorio/controlador/useEntidades";



type Params = {
  nombreEntidad: string;
  idEntidad: string;
  idArea: string;
}

const AreaPage = () => {
  const { idEntidad, idArea } = useParams<Params>();
  const [visibleModal, setVisibleModal] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [nuevosUsuarios, setNuevosUsuarios] = useState("");
  const [nuevosCheck, setNuevosCheck] = useState([]);
  const [statusCreation, setStatusCreation] = useState([]);
  const dispatch = useAppDispatch();
  const logggedIn = useAppSelector((state) => state.auth.loggedIn);
  const userId = useAppSelector((state) => state.auth.userId);
  const { presentNoIngresoUsuario } = useToast();
  const navigate = useNavigate();

  const {
    crearIntegranteEntidad,
    consultarIntegrantes,
  } = useEntidades();

  const {
    consultarUsuario,
} = useControl();

  const { guardarDatos } = useDato();


  const consultaArea = async () => {
    const respuesta: IntegranteEntidad[] = await consultarIntegrantes(
      idEntidad,
      idArea
    );
    setUsuarios(respuesta);
  };

  useEffect(() => {
    consultaArea();
    setStatusCreation([]);
    setNuevosCheck([]);
  }, [visibleModal]);

  const crearUsuarioMasivo = async () => {
    dispatch(creater());

    for (const element of nuevosCheck) {
      try {
        const credential = await createUserWithEmailAndPassword(auth,element.correo,element.correo);
        console.log("Creada con exito ", credential.user.email);
        
        // Llamamos a la función para crear el integrante del área
        await crearIntegranteEntidad(idEntidad,credential.user.uid,aIntegrante(element.correo));
        
        await guardarDatos(credential.user.uid,createUsuario(false,false, true, USUARIO_DATO_VACIO_CON_CORREO(element.correo)));
        
        } catch (error) {
            if (error.code === "auth/email-already-in-use") {
                const credential = await consultarUsuario(element.correo);
                await crearIntegranteEntidad(idEntidad,credential.id,aIntegrante(element.correo));
                await guardarDatos(credential.id,createUsuario(false,false, true, USUARIO_DATO_VACIO_CON_CORREO(element.correo)));
                console.log("Creada con exito ", credential.correo);
            } else {
                const newError = { mensaje: "Registro fallido - " + element.correo };
                setStatusCreation((prevState) => [...prevState, newError]);
            }
        }
        console.log("logggedIn: ", logggedIn);
        console.log("userId: ", userId);
    }

    setNuevosUsuarios("");
    dispatch(creater());
    // const credentials = await signInWithEmailAndPassword(auth,userAdmin,"SuperDesarrollador2023");
    // await dispatch(login({userId:credentials.user.uid, email:credentials.user.email}));
  };

  const checking = () => {
    const nuevos = nuevosUsuarios.split("\n");
    nuevos.map((element) => {
      setNuevosCheck((prevState) => [
        ...prevState,
        { correo: element, test: true },
      ]);
    });
  };
  

  const capturar = () => {
    if (nuevosUsuarios === "") {
      presentNoIngresoUsuario();
    } else {
      checking();
    }
  };

  return (
    <div className={stylePrograma.page}>
      <header className={stylePrograma.header}>
          <div className={stylePrograma.toolbar}>
              <button className={stylePrograma.backButton} onClick={() => navigate(-1)}/>
              <h1>Usuarios</h1>
          </div>
      </header>
      <div className={stylePrograma.content}>
        <div className={stylePrograma.lista}>
          {usuarios.map((element) => (
            <div className={stylePrograma.card} key={element.id}>
              <p>{element.correo}</p>
            </div>
          ))}
          {usuarios.length === 0 ? (
            <p>No hay integrantes</p>
          ) : (
            <br />
          )}
        </div>
        <button
          className={stylePrograma.button}
          onClick={() => {
            setVisibleModal(true);
          }}
        >
          Crear Usuarios
        </button>
      </div>
      <div className={visibleModal ? styleEntidad.modalActive : styleEntidad.modalDesactive}>
        <header className={styleEntidad.headerCard}>
          <div className={stylePrograma.toolbar}>
              <h1>Crear Usuarios</h1>
              <img className={styleEntidad.exit} src="/assets/icon/closeCircle.svg" onClick={() => setVisibleModal(false)}/>
          </div>
        </header>
        <div className={styleEntidad.content}>
          <div className={stylePrograma.lista}>
            <div className={styleEntidad.containerInput}>
              <p >Usuarios</p>
              <textarea 
                className={styleEntidad.area}
                rows={10}
                cols={50}
                placeholder="example1@gmail.com &#10; example2@hotmail.com &#10; example2@hotmail.com"
                value={nuevosUsuarios}
                onChange={(event) => {setNuevosUsuarios(event.target.value)}}/>
            </div>
            <button className={stylePrograma.button} onClick={capturar}>
              Agregar Usuarios
            </button>
          </div>
          <div className={stylePrograma.lista}>
            <button className={stylePrograma.button}  onClick={crearUsuarioMasivo}>
              ok
            </button>
          </div>
          <ul>
            {statusCreation.map((error) => (
              <li>
                <p>{error?.mensaje}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AreaPage;
