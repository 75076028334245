import React from 'react'
import { meses } from '../../../../../datos/paises';

interface Props{
    tipo:string;
}

export const BannerTablaNeuronas = ({tipo}:Props) => {
    var d = new Date();

    // Calcular la fecha del domingo anterior
    var lastSunday = new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate()));
    lastSunday.setUTCDate(d.getUTCDate() - (d.getUTCDay() || 7));

    // Calcular la fecha del próximo domingo
    var nextSunday = new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate()));
    nextSunday.setUTCDate(d.getUTCDate() + (7 - (d.getUTCDay() || 7)));

    const formatDateToDDMM = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Los meses van de 0 a 11
        let mes = meses[month]
        return `${day}/${mes}`;
    };

  return (
    <div style={{display:'flex',flexDirection:'row',height:'fit-content',padding:'5% 2%',paddingBottom:'10%',gap:'2%',justifyContent:'space-between'}}>
        <div>
        <p style={{fontFamily: 'Montserrat',width:'100%',fontSize: '1.025rem',fontWeight:'bolder',margin:'0',color:'#45225F',textTransform:'uppercase'}}>Ranking</p>
        <p style={{fontFamily: 'Montserrat',width:'100%',fontSize: '1.325rem',fontWeight:'bolder',margin:'0',color:'#45225F',textTransform:'uppercase'}}>
            {tipo == "TOTAL" ? 'total' : 'Semanal'}
        </p>
        </div>
        <div style={{display: 'flex',flexDirection: 'column',alignItems: 'center'}}>
            <p style={{fontFamily: 'Montserrat',fontWeight:'800',margin:'0',marginTop:'2%',height:'fit-content',color:'#45225F',textTransform:'capitalize'}}>{formatDateToDDMM(lastSunday)}</p>
            <p style={{fontFamily: 'Montserrat',fontWeight:'800',margin:'0',marginTop:'2%',height:'fit-content',color:'#45225F',textTransform:'capitalize'}}>{formatDateToDDMM(nextSunday)}</p>
        </div>
    </div>
  )
}
