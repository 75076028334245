import "../../../../../../theme/entrenamiento/entrenamiento.css";
import style from "../../../../../../theme/entrenamiento/entrenamientoActividades.module.css";

import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  EncuentraMenorARegistro,
  EstadosSesion,
  useProxyEntrenamiento,
} from "../../utils/UtilsEntrenamiento";
import ActividadBox from "../components/ActividadBox";
import { Modal } from "react-bootstrap";
import { Actividad } from "../../../../../../modelos/entrenamiento/Actividad";
import {
  ChangeRedux_finishSesion,
  agregarSesionVista,
} from "../../../../../../redux/entrenamiento/reducerEntrenamiento";
import { useAppSelector, useAppDispatch } from "../../../../../../redux/store";
import Loading from "../../../../../components/Loading";
import ProgressBarTraining from "../components/ProgressBarEntrenamiento";
import supercerebrovolando from "../../../../../../../src/theme/img/SuperCerebroVolando.png";

type Props = {
  idEntrenamiento: string;
  id: string;
};

const ActividadesPage = () => {
  const { id, idEntrenamiento: idEntrenamientoURL } = useParams<Props>();
  const cargarProxy = useProxyEntrenamiento(idEntrenamientoURL, id);

  const finishSesion = useAppSelector(
    (state) => state.entrenamiento?.finishSesion
  );
  const currentRegistroUsuarios = useAppSelector(
    (state) => state.entrenamiento?.current_registro_usuario
  );
  const currentSesionRedux = useAppSelector(
    (state) => state.entrenamiento?.current_sesion
  );
  const currentActividadesRedux = useAppSelector(
    (state) => state.entrenamiento?.current_actividades
  );
  const sesionesVistas = useAppSelector(
    (state) => state.entrenamiento.sesionesVistas
  );
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const dia = currentSesionRedux + 1;

  const [showFinish, setShowFinish] = useState<boolean>(false);
  const [array, setArray] = useState([]);
  const [loading, setLoading] = useState(!currentActividadesRedux);

  useLayoutEffect(() => {
    console.log("[ActividadesPage] Inicializando datos:");
    cargarProxy();
    const initialShowFinish =
      finishSesion === Number(id) && !sesionesVistas.includes(Number(id));
    setShowFinish(initialShowFinish);
    console.log("  - Valor inicial de showFinish:", initialShowFinish);
  }, []);

  const Consulta = () => {
    console.log("[ActividadesPage] Consultando actividades para la sesión:", id);
    const activities = currentActividadesRedux.map((element) => {
      const completado = EncuentraMenorARegistro(
        Number(id) + 1,
        element.orden,
        currentRegistroUsuarios
      );
      console.log(`  - Actividad ${element.orden}: completado = ${completado}`);
      return {
        ...element,
        completado,
      };
    });

    const allActivitiesCompleted = activities.every(
      (activity) => activity.completado === EstadosSesion.COMPLETADO
    );

    // Solo muestra el modal si es la primera vez que se completa la sesión y aún no está en `sesionesVistas`
    if (allActivitiesCompleted && finishSesion !== Number(id) && !sesionesVistas.includes(Number(id))) {
      console.log("  - Todas las actividades están completas por primera vez.");
      dispatch(ChangeRedux_finishSesion(Number(id)));
      setTimeout(() => {
        console.log("  - Mostrando el modal de finalización.");
        setShowFinish(true);
      }, 100);
    } else {
      console.log("  - Aún hay actividades sin completar o la sesión ya ha sido vista.");
    }

    setArray(activities);
    setLoading(false);
  };

  const closeModal = () => {
    console.log("[ActividadesPage] Modal cerrado.");
    setShowFinish(false);
    // Al cerrar el modal, marca la sesión como vista en `sesionesVistas` solo después de que se haya completado la primera vez
    dispatch(agregarSesionVista(Number(id)));
    dispatch(ChangeRedux_finishSesion(null));
  };

  const HandleGoBakc = () => {
    console.log("[ActividadesPage] Navegando a la página principal del entrenamiento.");
    return navigate(`/my/entrenamiento/${idEntrenamientoURL}`);
  };

  const HandleClick = (element: Actividad, index: number) => {
    if (element.completado) {
      console.log("[ActividadesPage] Navegando a la actividad:", index);
      return navigate(`/my/entrenamiento/${idEntrenamientoURL}/${id}/${index}`);
    }
  };

  useEffect(() => {
    console.log("[ActividadesPage] Ejecutando efecto para la sesión:", id);
    if (currentSesionRedux === parseInt(id)) {
      if (currentActividadesRedux?.length === 1) {
        console.log("  - Solo una actividad. Navegando automáticamente a la primera actividad.");
        navigate(`0`);
      } else {
        Consulta();
      }
    }
  }, [
    currentSesionRedux,
    currentRegistroUsuarios,
    id,
    currentActividadesRedux?.length,
  ]);

  useEffect(() => {
    const shouldShowFinish =
      finishSesion === Number(id) && !sesionesVistas.includes(Number(id));
    setShowFinish(shouldShowFinish);
    console.log("[ActividadesPage] Revisando condiciones para mostrar el modal:");
    console.log("  - FinishSesion:", finishSesion);
    console.log("  - ID actual:", id);
    console.log("  - Sesiones vistas:", sesionesVistas);
    console.log("  - Estado de showFinish:", shouldShowFinish);
  }, [finishSesion, id, sesionesVistas]);

  return (
    <div id="page" style={{ height: "100vh", overflowY: "auto" }}>
      <Loading isOpen={loading} />
      {!loading && currentSesionRedux === parseInt(id) && (
        <div
          className="contenedor_actividades fondo"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="contenedor_titulo_actividad">
            <button
              className="backButtonEntrenamiento"
              onClick={HandleGoBakc}
            ></button>
            <h1 className="dia" style={{ fontSize: "3em", lineHeight: "105%" }}>
              {" "}
              DÍA {dia}{" "}
            </h1>
          </div>
          <div
            style={{
              marginTop: "3svh",
              flex: "1",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {array.length >= 0 && (
              <div
                style={{
                  width: "70%",
                  alignSelf: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <ProgressBarTraining
                  actual={array.filter((item) => item.completado === 1).length}
                  total={array.length}
                />
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "5% 10%",
              }}
              className={style.fuente_activity}
            >
              {array.map((item, index) => (
                <ActividadBox
                  onClick={() => {
                    HandleClick(item, index);
                  }}
                  status={item.completado}
                  title={
                    !item.titulo ? `Actividad ${index + 1}` : item?.titulo
                  }
                ></ActividadBox>
              ))}
              {array?.length > 1 && (
                <button
                  className={style.ButtonFinalizar_activity}
                  style={
                    EncuentraMenorARegistro(
                      Number(id) + 1,
                      array.length,
                      currentRegistroUsuarios
                    ) === EstadosSesion.COMPLETADO
                      ? {
                          background: "var(--color-purple-dark)",
                          color: "white",
                        }
                      : {}
                  }
                  onClick={HandleGoBakc}
                >
                  VOLVER
                </button>
              )}
            </div>
          </div>
          {showFinish && (
            <Modal
              className={`${style.animationSliceCenter} ${style.contentModal}`}
              onClickOutside={closeModal}
              show={showFinish}
            >
              <div className={style.imagenSCVolando}>
                <button className={style.closeButton} onClick={closeModal}>
                  X
                </button>
                <div className={style.imageContainer}>
                  <img
                    src={supercerebrovolando}
                    alt="Super Cerebro Volando"
                  />
                </div>
                <p>
                  {currentRegistroUsuarios.completadoEntrenamiento
                    ? "Completaste la sesión ¡Sigue así!"
                    : "Completaste el entrenamiento ¡Felicidades!"}
                </p>
                <button
                  id="button_rosado_default"
                  className={style.buttonModal}
                  onClick={closeModal}
                >
                  {currentRegistroUsuarios.completadoEntrenamiento
                    ? "Finalizar"
                    : "Siguiente Sesión"}
                </button>
              </div>
            </Modal>
          )}
        </div>
      )}
    </div>
  );
};


export default ActividadesPage;
