  import React, { useEffect, useState } from 'react';

  import style from "../../../../../../../../theme/programas/calculo/Game1.module.css";
  import estiloNeuronas from "../../../../../../../../theme/PopoverNeuronas.module.css";

  import { useLocation } from 'react-router-dom';
  import { EtapaJuegoSumas1 } from '../../../../componentes/EtapaJuegoSumas';
  import { test_general } from '../../../../../../../../datos/DatosTests';
  import { useAppSelector, useAppDispatch } from '../../../../../../../../redux/store';
  import { useJuegos } from '../../../../../../../../repositorio/controlador/useJuegos';
  import { obtenerMensaje } from '../../../../../../../utils/resultadoUtil';

  interface PartidaSumasProps {
    estado: number;
    nivel: number;
    onReinicio: (diferencia: number, suma: number) => void;
  }

  const PartidaSumas: React.FC<PartidaSumasProps> = ({ estado, nivel, onReinicio }) => {

    const [etapa, setEtapa] = useState(0);
    const [cantidadDeNumeros, setCantidadDeNumeros] = useState(1);
    const cantidadDeDigitos= (1);
    const [velocidad, setVelocidad] = useState<number>(1);
    const [suma, setSuma] = useState<number>(1);
    const [respuesto, setRespuesto] = useState<number>(-1);
    const [resultado, setResultado] = useState<boolean>(false);

    const setCantidadDeNumerosWrapper = (nuevoNivel: number) => {
      const PENDIENTE = (3 / 10);
      const CORTE = 2;
      const nuevaCantidad = Math.floor(PENDIENTE * nuevoNivel + CORTE);
      console.log(`Nivel: ${nuevoNivel}, Cantidad de números: ${nuevaCantidad}`); // Log para comprobar el cambio
      setCantidadDeNumeros(nuevaCantidad);
  };

  const setVelocidadWrapper = (nuevoNivel: number) => {
      const MULTIPLICADOR = 1325;
      const BASE = 0.953; 
      const ASINTOTA = 240;
      const nuevaVelocidad = Math.floor(MULTIPLICADOR * Math.pow(BASE, nuevoNivel) + ASINTOTA);
      console.log(`Nivel: ${nuevoNivel}, Velocidad: ${nuevaVelocidad}`); // Log para comprobar el cambio
      setVelocidad(nuevaVelocidad);
  };

    const setNivelWrapper = (nuevoNivel: number) => {
      setCantidadDeNumerosWrapper(nuevoNivel);
      setVelocidadWrapper(nuevoNivel);
    };

    const onEnviar = (sumaEnviado: number, respuestoEnviado: number) => {
      let diferencia = Math.abs(sumaEnviado - respuestoEnviado);
      setResultado(!diferencia);
      setSuma(sumaEnviado);
      setRespuesto(respuestoEnviado);
      onReinicio(diferencia, sumaEnviado);
      setEtapa(2);
    };

    useEffect(() => {
      console.log(`Nivel desde Props: ${nivel}`);  // Verificar que el nivel recibido en el componente PartidaSumas sea correcto
      setNivelWrapper(nivel);
  }, [nivel]);
  
    useEffect(() => {
      switch (estado) {
        case 0:
          setEtapa(0);
          break;
        case 1:
          setEtapa(1);
          break;
        default:
          break;
      }
    }, [estado]);

    return (
      <>
        <EtapaJuegoSumas1
          cantidadDigitos={cantidadDeDigitos}
          cantidadNumeros={cantidadDeNumeros}
          etapa={etapa}
          velocidad={velocidad}
          onEnviar={onEnviar}
        />

        {etapa === 2 && (
          <div className={style.contenido_segundario}>
            <div className={estiloNeuronas.mensajes}>
              <p
                style={{ fontSize: "4svh" }}
                className={`${estiloNeuronas.mensajePersonalizado} ${resultado ? style.mensajeCorrecto : ""}`}
              >
                {resultado ? obtenerMensaje(0.65) : obtenerMensaje(0)}
              </p>
              <br />
              {!resultado && (
                <p className={style.subMensaje}>
                  LA RESPUESTA CORRECTA ERA: {suma}
                </p>
              )}
            </div>
            <div className={style.numero_principal_respuesta} style={{ margin: '0', height: '30svh' }}>
              <div
                className={style.resultado}
                style={{ color: resultado ? "var(--color-green)" : "#CB4141" }}
              >
                {respuesto}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  export default PartidaSumas;
