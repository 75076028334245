import { PayloadAction } from "@reduxjs/toolkit";
import { Actividad } from "../../../modelos/entrenamiento/Actividad";

export const actividadesReducers = {
    ChangeRedux_actividades_entrenamiento: (state, action: PayloadAction<{ actividades_entrenamiento: Actividad[][] }>) => {
        state.actividades_entrenamiento = action.payload.actividades_entrenamiento;
    },
    ChangeRedux_current_actividades: (state, action: PayloadAction<{ current_actividades: Actividad[] }>) => {
        state.current_actividades = action.payload.current_actividades;
    },
    ChangeRedux_current_indice_actividad: (state, action: PayloadAction<number>) => {
        state.current_actividad = action.payload;
    },
};
