import styleJuegos from '../../../../../../../theme/Juegos.module.css'

interface Props{
  obtenerImagen: () => any;
}

const NavegacionImagen:React.FC<Props> = (props) => {

  return (
    <div className={styleJuegos.navegationContainer}>
      {props.obtenerImagen()}
    </div>
  )
}

export default NavegacionImagen;