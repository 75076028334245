import { PayloadAction } from "@reduxjs/toolkit";
import { RegistroEntrenamiento } from "../../../modelos/entrenamiento/RegistroEntrenamiento";

export const sesionReducers = {
    ChangeRedux_current_registro_usuario: (state, action: PayloadAction<{ registro: RegistroEntrenamiento }>) => {
        const registro = action.payload.registro;
        state.current_registro_usuario = registro;

        const index = state.registros_usuario?.findIndex(val => val.idEntrenamiento === registro?.idEntrenamiento);
        if (index >= 0 && state.registros_usuario) {
            state.registros_usuario[index] = registro;
        }
    },
    ChangeRedux_current_sesion: (state, action: PayloadAction<{ sesion: number }>) => {
        state.current_sesion = action.payload.sesion;
    },
};
