import { DocumentSnapshot, Timestamp } from 'firebase/firestore';
import { useBaseDeDatos } from '../../generalUtils/repositoryUtils/useBaseDeDatos';
import { Neurona, aNeurona, toNeurona } from '../../modelos/Neurona';
import { nomNeuronas, nomNeuronasSemanales, nomUsuarios } from '../../datos/nombresBD';
import { obtenerSemanaAno } from '../../vista/utils/neuronasUtil';
import { toUsuario } from '../../modelos/usuario';
import { Calificacion, aCalificacionTest } from '../../modelos/Calificacion';

export const useNeurona = () => {

    /**
     * Funciones para acceder el base de datos
     * @type {Objecto}
     * @const
     */
    const {
        agregarDoc,  
        recogerDoc,
        recogerDocs,
        incrementarValorNeuronas,
        recogerDocsOrdenados
    } = useBaseDeDatos();

    const actualizarNeuronas = async (userId: string, neuronas: number, nombre:string) => {
        // actualiza las neuronas totales y las neuronas semanales
        if(neuronas > 0){  
            // incrementa las neuronas totales
            incrementarValorNeuronas(nomNeuronas + "/" + userId, nombre, neuronas);
            // incrementa las neuronas semanales            
            const result:string = obtenerSemanaAno();            
            incrementarValorNeuronas(nomNeuronasSemanales + "/" + result + "/usuarios/" + userId, nombre, neuronas)
        }        

    }
    const restarNeurocoins = async (userId: string, neuronas: number, nombre:string) => {
        // actualiza las neuronas totales y las neuronas semanales
        if(neuronas >= 0){  
            // incrementa las neuronas totales
            agregarDoc(nomNeuronas + "/" + userId,{nombre:nombre,total:neuronas,ultimoRegistro:Timestamp.now()});
        }        

    }


    const consultarNombreById = async (userId:string): Promise<any> => {
        var arrDoc : DocumentSnapshot;
        arrDoc = await recogerDoc(nomUsuarios + "/" + userId);
        return toUsuario(arrDoc)?.usuarioDato?.nombres;
    }   

      
    const consultarTodasNeuronas = async (): Promise<Calificacion[]> => {
        /*
        var arrDoc : DocumentSnapshot[];
        var retorno : Calificacion[] = [];
        arrDoc = await recogerDocs(nomNeuronas);
        console.log("🚀 ~ file: useNeurona.ts:53 ~ consultarTodasNeuronas ~ arrDoc:", arrDoc)
        for(let i in arrDoc){
            let neurona = (toNeurona(arrDoc[i]))         
            let calificacion = aCalificacionTest(neurona.total,null,null,null,neurona.nombre,null)
            retorno.push(calificacion)   
        }
        return retorno;
        */

        let cri1="";
        let cri1Asc = false;
        cri1="total"        
        let retorno = []
        const result:string = obtenerSemanaAno();                        
        const resultados:DocumentSnapshot[] = await recogerDocsOrdenados(nomNeuronas, cri1, cri1Asc);
        for(let doc in resultados){
            let neruona = toNeurona(resultados[doc])
            let calificacion = aCalificacionTest(neruona.total,null,null,null,neruona.nombre,null,neruona.id)
            retorno.push(calificacion)
        }
        return retorno
    }
    
    const consultarNeuronasSemana = async (): Promise<Calificacion[]> => {
        let cri1="";
        let cri1Asc = false;
        cri1="total"        
        let retorno = []
        const result:string = obtenerSemanaAno();                        
        const resultados:DocumentSnapshot[] = await recogerDocsOrdenados(nomNeuronasSemanales + "/" + result + "/usuarios", cri1, cri1Asc);
        for(let doc of resultados){
            let neruona = toNeurona(doc)
            let calificacion = aCalificacionTest(neruona.total,null,null,null,neruona.nombre,null,neruona.id)
            retorno.push(calificacion)
        }
        return retorno
    }

    const consultarNeuronas = async (userId: string): Promise<Neurona> => {
        var arrDoc : DocumentSnapshot;
        arrDoc = await recogerDoc(nomNeuronas + "/" + userId);
        if (arrDoc.exists()) {
            const doc:Neurona = toNeurona(arrDoc)
            return doc;
        }
        return aNeurona(0,"");
    }       
  
    return {        
        actualizarNeuronas,
        consultarNeuronas,
        consultarTodasNeuronas,
        consultarNombreById,
        restarNeurocoins,
        consultarNeuronasSemana,
        
    }
}

export default useNeurona;
