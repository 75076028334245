// Importacion de css
import "../../../theme/tiposJuegos.css";
import styles from "../../../theme/programas/jugador.module.css";
import estiloJuegos from "../../../theme/Juegos.module.css";

import React, { useEffect, useRef, useState } from 'react';
import { Timestamp } from 'firebase/firestore';
import { useParams } from 'react-router-dom';

import { nomProgMemNombres, nomProgMemPalabras, nomProgParejas, nomProgSuperCerebrosDice } from "../../../datos/nombresBD";
import { useDato } from "../../../modelos/auth";
import { Ranking } from "../../../modelos/Sala";
import { Aplicacion } from "../../../modelos/usuario";
import { terminadoJuego, changePuntaje, addIntegrante, cleanJuego, resincronizarJuego } from "../../../redux/juegoSimultaneo/reducerJuegoSimultaneo";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { useSala } from "../../../repositorio/controlador/useSala";
import Loading from "../../components/Loading";
import JuegoSumas from "../rutasPrivadasMy/juegos/pages/calculo/JuegoSumas";
import JuegoSuperCerebrosDice from "../rutasPrivadasMy/juegos/pages/memoria/JuegoSuperCerebrosDice";
import JuegoMemoriaNombres from "../rutasPrivadasMy/juegos/pages/memoria/MemoriaNombres";
import JuegoMemoriaPalabras from "../rutasPrivadasMy/juegos/pages/memoria/MemoriaPalabras";
import JuegoDeMemoriaParejas from "../rutasPrivadasMy/juegos/pages/memoria/MemoriaParejas";


interface RankingLIProps{
  posicion: number;
  nombre: string;
  total: number;
  jugador: boolean;
}

const RankingLI = ({posicion, nombre, total, jugador}:RankingLIProps) => {
  return (
    <div className={`${jugador? styles.rankingLIJugador: ""} ${styles.rankingLI}`}>
      <span className={styles.rankingLIPosicion}>{jugador? "#":""}{posicion}</span>
      <span className={styles.rankingLINombre}>{nombre}</span>
      <span className={styles.rankingLITotal}>{total}</span>
    </div>
  );

}

const IndicadorEspera = () => {
  return (
    <p className={styles.indicadorEspera}>
      <span>●</span>
      <span>&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;</span>
      <span>●</span>
    </p>
  )
}

const PlayerComponent = ({reboot,setReboot}:{reboot:boolean,setReboot:React.Dispatch<React.SetStateAction<boolean>>}) => {
  const idSala = useRef(useParams().idSala);

  const dispatch = useAppDispatch();
  const redux_nombre = useAppSelector((state) => state.juegoSimultaneo.nombre)
  const redux_telefono = useAppSelector((state) => state.juegoSimultaneo.telefono)
  const redux_sala = useAppSelector((state) => state.juegoSimultaneo.sala)
  const verPuntaje = useAppSelector((state) => state.juegoSimultaneo.verPuntaje)
  const finalizoJuego = useAppSelector((state) => state.juegoSimultaneo.finalizado)
  const ultimoResultadoRedux = useAppSelector((state) => state.juegoSimultaneo.ultimoResultado)
  const totalRedux = useAppSelector((state) => state.juegoSimultaneo.total)
  const loggedIn = useAppSelector(state => state.auth.loggedIn);

  const state = useDato().state as Aplicacion;

  const superCerebroImg: {src:string, style:React.CSSProperties}[] = [
    {src:"/assets/images/supercerebro/sc_SenalandoCerebro.png",style:{ margin: "0 0 0 -60%", width: "230%" }},
    {src:"/assets/images/supercerebro/sc_nice2.png",style:{ width: "90%", margin: "5% 0 0 6%" }},
    {src:"/assets/images/supercerebro/sc_emocion.png",style:{width: "97%", margin: "5% 0 0 4.4%"}}
  ];

  const [loading, setLoading] = useState(false);
  const [imagenEspera, setImagenEspera] = useState(0);

  const [data, setData] = useState(null)
  const [listening, setListening] = useState<any>(null);

  const [integrantesListening, setIntegrantesListening] = useState(null);

  const [numIntegrantes, setNumIntegrantes] = useState<number>(0);

  const [nombre, setNombre] = useState('');
  const [telefono, setTelefono] = useState('');
  
  const [sala, setSala] = useState(idSala.current ?? "");
  
  const [etapa, setEtapa] = useState(1);
  
  const [indexJuego, setIndexJuego] = useState(0);

  const [rank, setRank] = useState<Ranking[]>([]);
  const [displayRank, setDisplayRank] = useState<{datos:Ranking, posicion: number}[]>([]);
  const [rankingListening, setRankingListening] = useState<any>(null);
  const [posicion, setPosicion] = useState<number>(0);

  const {
    nuevoJugador,
    subscribirDoc,
    SubirResultados,
    desubscribir,
    SubscribirRankings,
    TraerRankings,
    TraerResultado
  } = useSala();

  const EncontrarPosición = (ranking: Ranking[]) => {
    if(!ranking) return -1;
    for (let i = 0; i < rank.length; i++){
      if(ranking[i].telefono === telefono){
        return i;
      }
    }
    return -1;
  }

  const NullNumberDisplay = (n: number) => {
    if(n === null || n === undefined) return null;
    return Math.round(n);
  }

  const HandleAgregarJugador = async(nombre:string,telefono:string,sala:string) => {
    if(nombre === '' || telefono === '' || sala === ''){
      alert('complete todos los campos'); 
      return;
    }
    
    dispatch(terminadoJuego({ultimoResultado:0,tiempo:0,finalizado:false}))
    dispatch(changePuntaje({verPuntaje:false}))

    setLoading(true)
    if(await nuevoJugador(sala,telefono)){
      let integrante = await TraerResultado(telefono,sala)
      console.log("🚀 ~ HandleAgregarJugador ~ integrante:", integrante)
      if(integrante !== null){
        dispatch(terminadoJuego({ultimoResultado:integrante?.total, tiempo:0, finalizado:false}))
        setNombre(integrante.nombre)
        dispatch(addIntegrante({nombre:integrante.nombre,telefono,sala}))
      }else{
        dispatch(addIntegrante({nombre,telefono,sala}))
      }
      subscribirDoc('juegos_linea/'+sala,setData,setListening)
      SubscribirRankings(`juegos_linea/${sala}Resultados/Ranking`, setRank, setRankingListening);
      setEtapa(2)
      setLoading(false)
    }else{
      alert('No existe la sala')
      setLoading(false)
    }
  }

  const HandlerSubirResultados = async () => {
    SubirResultados(totalRedux,ultimoResultadoRedux,Timestamp.now(),nombre,telefono,sala,indexJuego)
  }

  const LlamarJuego = (juego:string[]):JSX.Element => {
    if(verPuntaje === true){
      console.log('Termino juego:', juego)
      setEtapa(4)
      setLoading(false)
      return <br></br>
    }else{
      console.log('Inicio juego:', juego)
      switch (juego[indexJuego - 1]){
        case nomProgMemNombres:
          return <JuegoMemoriaNombres/>
          
        case nomProgMemPalabras:
          return <JuegoMemoriaPalabras/>
          
        case nomProgParejas:
          return <JuegoDeMemoriaParejas/>
        
        case nomProgSuperCerebrosDice:
          return <JuegoSuperCerebrosDice/>

        default:
          return <JuegoSumas/>
      }
    }
  }

  const HandlerSiguiente = () => {
    dispatch(terminadoJuego({ultimoResultado:0,tiempo:0,finalizado:false}))
    dispatch(changePuntaje({verPuntaje:false}))
    setEtapa(3)
  }

  useEffect(() => {
    if(etapa === 1){

      desubscribir(listening,setListening,setData);
      desubscribir(rankingListening,setRankingListening,setRank);

      setNombre('')
      setTelefono('')
      setIndexJuego(0)

      if(loggedIn){
        setNombre(state.nombres ?? '');
        setTelefono(state.telefono ?? '');
      }

      dispatch(cleanJuego())

      if(reboot){   
        if(redux_nombre && redux_telefono && redux_sala){
          HandleAgregarJugador(redux_nombre,redux_telefono,redux_sala)
          setNombre(redux_nombre)
          setTelefono(redux_telefono)
          setSala(redux_sala)
        }
      }
      
    }
    if(etapa === 1 || etapa === 3){
      let nuevaImagen = imagenEspera;
      
      while(nuevaImagen === imagenEspera){
        nuevaImagen = Math.floor(Math.random() * superCerebroImg.length);
      }

      setImagenEspera(nuevaImagen);
    }
    if(etapa === 5){
      desubscribir(rankingListening,setRankingListening,(a) => {});
      TraerRankings(`juegos_linea/${sala}Resultados/Ranking`, telefono).then((res) => {
        const posNueva = EncontrarPosición(res);

        setNumIntegrantes(res.length)
        setPosicion(posNueva);
      })
    }
  }, [etapa]) 

  useEffect(() => {
    
    const posNueva = EncontrarPosición(rank);
    setPosicion(posNueva);
    
    if(!rank) return;

    let ultimo = 0;
    if(posNueva >= 3) ultimo = (posNueva + 3 < rank?.length)? posNueva + 3: rank?.length - 1;
    else ultimo = (6 < rank?.length)? 6: rank?.length - 1;
    const primero = (ultimo - 6 >= 0)? ultimo - 6: 0;
    const nuevoDisplayRank: {datos:Ranking, posicion: number}[] = []

    rank?.forEach((v, i) => {
      if(i >= primero && i <= ultimo) nuevoDisplayRank.push({datos: v, posicion: i})
    })

    console.log("🚀 ~ useEffect ~ rank[posNueva]?.indiceJuego === indexJuego:", rank[posNueva]?.indiceJuego === indexJuego)
    console.log("🚀 ~ useEffect ~ indexJuego:", indexJuego)
    console.log("🚀 ~ useEffect ~ rank[posNueva]:", rank[posNueva])
    console.log("🚀 ~ useEffect ~ reboot:", reboot)
    if(reboot){
      if(indexJuego && rank[posNueva]?.indiceJuego === indexJuego){
        dispatch(resincronizarJuego({ultimoResultado:rank[posNueva]?.ultimoResultado,total:rank[posNueva]?.total,tiempo:0,finalizado:true}))
        dispatch(changePuntaje({verPuntaje:true}))
      }else{
        dispatch(resincronizarJuego({ultimoResultado:rank[posNueva]?.ultimoResultado,total:rank[posNueva]?.total,tiempo:0,finalizado:false}))
      }
      setReboot(false)
    }

    setDisplayRank(nuevoDisplayRank);
  }, [rank])
  
  
  useEffect(() => {
    console.log("🚀 ~ file: JuegoSimultaneo.tsx:43 ~ JuegoSimultaneo ~ data:", data)
    if(!data && etapa > 1){
      setEtapa(5)
      return;
    }
    if(data?.iniciar){
      setIndexJuego(data.iniciar)
      setEtapa(3)
      setLoading(false)
    }
    if(etapa === 4 ){
      console.log('cambio a juego')
      HandlerSiguiente()
    }
    console.log("🚀 ~ useEffect ~ setIndexJuego:", indexJuego)
    
  }, [data])

  useEffect(() => {
    if(finalizoJuego === true){
      console.log('Se suben los resultado')
      HandlerSubirResultados()
    }
  }, [finalizoJuego])

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ reboot:", reboot)
    if(reboot){
      console.log("🚀 ~ useEffect ~ nombre:", redux_nombre)
      console.log("🚀 ~ useEffect ~ sala:", redux_telefono)
      console.log("🚀 ~ useEffect ~ telefono:", redux_sala)
      setEtapa(1)
    }
  }, [reboot])
  

  return (
    <div className={styles.contenedor}>
      <Loading isOpen={loading}></Loading>
      { etapa === 1 &&
        <>
          <p className={styles.titulo}>REGISTRO</p>
          <p className={styles.subtitulo}>¿Listo para demostrar que eres el mejor en nuestro super torneo?</p>
          <form className={styles.datosForm}>

            <div>
              <label htmlFor='name'>&nbsp; Nombre completo</label>
              <div>  
                <input value={nombre} id='name' onChange={(event) => {setNombre(event.target.value)}} placeholder='NOMBRE'></input>
              </div>
            </div>

            <div>
              <label htmlFor='phone'>&nbsp; Numero de Telefono</label>
              <div>
                <input value={telefono} type='number' id='phone' onChange={(event) => {setTelefono(event.target.value)}} placeholder='TELEFONO: xxxxxxxxxx'></input>
              </div>
            </div>

            <div>
              <label htmlFor='room'>&nbsp; Sala</label>
              <div>
                <input value={sala} id='room' onChange={(event) => {setSala(event.target.value)}} placeholder='CODIGO SALA'></input>
              </div>
            </div>
          </form>
          <button className={`${styles.datosBoton} ${estiloJuegos.buttonEmpezar}`} onClick={() => HandleAgregarJugador(nombre,telefono,sala)}>Entrar a Sala</button>
      </>}

      { (etapa === 2 || etapa === 4) && 
      <>
        {etapa === 4 &&
          <div className={styles.infoEspera}>
          <p className={styles.puntajeEspera}>TU PUNTAJE FUE DE: <br/> <span>{NullNumberDisplay(ultimoResultadoRedux)} PUNTOS</span></p>
          <p className={styles.totalEspera}>TIENES EN TOTAL: <br/> <span>{NullNumberDisplay(totalRedux)} PUNTOS</span></p>
          </div>
        }
        <p className={styles.mensajeEspera}>{etapa === 2?<>ESPERANDO QUE EL <span>HOST</span> INICIE EL TORNEO...</>:"ESPERANDO EL SIGUIENTE JUEGO..."}</p>
        <div className={styles.imagenEspera} style={etapa === 4? {margin: "0 auto 3svh"}: {}}>
          <img src={superCerebroImg[imagenEspera].src} style={superCerebroImg[imagenEspera].style} alt='supercerebro'></img>
        </div>
        <IndicadorEspera/>
        <div style={{margin: (etapa === 2? "6svh": "1svh")}}></div>
      </>
      }

      { etapa === 3 && indexJuego >= 1 &&
      <div style={{flex:'1', height: "95%"}}>
        {data?.iniciar && 
          LlamarJuego(data?.juego)
        }
      </div>
      }
      { etapa === 5 && 
        <>
        <p className={styles.tituloEsperaRanking} style={{opacity: 0.4}}>TU RANKING</p>
        {(rank.length < 1)?
          <IndicadorEspera/>
          :
          <div className={styles.finalRanking}>
            FUISTE
            <div className={styles.finalPosicion}>
              <div>
                <div>#{posicion + 1}</div>
              </div>
            </div>
            OBTUVISTE
            <div className={styles.finalIntegrantes}>
              {Math.round(totalRedux)}
            </div>
            PUNTO{Math.round(totalRedux) != 1 && "S"}
          </div>
        }
        <button
          className={`${styles.datosBoton} ${estiloJuegos.buttonEmpezar}`}
          onClick={() => {setSala("") ; setEtapa(1);}}
        >FINALIZAR</button>
        </>
      }
    </div>
  )
}

export default PlayerComponent
