import { createSlice } from '@reduxjs/toolkit';
import { cuestionario } from './models/cuestionario';
import { cuestionarioReduxReducers } from './actions/reducerCuestionario';

const initialState: cuestionario = {
    preguntas: null
}

export const authSliceCuestionario = createSlice({
    name: 'cuestionario',
    initialState,
    reducers:cuestionarioReduxReducers
});

export const { add, clear} = authSliceCuestionario.actions;