import { ReactNode } from 'react'
import style from "../../../../../../../theme/Juegos.module.css"
interface Props{
    
    contenidoIdIndice: number;
    cantidadDeValores: number;
    getObjetos?: () => ReactNode;
}

const CirculosDeSeleccion:React.FC<Props> = (props) => {
    
    return (
        <ul className={style.circulos} style={(props.getObjetos) ? {marginBottom:'2%',marginTop:'5%'} : {}}>
            {props.cantidadDeValores>=1 && <li className={`${style.circulo} ${0 === props.contenidoIdIndice ? style.circuloActivado : style.circuloDesactivado}`}
            ></li>}
            {props.cantidadDeValores>=2 && <li className={`${style.circulo} ${2 === props.cantidadDeValores ? (props.cantidadDeValores-1) === props.contenidoIdIndice ? style.circuloActivado :style.circuloDesactivado : 0 !== props.contenidoIdIndice && (props.cantidadDeValores-1) !== props.contenidoIdIndice ? style.circuloActivado : style.circuloDesactivado}`}
            ></li>}
            {props.cantidadDeValores>2 && <li className={`${style.circulo} ${(props.cantidadDeValores-1) === props.contenidoIdIndice ? style.circuloActivado : style.circuloDesactivado}`}
            ></li>}
        </ul>
     )
}

export default CirculosDeSeleccion;