
import { useEffect, useState } from "react";

import { useNavigate } from 'react-router-dom'

import { useToast } from "../../../../../generalUtils/useToast";
import { aEntidad } from "../../../../../modelos/entidad/Entidad";
import { load } from "../../../../../redux/auth/reducerAuth";
import { useEntidades } from "../../../../../repositorio/controlador/useEntidades";

// css
import style from '../../../../../theme/admin/entidades.module.css';

const EntidadesPage: React.FC = () => {
    const [entidad, setEntidad] = useState("");
    const [entidades,setEntidades] = useState([]);
    //hook para abrir el modal de crear
    const [openModal,setOpenModal] = useState(false);
    //hook para crear la entidad
    const [nombreEntidad, setNombreEntidad] = useState("");
    const [descripcion, setDescipcion] = useState("");

    const navigate = useNavigate();

    const { 
        crearEntidad,
      } = useEntidades();

    const { presentDatosIncompletos } = useToast();

    const consulta = async (entidadConsultar: string) => {
        setEntidades([])       
    }

    //función que crea entidad 
    const creacion = async() => {
        if(nombreEntidad!=="" && descripcion!=""){
            const nuevaEntidad = aEntidad(nombreEntidad, descripcion)
            await crearEntidad(nuevaEntidad)
            setNombreEntidad("")
            setDescipcion("")
            setOpenModal(false)
        }else{
            presentDatosIncompletos()
        }
    }


    useEffect(() => {
        load();
        consulta("")
        load();
    }, [openModal]);

    
    const handleRedirect = ( ruta:string) => {
        navigate(ruta)
    }


    return (
        <div id="page">
            <div id="toolbar_default">
                <h1 className="ion-text-center title">Entidades</h1>
            </div>
            <div id='content_default' style={{color:'#868189'}}>
                <label >Entidad: <input type="text" id="entidad"
                    placeholder="Ingrese la entidad a consultar"
                    value={entidad}
                    onChange={(event) => { setEntidad(event.target.value) }}/>
                </label>
                <br/>

                <button className={style.button_entidades} onClick={() => consulta(entidad)}>
                    Consultar
                </button>
                <br/>

                {entidades.map((element, index) => (
                    <div className={style.card_entidad}     key={element.id} onClick={() => handleRedirect(`/my/home/entidades/${element.nombre}/${element.id}`)}>
                        {element.nombre}
                    </div>
                    )
                )}
                <button className={style.button_entidades} onClick={() => {setOpenModal(true)}}>Agregar Entidad</button>
                
                
                {openModal && <div className={`${style.modal_overlay} ${openModal ? 'scroll-up': ''}`}>
                    
                    <div  id="toolbar_default">
                        <div onClick={() => setOpenModal(false)}>
                            <span>&times;</span>
                        </div>
                        <h1 className="title_default">Crear Entidad</h1>
                    </div>
                    <div id='content_default' className={style.content_modal}>
                     
                        <div>
                            <p >Nombre</p>
                            <input 
                            value={nombreEntidad} 
                            onChange={(event) => {setNombreEntidad(event.target.value)}}/>
                        </div>
                
                        <div>
                            
                            <label>Descripción <input type="text" id="Descripcion"
                                placeholder="Ingrese una descripción de la entida a crear"
                                value={descripcion}
                                onChange={(event) => { setDescipcion(event.target.value) }}/></label>
                        </div>
                        <button className={style.button_entidades} onClick={creacion}>Crear</button>
                     
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default EntidadesPage;