// /features/juegoFechas/components/BotonDiasSemana.tsx
import React from 'react';
import { DiasSemana, diasArray } from '../constants/diasSemana';
import BotonBordeDegradado from '../../../../../../../components/BotonBordeDegradado';

interface Props {
  onClick: () => void;
  dia: DiasSemana;
}

const BotonDiasSemana: React.FC<Props> = ({ onClick, dia }) => {
  return (
    <BotonBordeDegradado style={{width:'7em',fontSize:'1.2em',textTransform:'uppercase'}} paddingInterno={'5%'} onClick={onClick}>
      {diasArray[dia]}
    </BotonBordeDegradado>
  );
};

export default BotonDiasSemana;
