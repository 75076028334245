import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { authSliceAuth } from './auth/reducerAuth';
import { authSliceActivity } from './actividad/reducerActividad';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { authSliceCuestionario } from './cuestionario/reducerCuestionario';
import { sliceEntrenamiento } from './entrenamiento/reducerEntrenamiento';
import { sliceJuegoSimultaneo } from './juegoSimultaneo/reducerJuegoSimultaneo';
import { sliceShoppingCart } from './shoppingCart/reducerShoppingCart';

export const store = configureStore({
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
  reducer: {
    auth: authSliceAuth.reducer,
    activity: authSliceActivity.reducer,
    cuestionario: authSliceCuestionario.reducer,
    entrenamiento: sliceEntrenamiento.reducer,
    juegoSimultaneo: sliceJuegoSimultaneo.reducer,
    shoppingCart: sliceShoppingCart.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
