import { Route, Routes } from "react-router-dom";
import HomePage from "./HomePage";
import TabsEntidades from "./entidades/Routes";
import useRutasPerfil from "./perfil/TabsPerfil";
import PagoPage from "./PagoPage";

export const TabsHome = () =>{
    
  const RutasPerfil = useRutasPerfil();

    return(
        <Routes>
            <Route path='/' element={<HomePage/>}/>
            {/* datosUsuario */}
            {RutasPerfil}
            {/* entidades */}
            <Route path='entidades' element={<TabsEntidades/>}></Route>
            {/* paginas pagos de cursos */}
            <Route path="PagoCurso" element={<PagoPage/>}/>
        </Routes>
    );
}