import { Dispatch, useState } from "react";
import { Sala, integrante, toSala, Ranking } from "../../modelos/Sala";
import { useBaseDeDatos } from "../../generalUtils/repositoryUtils/useBaseDeDatos";
import { firestore } from "../../firebase";
import { DocumentData, DocumentSnapshot, QuerySnapshot, Timestamp, collection, doc, getDocs, limit, onSnapshot, orderBy, query, setDoc, where } from "firebase/firestore";
import { nomJuegoIntegrantes, nomJuegoSimultaneo, nomProgMemNombres, nomProgMemNumeros, nomProgMemPalabras, nomProgParejas, nomProgSumas1, nomProgSuperCerebrosDice } from "../../datos/nombresBD";
import { aVersusUsuario, mensaje, toVersus } from "../../modelos/Versus";
import { aIntegrante, toIntegrante } from "../../modelos/entidad/IntegranteEntidad";

export const useSala = () => {

    /**
     * Funciones para acceder a la base de datos
     * @type {Objecto}
     * @const
     */
    const {
        agregarDoc,
        borrarDoc,
        reemplazarDoc,
        recogerDoc,
        agregarCollection,
        recogerDocsFiltrados,
        borrarCollection,
        recogerDocs,
        UnirCampoArregloDoc,
        EliminarCampoArregloDoc,
        IncrementarCampo,
        ActualizarUnCampo,
    } = useBaseDeDatos();

    function generarID(length: number) {
        var caracteres = '0123456789';
        var id = '';
        for (var i = 0; i < length; i++) {
          id += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
        }
        return id;
      }

    const SubirResultados = async (total:number,ultimoResultado:number, tiempo: Timestamp, nombre:string, telefono:string, idSala:string,indiceJuego:number): Promise<any>=> {
        const dato = {
            nombre:nombre,
            telefono:telefono,
            indiceJuego:indiceJuego,
            total:total,
            tiempo:tiempo,
            ultimoResultado,
        } as Ranking
        console.log("🚀 ~ SubirResultados ~ dato:", dato)
        console.log("🚀 ~ SubirResultados ~ nomJuegoSimultaneo + '/'+`${idSala}Resultados` + '/'+`Ranking`+'/'+`${telefono}`:", nomJuegoSimultaneo + '/'+`${idSala}Resultados` + '/'+`Ranking`+'/'+`${telefono}`)
        const response = await agregarDoc( nomJuegoSimultaneo + '/'+`${idSala}Resultados` + '/'+`Ranking`+'/'+`${telefono}`,dato)
        return response
    }    

    const crearSala = async (nomJuego: string[]): Promise<any> => {
        try {
            const idPersonalizado = generarID(4);
            //const idPersonalizado = 'AAAA';
            console.log("🚀 ~ CrearSala ~ idPersonalizado:", idPersonalizado);

            // Verificar si el documento ya existe en la colección
            const doc = await recogerDoc( nomJuegoSimultaneo + '/' + idPersonalizado);

            if (doc.exists()) {
                // Si el documento ya existe, intenta crear otra sala con un nuevo ID
                return crearSala(nomJuego);
            } else {
                // Si el documento no existe, crea una nueva sala
                const sala = { iniciar: 0, juego: nomJuego };
                await agregarDoc( nomJuegoSimultaneo + '/' + idPersonalizado, sala);
                agregarDoc( nomJuegoSimultaneo + '/' + idPersonalizado + `/`+nomJuegoIntegrantes + `/`+`${idPersonalizado}Integrantes`, 
                {abierto: true, integrantes:[]})
                const respuesta = idPersonalizado
                console.log("🚀 ~ crearSala ~ respuesta:", respuesta)
                console.log("🚀 ~ CrearSala ~ sala:", sala);
                return respuesta;
            }
        } catch (error) {
            console.error("Error al crear la sala:", error);
            throw error; // Propaga el error hacia arriba
        }
    }

    const RecogerSala = async (sala: string) => {
        const doc = await recogerDoc(`${nomJuegoSimultaneo}/${sala}`)
        return toSala(doc);
    }

    const nuevoJugador = async(idSala:string,telefono:string):Promise<boolean> => {
        let doc: DocumentSnapshot<DocumentData>;
        try{
            doc = await recogerDoc( nomJuegoSimultaneo + '/' + idSala + `/juegoIntegrantes/`+`${idSala}Integrantes`)
        }catch(e){
            console.error(e);
            return false;
        }
        console.log("🚀 ~ nuevoJugador ~ doc:", doc)
        let sala = null;
        console.log("🚀 ~ nuevoJugador ~ doc.exists():", doc.exists())
        if(doc.exists()){
            sala = toSala(doc)
            if(sala.abierto === true){
                console.log('¿sala abierta')
                try{
                    await UnirCampoArregloDoc(nomJuegoSimultaneo + '/' + idSala + `/juegoIntegrantes/`+`${idSala}Integrantes`, "integrantes", telefono);
                }catch(e){
                    console.error(e);
                    return false;
                }
                return true;
            }else{
                console.log('¿sala cerrada')
                return sala.integrantes.includes(telefono);
            }
        }
        return false
    }

    const iniciarJuego = async(idSala : string, sala: Sala) => {
        console.log("🚀 ~ iniciarJuego ~ sala:", sala)
        sala.abierto = false
        await agregarDoc( nomJuegoSimultaneo + '/' + idSala + `/juegoIntegrantes/`+`${idSala}Integrantes`, sala)
        //-----------------------------------------------
        //SOLUCION ABIERTA A CAMBIO
        //-----------------------------------------------
    }

    const iniciarSiguienteJuego = async (idSala : string) => {
        var doc = await recogerDoc( nomJuegoSimultaneo + '/' + idSala)
        console.log("🚀 ~ iniciarJuego ~ doc:", doc)
        var sala = toSala(doc)  
        console.log("🚀 ~ iniciarJuego ~ sala:", sala)
        sala.iniciar = sala.iniciar + 1;
        agregarDoc( nomJuegoSimultaneo + '/' + idSala, sala)
        //-----------------------------------------------
        //SOLUCION ABIERTA A CAMBIO
        return sala;
        //-----------------------------------------------
    }

    const cerrarSalaColeccion = async(idSala:string) =>{
        console.log("🚀 ~ cerrarSala ~ idSala:", idSala)
        let resp = await borrarDoc( nomJuegoSimultaneo + '/' + idSala)
        console.log("🚀 ~ cerrarSala ~ resp:", resp)
    }

    const cerrarSalaDoc = async(idSala:string) =>{
        console.log("🚀 ~ cerrarSala ~ idSala:", idSala)
        let resp = await borrarDoc( nomJuegoSimultaneo + '/' + idSala)
        console.log("🚀 ~ cerrarSala ~ resp:", resp)
    }

    const Suscribir = (q:any,setData:Dispatch<any>,setListening:Dispatch<any>) => {

        const unsubscribe = onSnapshot(q,(snapshot) => {
            const newData = [];
            snapshot.forEach((doc) => {
                newData.push(doc.data());
            })
            console.log("🚀 ~ unsubscribe ~ data:", newData)
            setData(newData)
        });
        
          // Almacenar la función unsubscribe en el estado
          setListening(() => unsubscribe);

    }

    const subscribirColeccion = (ruta:string,setData:Dispatch<any>,setListening:Dispatch<any>,host?:boolean) => {
        console.log('Empieza a  realizar la funcion de subscripcion.',ruta)

        const documentRef = collection(firestore,ruta);
        let q = null
        if(host === true){
            q = query(documentRef, orderBy("total","desc"), orderBy("tiempo","asc"), limit(10));
        }else{
            q = query(documentRef, orderBy("total","desc"), orderBy("tiempo","asc") );
        }
        Suscribir(q,setData,setListening)
    }

    const subscribirDoc = (ruta:string,setData:Dispatch<any>,setListening:Dispatch<any>) => {
        console.log('Empieza a  realizar la funcion de subscripcion.',ruta)
        const documentRef = doc(firestore,ruta);
        const unsubscribe = onSnapshot(documentRef,(doc) => {
            setData(
                toSala(doc)
            )
        });
    
          // Almacenar la función unsubscribe en el estado
          setListening(() => unsubscribe);
    }

    const desubscribir = (listening:any, setListening:Dispatch<any>,setData:Dispatch<any>) => {
        console.log('Empieza a realizar la funcion de desubscripcion.')
        if (listening) {
            console.log('zzzzz la funcion de desubscripcion.',listening)
            // Llamar a la función unsubscribe para dejar de escuchar cambios
            listening();
            setListening(null);
            setData(null);
        }
    }

    const TraerRankings = async (ruta:string,telefono:string): Promise<Ranking[]> => {
        const documentRef = collection(firestore,ruta);
        const q = query(documentRef, orderBy("total","desc"));
        console.log("🚀 ~ TraerRankings ~ q:", q)

        const querySnapshot  = await getDocs(q)
        console.log("🚀 ~ TraerRankings ~ querySnapshot:", querySnapshot)

        return querySnapshot.docs.map(doc => doc.data() as Ranking);
    }

    const SubscribirRankings = (ruta:string,setData:Dispatch<Ranking[]>,setListening:Dispatch<any>) => {
        console.log('Empieza a  realizar la funcion de subscripcion.')

        const documentRef = collection(firestore,ruta);
        const q = query(documentRef, orderBy("total","desc"), orderBy("tiempo","asc"));

        const unsubscribe = onSnapshot(q,(snapshot) => {
            console.log("🚀 ~ unsubscribe ~ data:", snapshot.docs)
            setData(snapshot.docs.map(doc=>doc.data() as Ranking))
        });
    
          // Almacenar la función unsubscribe en el estado
          setListening(() => unsubscribe);
    }

    const GuardarTorneo = async (idSala:string,nombre:string) => {
        console.log("🚀 ~ GuardarTorneo ~ {nombre:nombre} as Object:", {nombre:nombre} as Object)
        const ruta = nomJuegoSimultaneo + '/' + `${idSala}Resultados`
        console.log("🚀 ~ GuardarTorneo ~ ruta:", ruta)
        const resp = await agregarDoc(ruta,{nombre:nombre} as Object)
        console.log("🚀 ~ GuardarTorneo ~ resp:", resp)
    }

    const BorrarResultados = async (idSala:string) => {
        await borrarDoc(`${nomJuegoSimultaneo}/${idSala}Resultados`)
    }

    const TraerResultado = async (telefono:string,idSala:string) => {
        let doc = await recogerDoc(nomJuegoSimultaneo + "/" + `${idSala}Resultado` + "/" + "Ranking" + "/" + telefono)
        if(doc.exists()){
            return doc.data() as unknown as Ranking
        }else{
            return null
        }
    }
    // Suscripcion para un versus 1v1

    const BuscarVersus = (ruta:string,setVersusAbiertos:Dispatch<any>,setListening:Dispatch<any>) => {
        
        subscribirDoc(ruta, (input) => {
            setVersusAbiertos(input?.integrantes.length >= 1 ? input?.integrantes : []);
            
        }, setListening);
    }

    const CrearVersus = async (idPersonalizado:string,uid:string) => {        
        try {
            // Verificar si el documento ya existe en la colección
            const doc = await recogerDoc( nomJuegoSimultaneo + '/' + "versus" + "/" + 'salas' + "/" + idPersonalizado);
            console.log("🚀 ~ CrearVersus ~ idPersonalizado:", idPersonalizado)
            console.log("🚀 ~ CrearVersus ~ CrearVersus:",doc)
            console.log("🚀 ~ CrearVersus ~ doc.exists():", doc.exists())
            
            if (doc.exists()) {
                const versus = toVersus(doc)
                console.log("🚀 ~ CrearVersus ~ versus update:", versus)
                if(versus.integrantes.length > 1){
                    console.log("🚀 ~ CrearVersus ~ versus:", versus)
                    throw new Error("Error integrantes completos.");
                }else{
                    versus.integrantes.push({uid} as integrante)
                    await UnirCampoArregloDoc(nomJuegoSimultaneo + '/' + "versus" + "/" + 'salas' + "/" + idPersonalizado,'integrantes',{uid} as integrante)
                    await BorrarJugadorVersus(uid)
                }                
            } else {
                // Si el documento no existe, crea una nueva sala
                let int = [{uid} as integrante]
                let versus = aVersusUsuario(0,int,null,[])
                console.log("🚀 ~ CrearVersus ~ versus crear:", versus)
                await agregarDoc(nomJuegoSimultaneo + '/' + "versus" + "/" + 'salas' + "/" + idPersonalizado, versus)
                await BorrarJugadorVersus(uid)
                const respuesta = idPersonalizado
                return respuesta;
            }
        } catch (error) {
            throw new Error("Error al crear la sala:", error);
        }
    }

    const AgregarJugadorVersus = async (nombre:string,uid:string) => {
        let doc = await recogerDoc(nomJuegoSimultaneo + "/versus");
        let versus = toVersus(doc);

        if (!versus) {
            // Si el documento aún no existe, crea un nuevo objeto versus
            versus = {
                integrantes: [{ nombre, uid, pareja: [], aceptado: null }]
            };
            console.log("🚀 ~ AgregarJugadorVersus ~ versus:", versus)
            await agregarDoc(nomJuegoSimultaneo + "/versus", versus);
            console.log("Se creó el documento versus con el integrante nuevo.");
            return true
        } else {
            // Si el documento ya existe
            if (!versus.integrantes) {
                // Si el campo integrantes no existe, inicialízalo como un array vacío
                versus.integrantes = [];
            }
            
            // Verificar si el nuevo integrante ya está presente en la lista de integrantes
            const integranteRepetido = versus.integrantes.find(integrante => integrante.uid === uid);
            
            if (!integranteRepetido) {
                // Si el integrante no está repetido, se añade a la lista
                versus.integrantes.push({ nombre, uid, pareja: null, aceptado: null });
                console.log("🚀 ~ AgregarJugadorVersus ~ versus:", versus)
                await agregarDoc(nomJuegoSimultaneo + "/versus", versus);
                console.log("El integrante ahora está presente en la lista de integrantes.");
                return true
            } else {
                // Si el integrante ya está presente, puedes manejar esta situación según tus necesidades
                console.log("El integrante ya está presente en la lista de integrantes.");
                return false
            }
        }
    }

    const EnviarInvitacionVersus = async (nombre: string, uid: string) => {
        try {
            // Recoger el documento del versus de la base de datos
            let docVersus = await recogerDoc(`${nomJuegoSimultaneo}/versus`);
            let versus = toVersus(docVersus);
    
            // Verificar si el documento existe y tiene la propiedad integrantes
            if (versus && versus.integrantes) {
                // Encontrar el usuario con el uid proporcionado
                const usuario = versus.integrantes.find((usuario: integrante) => usuario.uid === uid);
                
                // Verificar si se encontró el usuario
                if (usuario) {
                    // Verificar si el nombre ya existe en el array usuario.pareja
                    if (!usuario.pareja?.includes(nombre)) {
                        // Agregar el atributo nombre de myUsuario al atributo pareja del usuario encontrado
                        if (!usuario.pareja) {
                            usuario.pareja = [];
                        }
                        usuario.pareja.push(nombre);
    
                        // Actualizar el documento en la base de datos
                        console.log("🚀 ~ EnviarInvitacionVersus ~ versus:", versus)
                        await agregarDoc(`${nomJuegoSimultaneo}/versus`, versus);
    
                        console.log(`Se agregó ${nombre} a la pareja del usuario con uid ${uid}.`);
                    } else {
                        console.log(`${nombre} ya existe en la pareja del usuario con uid ${uid}.`);
                    }
                } else {
                    console.log(`No se encontró ningún usuario con uid ${uid}.`);
                }
            } else {
                console.log("No se encontró el documento versus o no tiene la propiedad integrantes.");
            }
        } catch (error) {
            console.error("Error al enviar la invitación:", error);
        }
    };

    const GenerarID = async (canti:number) => {
        let doc = null;
        let idPersonalizado = null;
        do{
            idPersonalizado = generarID(canti);
            doc = await recogerDoc( nomJuegoSimultaneo + '/' + "versus" + "/" + 'salas' + "/" + idPersonalizado);
        } while(doc.exists())
        console.log("🚀 ~ constEnviarAceptación= ~ idPersonalizado:", idPersonalizado)
        return idPersonalizado
    }

    const CambiarAceptado = async(nombre:string,idPersonalizado:string,currentUid:integrante) => {
        // Recoger el documento del versus de la base de datos
        let docVersus = await recogerDoc(`${nomJuegoSimultaneo}/versus`);
        let versus = toVersus(docVersus);
        
        // Verificar si el documento existe y tiene la propiedad integrantes
        if (versus && versus.integrantes) {
            const usuario = versus.integrantes.find((usuario: integrante) => usuario.nombre === nombre);
            if(usuario.aceptado === null){
                usuario.aceptado = idPersonalizado;
                await agregarDoc(`${nomJuegoSimultaneo}/versus`, versus);
                return true
            }else{
                await EliminarInvitacion(nombre,currentUid)
                console.log(`No se encontró ningún usuario con nombre ${nombre} o ya tiene algo en el atributo aceptado.`);
                return false
            }
            
        } else {
            console.log("No se encontró el documento versus o no tiene la propiedad integrantes.");
            return false
        }
    }

    const ModificarActualUsuario = async(currentUid: integrante,idPersonalizado:string) => {
        // Recoger el documento del versus de la base de datos
        let docVersus = await recogerDoc(`${nomJuegoSimultaneo}/versus`);
        let versus = toVersus(docVersus);
        const actualUser = versus.integrantes.find((otroUsuario: integrante) => otroUsuario.uid === currentUid.uid);
        if(actualUser.aceptado === null){
            actualUser.aceptado = idPersonalizado;
            await agregarDoc(`${nomJuegoSimultaneo}/versus`, versus);
            return true
        }else{
            console.log("El otroUsuario ya tenia aceptado.");
            return false

        }

    }

    const EnviarAceptación = async (nombre: string, currentUid: integrante) => {
        try {
            let idPersonalizado = await GenerarID(6)
            if(CambiarAceptado(nombre,idPersonalizado,currentUid)){
                if(ModificarActualUsuario(currentUid,idPersonalizado)){
                    let docVersus = await recogerDoc(`${nomJuegoSimultaneo}/versus`);
                    let versus = toVersus(docVersus);
                    const otroUser = versus.integrantes.find((otroUsuario: integrante) => otroUsuario.nombre === nombre);
                    const actualUser = versus.integrantes.find((otroUsuario: integrante) => otroUsuario.uid === currentUid.uid);
                    if(otroUser.aceptado === actualUser.aceptado){
                        console.log("🚀 ~ constEnviarAceptación= ~ otroUser.aceptado === actualUser.aceptado:", otroUser.aceptado === actualUser.aceptado)
                        await CrearVersus(idPersonalizado,currentUid.uid)
                    }
                }
            }
            return idPersonalizado       
        } catch (error) {
            console.error("Error al enviar la aceptación:", error);
            return false
        }
    };

    const EliminarInvitacion = async (nombre: string, currentUid: integrante) => {
        let newUser = currentUid.pareja.filter(par => par !== nombre)
        console.log("🚀 ~ EliminarInvitacion ~ newUser:", newUser)
        console.log("🚀 ~ EliminarInvitacion ~ currentUid:", currentUid)
        await EliminarCampoArregloDoc(nomJuegoSimultaneo + "/versus",'integrantes',currentUid)
        await UnirCampoArregloDoc(nomJuegoSimultaneo + "/versus",'integrantes',newUser)
    }

    const BorrarJugadorVersus = async (uid: string) => {
        // Recoger el documento actual de la base de datos
        let doc = await recogerDoc(nomJuegoSimultaneo + "/versus");
        let versus = toVersus(doc);
    
        // Filtrar los integrantes para eliminar el integrante con el uid proporcionado
        versus.integrantes = versus?.integrantes?.filter(integrante => integrante.uid !== uid);
    
        // Actualizar el documento en la base de datos
        await reemplazarDoc(nomJuegoSimultaneo + "/versus", versus);
    };

    const EnviarMensaje = async (idSala:string,nombre:string,mensaje:string) => {
        let currentMensaje = {nombre,mensaje} as mensaje;
        await UnirCampoArregloDoc(nomJuegoSimultaneo + "/versus/salas/" + idSala,'mensaje',currentMensaje)
    }
    const EnviarReady = async (idSala: string, cant: number) => {
        let juegos = [nomProgMemNombres, nomProgMemPalabras, nomProgSumas1, nomProgSuperCerebrosDice, nomProgParejas];
        let indexAzar = Math.floor(Math.random() * juegos.length);
        
        // Incrementa el campo
        await IncrementarCampo(nomJuegoSimultaneo + "/versus/salas/" + idSala, 'iniciar', cant);
        
        // Recoge el documento
        let doc = await recogerDoc(nomJuegoSimultaneo + "/versus/salas/" + idSala);
        
        // Convierte el documento a un objeto 'versus'
        let versus = toVersus(doc);
        
        // Verifica si versus.juego es null y actualiza si es necesario
        if (versus.juego === null || versus.juego === undefined) {
            await ActualizarUnCampo(nomJuegoSimultaneo + "/versus/salas/" + idSala, 'juego', juegos[indexAzar]);
        }
    }
    
    const GuardarPuntaje = async (idSala:string,uid:string,nombre:string,puntaje:number) => {
        let registro = {uid,nombre,puntaje} as integrante
        console.log("🚀 ~ GuardarPuntaje ~ registro:", registro)
        await EliminarCampoArregloDoc(nomJuegoSimultaneo + "/versus/salas/" + idSala,'integrantes',uid)
        await UnirCampoArregloDoc(nomJuegoSimultaneo + "/versus/salas/" + idSala,'integrantes',registro)
    }

    const BorrarSala = (idSala:string) => {
        borrarDoc(nomJuegoSimultaneo + "/versus/salas/" + idSala)
    }

    return {
        crearSala,
        cerrarSalaColeccion,
        cerrarSalaDoc,
        nuevoJugador,
        subscribirColeccion,
        subscribirDoc,
        desubscribir,
        iniciarJuego,
        iniciarSiguienteJuego,
        SubirResultados,
        TraerRankings,
        TraerResultado,
        SubscribirRankings,
        RecogerSala,
        GuardarTorneo,
        BorrarResultados,
        BuscarVersus,
        CrearVersus,
        AgregarJugadorVersus,
        BorrarJugadorVersus,
        EnviarInvitacionVersus,
        EnviarAceptación,
        EnviarMensaje,
        EnviarReady,
        GuardarPuntaje,
        BorrarSala,
    }
}
