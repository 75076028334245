import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import estiloJuegos from './style/JuegoSumas.module.css';
import ProgressBar from '../../../componentes/ProgressBar';
import PartidaSumas from './components/PartidaSumas';
import { useJuegoSumas } from './hooks/useJuegoSumas';
import { useDato } from '../../../../../../../modelos/auth';
import { useAppSelector, useAppDispatch } from '../../../../../../../redux/store';
import { useJuegos } from '../../../../../../../repositorio/controlador/useJuegos';
import DosBotonesColumna from '../../../../../../components/DosBotonesColumna';
import Selector from '../../../../../../components/Selector';
import { obtenerNeuronas } from '../../../../../../utils/neuronasUtil';

const JuegoSumas = () => {
    const { GuardarRegistroSumas } = useJuegos();
    const userId = useAppSelector((state) => state.auth.userId);
    const idActividad = useAppSelector((state) => state.activity.id);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { state } = useDato();

    const { 
        estado, partida, nivel, cambio, puntaje, cargado, setEstado, setPartida, 
        setNivel, setCambio, setPuntaje, getNuevoNivelRacha, sumaTotal, diferenciaTotal, 
        nivelGanado, setNivelGanado, Constructor
    } = useJuegoSumas(userId, idActividad);
    

    const arregloNumeros = Array.from({ length: 50 }, (_, index) => ({ value: index + 1, name: (index + 1).toString() }));

    const onReinicio = (diferencia: number, suma: number) => {
        sumaTotal.current += suma;
        diferenciaTotal.current += diferencia;

        const neuronasNuevas = obtenerNeuronas('nomProgSumas1', sumaTotal.current, diferenciaTotal.current); // Calcular neuronasNuevas
        const nivelGuardado = nivel > nivelGanado ? nivel : nivelGanado;

        if (partida + 1 < 5) {
            const [nuevoNivel, nuevaRacha] = getNuevoNivelRacha((suma - diferencia) / suma);
            setNivel(nuevoNivel);
            setCambio(nuevaRacha);
        } else {
            GuardarRegistroSumas(userId, { puntaje: Math.round(neuronasNuevas), nivel: nivelGuardado }, state.nombres, state.pais);
        }

        setPartida(prev => prev + 1);
        setEstado(2);
    };

    const onSiguiente = () => {
        if (partida >= 5) {
            navigate(-1);
        } else {
            setEstado(1);
        }
    };

    useEffect(() => {
        Constructor();
    }, [Constructor]);

    return (
        <div id='page' style={idActividad === 'juego_linea' ? { height: "100%" } : {}}>
            {estado === 0 && (
                <div className={estiloJuegos.contenedorIconoMatematico}>
                    <img src='/assets/componentes/Juegos/CÁLCULO MATEMÁTICO.svg' className={estiloJuegos.icono} alt="Ícono de cálculo matemático" />
                    <Selector setSelection={setNivel} selection={nivel} style_contain={{ border: '1px solid pink' }} opciones={arregloNumeros} />
                    <button className={estiloJuegos.buttonEmpezar} onClick={() => setEstado(1)}>EMPEZAR</button>
                </div>
            )}

            {estado > 0 && <ProgressBar tamaño={5} posicion={partida} />}
            {cargado && <PartidaSumas estado={estado} nivel={nivel} onReinicio={onReinicio} />}

            {estado === 2 && (
                <DosBotonesColumna
                    textoSuperior="SIGUIENTE"
                    textoInferior="VOLVER"
                    onClickSuperior={onSiguiente}
                    onClickInferior={() => navigate(-1)}
                />
            )}
        </div>
    );
};

export default JuegoSumas;
