import styleGame from "../../../../../../theme/programas/calculo/Game1.module.css";

import { useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { buttons } from '../../../../../utils/Buttons';
import { useJuegoCodificacionLogica } from '../utils/useJuegoCodificacionLogica';
import { palabras } from "../../../../../../datos/DatosMemPalabras";
import { changeCondicion } from "../../../../../../redux/actividad/reducerActividad";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import useRegistroEntrenamiento from "../../../../rutasPrivadasMy/entrenamiento/repository/useRegistroEntrenamiento";
import Button from "../../../../../components/Button";


export const useJuegoCodificacion = () => {
    const { GenerarIndicesValidosRandom, CalcularRespuesta, obtenerMensaje } = useJuegoCodificacionLogica();

    const [etapa, setEtapa] = useState(0);
    const [loading, setLoading] = useState(false);
    const [palabrasAPreguntar, setPalabrasAPreguntar] = useState<number[]>([]);
    const [indicePalabraAMostrar, setIndicePalabraAMostrar] = useState(0);
    const [inputsUsuario, setInputsUsuario] = useState(new Array(10).fill(''));

    const { actualizarRegistroEntrenamiento } = useRegistroEntrenamiento();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { nivel } = useParams<{ nivel: string }>();
    const parametro = useAppSelector((state) => state.activity?.tiene_bloqueo ?? 0);
    const userId = useAppSelector((state) => state.auth.userId);
    const idEntrenamiento = useAppSelector((state) => state.entrenamiento.current_entrenamiento?.id ?? null);
    const indiceActividad = useAppSelector((state) => state.entrenamiento.current_actividad);
    const indiceSesion = useAppSelector((state) => state.entrenamiento.current_sesion);
    const lenghtActividades = useAppSelector((state) => state.entrenamiento.current_actividades?.length);
    const emailUsuario = useAppSelector((state) => state.auth.email);

    const HandleBackButtonClick = async () => {
        if (ContarRespuestas >= parametro) {
            actualizarRegistroEntrenamiento(emailUsuario, userId, idEntrenamiento, indiceSesion + 1, indiceActividad + 1);
            dispatch(changeCondicion({ condicion: ContarRespuestas }));
            navigate(lenghtActividades === 1 ? `/my/entrenamiento/${idEntrenamiento}` : `/my/entrenamiento/${idEntrenamiento}/${indiceSesion}`);
        } else if (etapa === 2) {
            HandleEmpezarJuego();
        } else {
            navigate(lenghtActividades === 1 ? `/my/entrenamiento/${idEntrenamiento}` : `/my/entrenamiento/${idEntrenamiento}/${indiceSesion}`);
        }
    };

    const HandleEmpezarJuego = () => {
        let rangeStart = 0, rangeEnd = 100;
        const nivelDefinido = parseInt(nivel) || 1;

        if (nivelDefinido >= 4) {
            rangeEnd = 300;
        } else if (nivelDefinido > 0) {
            rangeStart = (nivelDefinido - 1) * 100;
            rangeEnd = rangeStart + 100;
        }

        let palabrasIndices = GenerarIndicesValidosRandom(10, rangeStart, rangeEnd);
        console.log("🚀 ~ HandleEmpezarJuego ~ palabrasIndices:", palabrasIndices)
        setPalabrasAPreguntar(palabrasIndices);
        setIndicePalabraAMostrar(0);
        setInputsUsuario(new Array(10).fill(''));
        setEtapa(1);
    };

    const HandleInput = (e: any, textoInput: any) => {
        if (textoInput === 'Enviar') {
            if (indicePalabraAMostrar === 9) {
                return aResultadoFinal();
            } else {
                setIndicePalabraAMostrar((prev) => prev + 1);
                return;
            }
        }
        setInputsUsuario((prev) => {
            const nuevoInputsUsuario = [...prev];
            if (textoInput === 'Borrar') {
                nuevoInputsUsuario[indicePalabraAMostrar] = nuevoInputsUsuario[indicePalabraAMostrar].slice(0, -1);
            } else {
                nuevoInputsUsuario[indicePalabraAMostrar] = nuevoInputsUsuario[indicePalabraAMostrar] + textoInput;
            }
            return nuevoInputsUsuario;
        });
    };

    const ChecarRespuestas = () => {
        let correctas: boolean[] = [];

        for (let i = 0; i < inputsUsuario.length; i++) {
            const indicePalabra = palabrasAPreguntar[i];
            const palabra = palabras[indicePalabra];
            const respuestaCorrecta = CalcularRespuesta(palabra);
            const respuestaUsuario = inputsUsuario[i];
            const esCorrecta = respuestaUsuario === respuestaCorrecta;
            correctas.push(esCorrecta);
        }

        return correctas;
    };

    const aResultadoFinal = () => {
        setEtapa(2);
    };

    const GetMensajes = () => {
        return obtenerMensaje(ContarRespuestas);
    };

    const LayoutCalculator = () => {
        return(
            <div className={styleGame.calculadoraSumas1} style={{}}>
				{buttons.flat().map((button,_index) => {
                    return <Button key={_index} value={button.value} special={button.special} send={button?.send} clickEvent={HandleInput} />;
                })}
			</div>
        )
    };

    const getPalabraWrapper = (i: number) => {
        return palabras[palabrasAPreguntar[i]];
    };

    const GenerarArrayResultadosAMostrar = () => {
        const arrayPalabras = palabrasAPreguntar.map((e) => palabras[e]);
        const arrayCodeWord = ['Palabra', ...arrayPalabras];
        const arrayInputs = inputsUsuario.map((e) => e === "" ? 'No respondió': e);
        const arrayInputUser = ['Respuesta', ...arrayInputs];
        let array = [arrayCodeWord,arrayInputUser]
        return array

    }

    const arrayBooleanCorrectas= useMemo(() => (etapa === 2)? ChecarRespuestas():[], [etapa])
    const ContarRespuestas = useMemo(() => ChecarRespuestas().filter(r => r === true).length, [arrayBooleanCorrectas]);

    return {
        etapa,
        loading,
        palabras,
        arrayBooleanCorrectas,
        palabrasAPreguntar,
        inputsUsuario,
        indicePalabraAMostrar,
        LayoutCalculator,
        HandleBackButtonClick,
        HandleInput,
        GetMensajes,
        ContarRespuestas,
        HandleEmpezarJuego,
        getPalabraWrapper,
        GenerarArrayResultadosAMostrar
    };
};
