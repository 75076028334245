import React, { CSSProperties, Component, ReactNode } from 'react';
import '../../../../../../theme/Juegos.module.css';
import estiloLanding from "../../theme/landing.module.css";

interface VideoPlayerProps {
  children?: ReactNode;
  videoUrl?: string | string[];
  setLoading?:React.Dispatch<React.SetStateAction<boolean>>;
  setNeuronasGanadas?: React.Dispatch<React.SetStateAction<number>>
  autoplay?:boolean;
  poster?: string;
  preload?: string;
  style_contain?: CSSProperties;
  style_video?: CSSProperties;
  adelantar?: boolean;
}

interface state{
  currentPosition:number;
  muted: boolean;
  timeBeforeSeek: number | null;
}

class VideoConContador extends Component<VideoPlayerProps,state> {
  private videoRef: React.RefObject<HTMLVideoElement>;
  private miVideo = document.getElementById('videoReproduciendo');

  constructor(props: VideoPlayerProps) {
    super(props);
    this.state = {
      currentPosition: 0,
      muted: this.props.autoplay,
      timeBeforeSeek: null,
    };
    this.videoRef = React.createRef();
  }

  capturarInfoVideo = (): number => {
    let miVideo = document.getElementById('videoReproduciendo');
    
    if (miVideo instanceof HTMLVideoElement && miVideo !== null) {
      let porcentaje = Number((miVideo.currentTime/miVideo.duration * 100).toFixed(0))
      if(Number.isNaN(porcentaje)){
        porcentaje = 0
      }
      if(this.props.setNeuronasGanadas){
        this.props.setNeuronasGanadas(porcentaje)
      }
      // setState({neuronas:porcentaje}) 
      return porcentaje
    } else {
      return 0
    }
  }

  HandleUnMuted = () => {
    this.setState({muted: !this.state.muted})
  }

  render() {
    return (
      <div style={{...this.props.style_contain,position:'relative'}}>
        {this.state.muted && <div className={estiloLanding.unmuteButton} onClick={this.HandleUnMuted}></div>}
        <video 
          autoPlay={this.props.autoplay}
          muted={this.state.muted}
          ref={this.videoRef}
          controls
          controlsList="nodownload"
          id="videoReproduciendo"
          poster={this.props.poster ?? ""}
          preload={this.props.preload ?? ""}
          style={this.props.style_video} 
          className='video'
          playsInline
          >
            { 
              typeof(this.props.videoUrl) === 'string' ?
                <source src={this.props.videoUrl} type="video/mp4"/>
              :
              this.props.videoUrl?.map((url) => 
                <source src={url} type="video/mp4"/>)
            }
            {this.props.children}
          Tu navegador no soporta la reproducción de video.
        </video>
      </div>
    );
  }
};

export default VideoConContador;
