import React from 'react'
import style from '../../../../../../theme/entrenamiento/entrenamientoActividades.module.css'
import styleSC from "../../../../../../theme/entrenamiento/componentes/NeuronaSesion.module.css";
import { EstadosSesion } from '../../utils/UtilsEntrenamiento'

const ImagenCheck = ({status}:{status:EstadosSesion}) => {
    return (
        <div style={{position: 'absolute',top:'49%',right:'6.5%',transform:'translateY(-50%)'}} className={style.activity_bk_white}>
            { status === EstadosSesion.COMPLETADO ? 
                <svg xmlns="http://www.w3.org/2000/svg" width="3.5em" height="3.5em" viewBox="0 0 53 53" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M26.492 52.984C41.1232 52.984 52.984 41.1232 52.984 26.492C52.984 11.8609 41.1232 0 26.492 0C11.8609 0 0 11.8609 0 26.492C0 41.1232 11.8609 52.984 26.492 52.984ZM22.7738 30.7604C22.1196 31.5752 21.4428 32.4182 20.735 33.3009L20.7384 33.2943C20.4806 32.9664 20.2448 32.6529 20.0194 32.3533C19.5356 31.7101 19.0999 31.1308 18.5976 30.6103C17.2784 29.2467 15.908 27.9229 14.4968 26.6489C13.5048 25.7532 12.414 25.8892 11.4254 26.7054C10.3925 27.558 10.3414 28.6064 11.0129 29.678C11.3913 30.2851 11.8379 30.879 12.3526 31.38C14.5956 33.5697 16.8421 35.756 19.1567 37.876C20.9122 39.4851 21.9792 39.3789 23.5916 37.6106C24.045 37.1163 24.4676 36.5987 24.8869 36.0745C25.2195 35.6588 25.5521 35.243 25.8847 34.8272C30.4305 29.145 34.9772 23.4616 39.4972 17.7609C39.5229 17.7284 39.5489 17.6955 39.5752 17.6623C40.0359 17.0806 40.587 16.3848 40.5676 15.7603C40.5404 14.8877 40.1722 13.7796 39.5347 13.2521C39.0643 12.8639 37.7826 12.99 37.1145 13.3417C36.2891 13.7755 35.6332 14.5889 35.026 15.342C35.0062 15.3665 34.9865 15.3909 34.9669 15.4152C33.4554 17.2944 31.966 19.1879 30.4766 21.0814C29.4837 22.3437 28.4908 23.606 27.4913 24.864C25.9741 26.7748 24.4481 28.6754 22.7738 30.7604Z" fill="#77B15C"/>
                </svg>:
                status === EstadosSesion.ACTUAL ?
                    <img src={'assets/icon/icon_play_pink.svg'} style={{width:'3.5em',maxHeight:'3.5em'}}></img>:
                <div className={style.activity_candado}></div>
            }
        </div>
    )
}

const ActividadBox = ({status,title,onClick}:{status:EstadosSesion,title:string,onClick:() => void}) => {

    const GenerateActivityBox = (status: EstadosSesion) => {
        let color;
        
        if (status === EstadosSesion.COMPLETADO){
            color = '#9C2C6E';
        }else if (status === EstadosSesion.ACTUAL) {
            color = '#D74286';
        }else{
            color = 'white'
        }

        return(
            <div style={{position:'relative'}} className={ status === EstadosSesion.ACTUAL && styleSC.actual} onClick={onClick}>
                <text className={style.textActivity} style={status === EstadosSesion.INCOMPLETO ? {color:'var(--color-Light-Grey)'} : {}}>{title}</text>
                <ImagenCheck status={status}></ImagenCheck>
                <svg xmlns="http://www.w3.org/2000/svg" width="20.8em" height="7.05em" viewBox="0 0 324 109" fill="none" className={`${style.activity_box} ${status !== EstadosSesion.ACTUAL && style.activity_box_shadow}`}>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15 0C6.71573 0 0 6.71573 0 15V94C0 102.284 6.71573 109 15 109H309C317.284 109 324 102.284 324 94V15C324 6.71573 317.284 0 309 0H15ZM242 16C233.716 16 227 22.7157 227 31V73.6797C227 81.964 233.716 88.6797 242 88.6797H284.68C292.964 88.6797 299.68 81.964 299.68 73.6797V31C299.68 22.7157 292.964 16 284.68 16H242Z" fill={color}/>
                </svg>
            </div>
        )
    }

    const GenerateConeccion = (status) => {
        let color;
        if (status === EstadosSesion.COMPLETADO){
            color = '#9C2C6E';
        }else{
            color = 'rgb(0, 0, 0, .25)'
        }
        return(
            <div style={{background:color}} className={style.coneccionActivity}></div>
        )
    }

    return (
        <>
            {GenerateActivityBox(status)}
            {GenerateConeccion(status)}
        </>
    )
}

export default ActividadBox