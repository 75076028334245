import React, { ReactNode } from "react";
import NavegacionImagen from "./NavegacionImagen";
import NavegacionPalabra from "./NavegacionPalabras";
import style from "../../../../../../../theme/Juego2.module.css"
import BotonesNavegacion from "./BotonesNavegacion";

interface propSegundaPantalla {
    digitos: number;
    datoPrincipal: string;
    resetContenido: () => void;
    contenidoIdIndice: number;
    cantidadDeValores: number;
    tituloPalabras: string;
    leftSubmit: () => void;
    rightSubmit: () => void;
    aSiguientePantalla: () => void;
    getObjetos?: () => ReactNode;
 
}
const   SegundaPantalla: React.FC<propSegundaPantalla> = (props) => {
    const MostrarObjetos = () =>{
        if(props.getObjetos){
            return props.getObjetos();
        }else{
            return null;
        }
    }
    return (

        <div  style={{display:'flex',flexDirection:'column',padding:'0% 0% 0% 0%', width:'100%', height: '-webkit-fill-available',overflowY: 'scroll'}}>
            {(props.getObjetos) ? 
            <>
                <div className="ion-text-center">
                    <NavegacionImagen obtenerImagen={MostrarObjetos}/>
                </div>
                <div className="ion-text-center" style={{display: "flex",flexDirection: "column", justifyContent: "flex-end"}}>
                    <label className={style.palabra}>{props.datoPrincipal}</label>
                    <BotonesNavegacion cantidadDeValores={props.cantidadDeValores} contenidoIdIndice={props.contenidoIdIndice} leftSubmit={props.leftSubmit} rightSubmit={props.rightSubmit} resetContenido={props.resetContenido}/>
                </div>
            </>:
            <>
                <NavegacionPalabra tituloPalabras={props.tituloPalabras} digitos={props.digitos} datoPrincipal={props.datoPrincipal}  />
                <BotonesNavegacion cantidadDeValores={props.cantidadDeValores} contenidoIdIndice={props.contenidoIdIndice} leftSubmit={props.leftSubmit} rightSubmit={props.rightSubmit} resetContenido={props.resetContenido}/>
            </>
            }
            {props.contenidoIdIndice === props.cantidadDeValores-1 && 
                <button id='button_rosado_default' onClick={props.aSiguientePantalla} style={{width:'calc(100% - 11svh)',height:'fit-content',alignSelf:'center'}}>TERMINAR</button>
            }
        </div>
    );
}

export default SegundaPantalla;