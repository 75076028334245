import { PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "../../auth/reducerAuth";

export const uiReducers = {
    ChangeRedux_cargando: (state, action: PayloadAction<boolean>) => {
        state.cargando = action.payload;
    },
    ChangeRedux_finishSesion: (state, action: PayloadAction<number | null>) => {
        state.finishSesion = action.payload;
    },
    CleanEntrenamiento: (state) => {
        Object.assign(state, initialState);
    },
};
