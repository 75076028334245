
/** Internas */
// import PruebaGrafica from '../programas/resultados/componentes/PruebaGrafica';
import  "../../../../../theme/pages.css"; 
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { useDato } from "../../../../../modelos/auth";
import { newTorneo } from "../../../../../redux/actividad/reducerActividad";
import { logout } from "../../../../../redux/auth/reducerAuth";
import { CleanEntrenamiento } from "../../../../../redux/entrenamiento/reducerEntrenamiento";
import { useAppSelector, useAppDispatch } from "../../../../../redux/store";
import { useTests } from "../../../../../repositorio/controlador/useTests";
import Loading from "../../../../components/Loading";

const SettingsPage: React.FC = () => {
	const [loading, setLoading] = useState(false);
	const [testsAdmin, setTestsAdmin] = useState([]);
	const userId = useAppSelector((state) => state.auth.userId);
	const navigate = useNavigate();

    const {
	// borrarCuenta,
		logOutCuenta
    } = useDato();

	const {
		consultarTestsAdminUsuario,
	} = useTests();

	// const filtrarPorFechas = async (testAFiltrar:Test[]) => {
	// 	const fechaActual = Timestamp.now();
	// 	const testsVigentes = testAFiltrar.filter((test, index, self) => {
	// 	  const fechaInicio = test.fechaInicio;
	// 	  const fechaFin = test.fechaFin;      
	
	// 	  if (!fechaInicio || !fechaFin) {
	// 		return true;
	// 	  }
	// 	  // Comparamos las fechas utilizando los métodos getTime() de los objetos Timestamp
	// 	  return fechaInicio.toMillis() <= fechaActual.toMillis() && fechaFin.toMillis() >= fechaActual.toMillis();
	
	// 	});
	
	// 	return testsVigentes;
	//   }

	const consulta = async () => {
		setLoading(true)
		
		const respuesta = await consultarTestsAdminUsuario(userId);
		console.log("🚀 ~ file: SettingsPage.tsx:65 ~ consulta ~ respuesta:", respuesta)
		
		setTestsAdmin(await respuesta)
		setLoading(false)
	}

	const dispatch = useAppDispatch();

	// const borrarSesion = async () => {
	// 	await dispatch(logout());
	// 	borrarCuenta();
	// }

	const cerrarSesion = async () => {
		dispatch(logout());
		dispatch(CleanEntrenamiento())
		logOutCuenta();
	}

	const inicializar = (id: string, name: string, torneo: boolean) => {
		dispatch(newTorneo({ id: id, name: name, torneo:torneo}))
	  }

	useEffect(() => {
		setTestsAdmin([])
		consulta()
    }, []);

    return (
	<div id='page'>
		<Loading isOpen={loading}></Loading>
		<div id='toolbar_default'>
			<h1 className="ion-text-center title">Ajustes</h1>
		</div>
	    <div id='content_default' style={{padding:'10%'}}>
			<button id='button_rosado_default'
			onClick={cerrarSesion}
				>
				Logout
			</button>
				
			<div style={{display:'grid',rowGap:'5%',width:'100%',height:'20vh',justifyItems:'center'}}>
				{testsAdmin.map((element, index) => (
				<div key={index} style={{ width: '95%',boxShadow: '-3px 4px 7px 0px black', borderRadius: '10px',
				display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
					<div onClick={() => {
					inicializar(element.id, element.name ,element?.torneo);
					navigate(`/my/TestAdminPage/${element.nombre}`)
					}}>
					<label>{element.nombre}</label>
					</div>
				</div>
					)
				)}
			</div>
				
					

			{	/* 
					<PruebaGrafica value={40}/>
					
				<IonButton color="medium" expand="block"
			onClick={borrarSesion}
				>
				Borrar Perfil
			</IonButton> */}
		</div>
			
	</div>
    );

}

export default SettingsPage;
