import { PayloadAction } from "@reduxjs/toolkit";
import { Entrenamiento } from "../../../vista/rutasPublicas/rutasPrivadasMy/entrenamiento/models/Entrenamiento";

export const entrenamientosReducers = {
    ChangeRedux_entrenamientos_app: (state, action: PayloadAction<{ entrenamientos: Entrenamiento[] }>) => {
        state.entrenamientos_app = action.payload.entrenamientos;
    },
    ChangeRedux_entrenamientos_user: (state, action: PayloadAction<{ entrenamientos: string[] }>) => {
        state.entrenamientos_user = action.payload.entrenamientos;
    },
    ChangeRedux_current_entrenamiento: (state, action: PayloadAction<{ entrenamiento: Entrenamiento }>) => {
        state.current_entrenamiento = action.payload.entrenamiento;
    },
};
