import '../../../theme/variables.css';
import '../../../theme/navBar.css';

import { Route, Navigate, useLocation, Routes, NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Timestamp } from 'firebase/firestore';
import { nomUsuarios, nomUsuarioPublicidad, nomNeuronas } from '../../../datos/nombresBD';
import { useBaseDeDatos } from '../../../generalUtils/repositoryUtils/useBaseDeDatos';
import { newActivity, changePrograma } from '../../../redux/actividad/reducerActividad';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import Publicidad from '../../components/Publicidad';
import NotFoundPage from '../../NotFoundPage';
import { aspectRatio } from '../../utils/boundarys';
import AdminProgramasPage from '../admin/AdminProgramasPage';
import AdminTestsPage from '../admin/AdminTestsPage';
import FormCuestionario from '../programas/pages/FormCuestionario';
import JuegoSimultaneo from '../PvP/JuegoSimultaneo';
import { TabsEntreno } from './entrenamiento/TabsEntrenamiento';
import { TabsHome } from './home/TabsHome';
import ConsultaIA from './inteligenciaArtificial/ConsultaIA';
import TabsJuegos from './juegos/TabsJuegos';
import ShowInfoTest from './resultados/componentes/ShowInfoTest';
import ShowResults from './resultados/componentes/ShowResults';
import RankingPageNeuronas from './resultados/RankingPageNeuronas';
import { useRoutesTest } from './test/Routes';

const AppTabs: React.FC = () => {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const { recogerDoc, agregarDoc } = useBaseDeDatos();
	const userID = useAppSelector(state => state.auth.userId);

	const RoutesTests = useRoutesTest();

	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedImg, setSelectedImg] = useState(0);
	const [esEntrenamiento, setEsEntrenamiento] = useState(false);
	const [mostrarEvento, setMostrarEvento] = useState(false);

	const eventoActivo = false; //CAMBIAR A TRUE EN CASO DE EVENTO (CAMBIAR DATOS DEL EVENTO DE FORMA ACORDE)

	const getEstadoEvento = async () => {
		const data = (await recogerDoc(`${nomUsuarios}/${userID}/${nomUsuarioPublicidad}/registro`)).data();
		if(data && data.fecha){
			const fechaInicial = (data.fecha as Timestamp).toDate();
			const fechaActual = new Date();

			let diferenciaTiempo = fechaActual.getTime() - fechaInicial.getTime();

			let diferenciaHoras = Math.round(diferenciaTiempo / (1000 * 3600));

			if(diferenciaHoras >= 96){ // 96 horas === 4 dias
				setMostrarEvento(true);
			}
		} else{
			setMostrarEvento(true);
			agregarDoc(`${nomUsuarios}/${userID}/${nomUsuarioPublicidad}/registro`,
			{
				fecha: Timestamp.now()
			});
		}
	}

	useEffect(() => {
		if (location.pathname == "/my/sesiones" ) {
			setEsEntrenamiento(true);
		} else {
			setEsEntrenamiento(false);
		}
	}, [location])

	useEffect(() => {
		if(!eventoActivo) return;
		if(userID){

			getEstadoEvento();
		}
	}, [])

	const handleClick = (tab:number) =>{
		setSelectedTab(tab);
		setTimeout(()=>{
			setSelectedImg(tab)
		},100)
	}
	const HandleCerrarEvento = () => {
		setMostrarEvento(false);
		agregarDoc(`${nomUsuarios}/${userID}/${nomUsuarioPublicidad}/registro`,
			{
				fecha: Timestamp.now()
			}
		);
	}
	
	const loggedIn = useAppSelector((state) => state.auth.loggedIn)

	//cambio valores para consulta
	const handleRedirectNeuronas = () => {
		handleClick(3);
		dispatch(newActivity({ id: nomNeuronas, name: nomNeuronas }))
		dispatch(changePrograma({ id: nomNeuronas }))
	}
	// si el usuario no esta logeado, directa a login
	if (!loggedIn) {
		return <Navigate to="/login" replace/>;
	}
	return (
		<>
			{mostrarEvento &&
				<Publicidad cerrarPosicion={{top:"-5px",left:"-5px",width:"15%",height:"10%"}}
					onCerrar={HandleCerrarEvento}
					onEvento={HandleCerrarEvento}
					href="https://www.tiendacompensar.com/activaci%C3%B3n-para-adulto-mayor/talleres-de-estimulacion-cognitiva-supercerebros/HER-B-RED-REC-PMY-133"
					imagen='/assets/images/eventos/estimulacionCognitivaEvento.png'
					fillPercentage={(aspectRatio() <= 3/5)?100:80}
				/>
			}
			<Routes>
				{/* se agrupan las rutas por los 5 tabs del navbar */}

				{/* home */}
				<Route path="home/*" element={<TabsHome />}/>								

				{/* Entrenamientos */}
				<Route path="entrenamiento/*" element={<TabsEntreno />}/>															
													
				{/* Rutas de juegos */}
				<Route path="juegos/*" element={<TabsJuegos />}/>
				
				{/* Ranking*/}								
				<Route path="rankingNeuronas" element={<RankingPageNeuronas />}/>
															
				{/* consultaIA  */}
				<Route path="consultaIA"element={<ConsultaIA />}/>									


				{/* Rutas de Tests */}
				{RoutesTests}
				
				<Route path="ResultsPage/:nombre" element={<ShowResults />}/>									
				<Route path="ShowInfoTest/:nombre" element={<ShowInfoTest />}/>				
													
				<Route path="AdminCuestionario" element={<FormCuestionario />}/>							
				<Route path="AdminTests" element={<AdminTestsPage />}/>							
				<Route path="AdminTests/:idTests/:nombreTest" element={<AdminProgramasPage />}/>								
				
				<Route path="simultaneo/:idSala?" element={<JuegoSimultaneo />}/>		
				<Route path='*' element={<NotFoundPage />}/>				
			</Routes>
			<nav className='navBar'>
				<NavLink className={`nav_item ${(isActive) => isActive ? "active" : ""}`} to="/my/home" >
					{({ isActive }) => (
						<>
						<img src={isActive ? '/assets/icon/icon_home_s.svg' : '/assets/icon/icon_home.svg'} alt="" />
						<p className={isActive ? 'item_seleccionado' : ''}>inicio</p>
						</>
					)}
				</NavLink>

				<NavLink className={`nav_item ${(isActive) => isActive ? "active" : ""}`} to="/my/juegos">
					{({ isActive }) => (

						<>
							<img src={isActive ?'/assets/icon/icon_juegos_s.svg': '/assets/icon/icon_juegos.svg'} alt="" />					
							<p className={ isActive  ? 'item_seleccionado':''}>juegos</p>
						</>
					)}
				</NavLink>

				<NavLink className={`nav_item ${( isActive ) => isActive ? "active" : ""}`} to="/my/rankingNeuronas" onClick={handleRedirectNeuronas}>
					{({ isActive }) => (
						<>
							<img src={isActive?'/assets/icon/icon_neuronas_s.svg': '/assets/icon/icon_neuronas.svg'} alt="" />				
							<p className={isActive ? 'item_seleccionado':''}>neuronas</p>
						</>
					
					)}
				</NavLink>

				<NavLink className={`nav_item ${(isActive) => isActive ? "active" : ""}`} to="/my/entrenamiento">
					{({ isActive }) => (
						<>
							<img src={isActive ?'/assets/icon/icon_entreno_s.svg': '/assets/icon/icon_entreno.svg'} alt="" />				
							<p className={isActive ? 'item_seleccionado':''}>entreno</p>
						</>
					)}
				</NavLink>

				<NavLink className={`nav_item ${(isActive ) => isActive ? "active" : ""}`} to="/my/consultaIA">
					{({ isActive }) => (
						<>
							<img src={ isActive ?'/assets/icon/icon_chat_s.svg': '/assets/icon/icon_chat.svg'} alt="" />	
							<p className={ isActive ? 'item_seleccionado':''}>chat</p>
						</>
					)}
				</NavLink>
				<img className='ellipse' src='/assets/icon/ellipseNavBar.png' alt="" />
			</nav>
		</>
	);
}

export default AppTabs;
