import { createSlice } from '@reduxjs/toolkit';
import { JuegoSimultaneoRedux } from './models/juegoSimultaneo';
import { juegoSimultaneoReducers } from './actions/juegoSimultaneoReducers';

const initialState = {
    nombre: null,
    correo : null,
    telefono: null,
    ultimoResultado : 0,
    total : 0,
    tiempo : null,
    finalizado : false,
    verPuntaje : false,
    cantidadIntegrates : 0,
    sala : null,
} as JuegoSimultaneoRedux

export const sliceJuegoSimultaneo = createSlice({
    name: 'juegoSimultaneo',
    initialState,
    reducers: juegoSimultaneoReducers,
});

// Exportar las acciones
export const {
    addIntegrante,
    addCantidadIntegrantes,
    terminadoJuego,
    resincronizarJuego,
    changePuntaje,
    cleanJuego,
    clear,
} = sliceJuegoSimultaneo.actions;

export default sliceJuegoSimultaneo.reducer;
