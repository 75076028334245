import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import stylePrograma from '../../../../../theme/admin/programa.module.css'
import style from '../../../../../theme/admin/entidad.module.css'
import { load } from "../../../../../redux/auth/reducerAuth";

type Params = {
    nombreEntidad: string;
    id:string;
}

const EntidadPage: React.FC = () => {
    //recibe el id de la entidad por el link
    const { nombreEntidad,id } = useParams<Params>();
    const [ areas,setAreas ] = useState([]);
    const [ openVentana,setOpenVentana ] = useState(false);

    //hooks para creacion de area
    const [ nombreArea, setNombreArea ] = useState("");
    const [ descripcion, setDescipcion ] = useState("");

    
    const navigate = useNavigate();
    
    const consultaEntidad = async () => {
        // const respuesta:any[] = await consultarAreas(id);
        setAreas([{nombre: 'entidad1'},{nombre: 'entidad2'},{nombre: 'entidad3'}])
    }

    useEffect(() => {
        load();
        consultaEntidad()
        load();
    }, []);


    /*
    const creacionArea = async () => {
        if(nombreArea!=="" && descripcion!=""){
            const nuevaArea = aArea(nombreArea, descripcion)
            await crearArea(id,nuevaArea)
            setNombreArea("")
            setDescipcion("")
            consultaEntidad()
            setOpenVentana(false)
        }else{
            presentDatosIncompletos()
        }
    }*/

    const handleRedirect = ( ruta:string) => {
        navigate(ruta)
    }

    return(
        <div className={stylePrograma.page}>
            <header className={stylePrograma.header}>
                <div className={stylePrograma.toolbar}>
                    <button className={stylePrograma.backButton} onClick={() => navigate(-1)}/>
                    <h1 >{nombreEntidad}</h1>
                </div>
            </header>
            <div className={stylePrograma.content}>
                <div className={stylePrograma.lista}>
                    {areas.map((element) => (
                        <div className={stylePrograma.card} onClick={() => navigate(`/my/home/entidades/${nombreEntidad}/${id}/${element.id}`)}>
                            <p>
                                {element.nombre}
                            </p>
                        </div>
                    ))}
                    {areas.length === 0 ? <p>No hay áreas</p> : <br/>}
                </div>
                <button className={stylePrograma.button} onClick={()=>{setOpenVentana(true)}}>Crear Area</button>
            </div>
            <div className={openVentana ? style.modalActive : style.modalDesactive}>
                <header className={style.headerCard}>
                    <div className={stylePrograma.toolbar}>
                        <h1>Crear Area</h1>
                        <img className={style.exit} src="/assets/icon/closeCircle.svg" onClick={() => setOpenVentana(false)}/>
                        {/* <IonIcon icon={closeCircle} onClick={() => setOpenVentana(false)}/> */}
                    </div>
                </header>
                <div className={style.content}>
                    <div className={stylePrograma.lista}>
                        <div className={style.containerInput}>
                            <p>Nombre</p>
                            <input 
                            value={nombreArea} 
                            onChange={(event) => {setNombreArea(event.target.value)}}/>
                        </div>
                        <div className={style.containerInput}>
                            <p>Descripción</p>
                            <textarea 
                            className={style.area}
                            rows={10}
                            cols={50}
                            placeholder="Ingrese una descripción del área a crear"
                            value={descripcion}
                            onChange={(event) => {setDescipcion(event.target.value)}}/>
                        </div>
                        <button className={stylePrograma.button}
                        // onClick={creacionArea}
                        >Crear</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EntidadPage;