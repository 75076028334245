import React, { useEffect, useState } from 'react'
import '../../../theme/anexos.css'
import { useLocation } from 'react-router-dom';

export const MemorizacionPalabras = () => {
    const location = useLocation();
    const [array, setArray] = useState([1,11,2,12,3,13,4,14,5,15,6,16,7,17,8,18,9,19,10,20]);
    useEffect(()=> {
        if(location.pathname == "/presentacionMemorizacionPalabras"){
            // const largo = document.getElementById('el-body').clientHeight ;
            // const ancho = document.getElementById('el-body').clientLeft ;
            // console.log("🚀 ~ file: MemorizacionPalabras.tsx:12 ~ useEffect ~ ancho:", ancho)
            // setAncho(ancho)
            // if((largo*3/4) <= ancho){
                
            // }
        }
    }
        ,[])
  return (
    <div className='fondo-presentacion'>
        <div className='contenedor-icono-fondo'>
            <img src='/assets/images/logo-supercerebros.png' style={{width:'100%',height:'100%',background: 'transparent'}}></img>
        </div>
        <h1 className='title-presentacion'>memorización de palabras</h1>
        <div className='el-grid'>
            {array.map((element,index) => (
                <div key={index} style={{display:'flex',flexDirection:'row', height:'80%',width:'90%'}}>
                    <p className='label'>{element}</p>
                    <input type='text' className='el-input'/>
                </div>
            ))}

        </div>
    </div>
  )
}

export default MemorizacionPalabras;