import React from 'react'
import style from '../../../../../../theme/entrenamiento/entrenamientoActividades.module.css'

const ProgressBarTraining = ({actual,total}:{actual:number,total:number}) => {
  let porcentaje = (actual / total * 100).toFixed(0)
  return (
    <>
      <div className={style.progressBar}>
          <div style={{width:`${porcentaje}%`}} className={style.contentProgressBar}>
          </div>
      </div>
      <p style={{color:'var(--color-purple-dark)',fontWeight:'600'}}>{porcentaje}% COMPLETADO</p>
    </>
  )
}

export default ProgressBarTraining