import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

import estiloPago from '../../../../theme/pagoPage.module.css';

import { paises } from '../../../../datos/paises';
import { useToast } from '../../../../generalUtils/useToast';
import { useDato } from '../../../../modelos/auth';
import { ProductoReferencia, TiposProductos } from '../../../../modelos/Facturas';
import { Producto } from '../../../../modelos/Producto';
import { useEntrenamiento } from '../../../../repositorio/controlador/useEntrenamiento';
import Selector from '../../../components/Selector';
import CrearProducto from '../../admin/componentes/CrearProducto';
import { opcion } from '../juegos/componentes/SelectorNivel';
import compraEfectivo from '../../../utils/compraEfectivo';

export interface Formulario{
  nombre:string;
  codigoTel:string;
  telefono:string;
  especificaciones?:string;
  direccion?:string;
  ciudad?:string;
  correo?:string;
  documentoIdentidad?:number;
}

type Params = {
  titleEntrenamiento:string;
  codigoParams?:string;
}

enum TiposPagos {
  tipoRegalo = "Regalo",
  tipoEfectivo = "Efectivo",
  tipoDescuento = "Efectivo_descuento",
}

const opcionesTiposPagos: opcion[] = [
  { value: null, name: "Sin seleccionar" }, // Opción por defecto
  { value: TiposPagos.tipoRegalo, name: "Tipo Regalo" },
  { value: TiposPagos.tipoEfectivo, name: "Efectivo" },
  { value: TiposPagos.tipoDescuento, name: "Aplicar descuento" },
];

const PagoPage = () => {

  const location = useLocation();

  const { titleEntrenamiento, codigoParams } = useParams<Params>();

  const [usuarioFormulario, setUsuarioFormulario] = useState<Formulario>({nombre:'',codigoTel:'+57',telefono:''})
  const [loading, setLoading] = useState(false)
  const [tipoPago, setTipoPago] = useState<TiposPagos>(TiposPagos.tipoEfectivo)
  const [descuento, setDescuento] = useState(0)
  const [costoCompra, setCostoCompra] = useState(0)
  const [openModalConfirmacion, setOpenModalConfirmacion] = useState(false)
  const [openModalCrearProducto, setOpenModalCrearProducto] = useState(false)
  const [arrayCompra, setArrayCompra] = useState<ProductoReferencia[]>([]);
  const [currentProducto, setCurrentProducto] = useState<{prod:ProductoReferencia,index:number}>({prod:null,index:null});


  const payUrl = useMemo(()=> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development')  
      return process.env.REACT_APP_EPAYCO_URL_CONFIRMATION_DEV;       
    else if (process.env.REACT_APP_ENVIRONMENT === 'production')
      return process.env.REACT_APP_EPAYCO_URL_CONFIRMATION;

  }, [process.env.REACT_APP_ENVIRONMENT]);

  const {
    state
  } = useDato()

  const {
    presentMensajePersonalizado
  } = useToast();

  const {
    consultarProductoVentas,
    consultarCampaña,
  } = useEntrenamiento();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUsuarioFormulario((state) => ({
      ...state,
      [name]: value
    }));
  };

  const setCodigoTel = (e:any) => {
    setUsuarioFormulario((state) => ({
      ...state,
      ['codigoTel']: e.toString()
    }))
  }

  const validarEmail = (valor:string):Boolean => {
    var reg:RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    var regOficial:RegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (reg.test(valor) && regOficial.test(valor)) {
      console.log("válido oficial y extraoficialmente");
      return true
    } else if (reg.test(valor)) {
      console.log("válido extraoficialmente");
      return true
      
    } else {
      console.log("incorrecto");
      return false
    }
  }

  const validarDatos = (): Boolean => {
    if(validarEmail(usuarioFormulario.correo) && usuarioFormulario.codigoTel !== null && usuarioFormulario.telefono !==null && usuarioFormulario.nombre !== null){
      if(arrayCompra.some((el) => el.tipo === TiposProductos.tipoLibroFisico)){
        if(usuarioFormulario.direccion && usuarioFormulario.documentoIdentidad > 0){
          return true
        }else{
          return false
        }
      }
      return true
    }else{
      presentMensajePersonalizado("Datos incompletos")
      return false
    }
  }

  async function handleEfectivo():Promise<void> {
    let total = tipoPago === TiposPagos.tipoRegalo ? 0 : costoCompra-descuento
    console.log("🚀 ~ handleEfectivo ~ total:", total)
    if(validarDatos() && arrayCompra.length > 0){
        console.log("🚀 ~ handleEfectivo ~ validarDatos:", validarDatos())
        setLoading(true)
        try {
          await compraEfectivo(payUrl, 
            {
              id_productos: arrayCompra,
              nombre: usuarioFormulario.nombre,
              telefono: usuarioFormulario.telefono,
              email: usuarioFormulario.correo,
              codigoTelefono: usuarioFormulario.codigoTel,
              documentoIdentidad: usuarioFormulario.documentoIdentidad,
              direccion: `${usuarioFormulario.ciudad}, ${usuarioFormulario.direccion} ${usuarioFormulario.especificaciones}`,
              userId: null,
              campaña: codigoParams,
              valorPagado: total < 0 ? 0 : total,
              tipoModena: "COP",
              urlCompra: location.pathname
            }
          )
          presentMensajePersonalizado("Realizado pago en efectivo")
        }catch(error){
          console.log("🚀 ~ file: PagoPage.tsx:105 ~ handleEfectivo ~ error:", error)
        }   
        setLoading(false)
        setOpenModalConfirmacion(true)
        setArrayCompra([])
        setCurrentProducto({prod:null,index:null})
        setCostoCompra(0)

      }else{
        presentMensajePersonalizado("Datos incompletos")
    }
    
  }

  const CalcularCostoTotal = async () => {
    setLoading(true)
    let total = 0
    let arrayPromesas:Promise<Producto>[] = []
    arrayCompra.map((prod) => {
      if(prod.tipo !== TiposProductos.tipoTaller && prod.tipo !== TiposProductos.tipoAsignacion){
        arrayPromesas.push(consultarProductoVentas(prod.id_referencia))
      }
    })
    let arreglodeProductos = await Promise.all(arrayPromesas)
    console.log("🚀 ~ CalcularCostoTotal ~ arreglodeProductos:", arreglodeProductos)
    arreglodeProductos?.map((prod) => {
      total += prod.precio
    })
    setCostoCompra(total)
    setLoading(false)
  }

  const AgregarProducto = (newProduct:ProductoReferencia) => {
    if(currentProducto.prod !== null){
      let copy = [...arrayCompra]
      copy[currentProducto.index] = newProduct
      setArrayCompra(copy)
      setCurrentProducto({prod:null,index:null})
    }else{
      setArrayCompra([...arrayCompra,newProduct])
    }
  }

  const deleteProducto = () => {
    let arr = arrayCompra.filter((_, i) => i !== currentProducto.index);
    console.log("🚀 ~ deleteProducto ~ arr:", arr)
    setArrayCompra(arr);
    setCurrentProducto({prod:null,index: null})
    setOpenModalCrearProducto(false)
  }

  useEffect(() => {
    CalcularCostoTotal()
    console.log("🚀 ~ PagoPage ~ arrayCompra:", arrayCompra)
  }, [arrayCompra])
  
  const HandleEditarProducto = (prod:ProductoReferencia,index:number) => {
    setCurrentProducto({prod:prod,index: index})
    setOpenModalCrearProducto(true)
  }

  const HandleAgregarProducto = () => {
    setCurrentProducto({prod:null,index:null})
    setOpenModalCrearProducto(true)
  }

  return (
    <div id='content_default'>
      {/* <Loading isOpen={loading}/> */}
      <div style={{maxHeight: "94svh", overflow: "auto"}}>
        <h1 className={estiloPago.title}>                
          <span className={estiloPago.entrenamiento}><strong>Compra Administrador</strong></span><br></br>
        </h1>
        <div className={estiloPago.factura}>
          <p>Factura de Compra</p>
          <div className={estiloPago.linea}>
            {Array.from({ length: 16 }, (_, index) => (
              <div key={index}></div>
            ))}
          </div>
          <div className={estiloPago.grid}>
            <p>valor</p>
            <p className={estiloPago.valor}>{costoCompra.toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</p>
            <p>descuento</p>
            <p className={estiloPago.valor}>{descuento.toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</p>
          </div>
          <div className={`${estiloPago.grid} ${estiloPago.backMorado}`}>
            <p>total</p>
            <p className={estiloPago.valor}>{((costoCompra-descuento) < 0 ? 0 : (costoCompra-descuento)).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</p>
          </div>
        </div>
        <div className={estiloPago.boxInput} style={{display:'grid',gap:'1svh'}}>
          <div style={{display:'grid',gap:'1svh'}}>
            <p className={estiloPago.campoObligatorio}>Nombre Completo*</p>
            <input type='text' name="nombre" style={{width:'100%'}} placeholder='Ingrese su nombre' value={usuarioFormulario.nombre} onChange={handleChange}></input>
            <div className={estiloPago.telefono}>
              <p className={estiloPago.campoObligatorio}>país</p>
              <p className={estiloPago.campoObligatorio}>número de telefono*</p>
              <div className={estiloPago.selector}>
                <Selector setSelection={setCodigoTel} selection={usuarioFormulario.codigoTel} opciones={paises} showValues={true}/>
              </div>
              <input type='number' name="telefono" style={{width:'100%'}} placeholder='Ejemplo: ' value={usuarioFormulario.telefono} onChange={handleChange} ></input>
            </div>
            <p className={estiloPago.campoObligatorio}>Correo electronico*</p>
            <input type='text' name="correo" style={{width:'100%'}} placeholder='Ejemplo: example@xmpl.com' value={usuarioFormulario.correo} onChange={handleChange} ></input>
            <p className={estiloPago.campo}>*campos obligatorios</p>
            {arrayCompra.some((el) => el.tipo === TiposProductos.tipoLibroFisico) && 
              <>
                <input name="documentoIdentidad"  placeholder='Ingrese documentoIdentidad' value={usuarioFormulario.documentoIdentidad} onChange={handleChange}></input>
                <input name="ciudad"  placeholder='Ingrese ciudad' value={usuarioFormulario.ciudad} onChange={handleChange}></input>
                <input name="direccion"  placeholder='Ingrese direccion' value={usuarioFormulario.direccion} onChange={handleChange}></input>
                <input name="especificaciones"  placeholder='Ingrese especificaciones' value={usuarioFormulario.especificaciones} onChange={handleChange}></input>
              </>
            }
          </div>
          <table style={{margin:'0',width:'100%'}}>
            <tr style={{border:'2px solid black'}}>
              <th>id</th>
              <th>tipo</th>
              <th></th>
            </tr>
            {arrayCompra?.map((prod,index) => (
              <tr key={index} style={{alignItems:'center'}}>
                <th>{prod.id_referencia}</th>
                <th>{prod.tipo}</th>
                <button onClick={() => HandleEditarProducto(prod,index)}>Editar</button>
              </tr>
            ))}
          </table>
          {loading? 
            <Spinner style={{marginLeft: "50%", translate: "-50%"}}/>:
            <>
            <div className={estiloPago.divButton}>
              <button onClick={HandleAgregarProducto}>Agregar Producto</button>
            </div>
            <Selector selection={tipoPago} setSelection={setTipoPago} opciones={opcionesTiposPagos} style_contain={{zIndex:'0 !important'}}></Selector>
            {tipoPago === TiposPagos.tipoDescuento && 
              <input type='number' value={descuento} onChange={(e)=>setDescuento(Number(e.target.value))} placeholder='Ingrese el descuento'></input>
            }
            { state?._administrador===true &&
              <div className={estiloPago.divButton}>
                <button onClick={handleEfectivo}>Pagar en Efectivo</button>
              </div>
            }
            </>
          }
          <div className={estiloPago.mediosdePago}>
            <img src='/assets/icon/visa.svg'></img>
            <img src='/assets/icon/mastercard.svg'></img>
            <img src='/assets/icon/paypal.svg'></img>
            <img src='/assets/icon/pse.png'></img>
            <img src='/assets/icon/padaTodo.png'></img>
            <img src='/assets/icon/efecty.png'></img>
          </div>
        </div>
      </div>
      {openModalCrearProducto && <CrearProducto setVisible={setOpenModalCrearProducto} deleteProducto={deleteProducto} pushProducto={AgregarProducto} index={currentProducto.index} initialProducto={currentProducto.prod}></CrearProducto>}
      {openModalConfirmacion && <div className={estiloPago.modal}>
        <div className={estiloPago.content}>
          <label className={estiloPago.title_mensaje}>Bienvenido</label>
          <div className={estiloPago.linea}></div>
          <label className={estiloPago.mensaje}>
            Transaccion existosa ya puedes comenzar
          </label>
          <br></br>
          <button onClick={() => {setUsuarioFormulario({nombre:'',codigoTel:'+57',telefono:''});setOpenModalConfirmacion(false)}} className={estiloPago.buttonTestInicial} style={{width:'50%'}}>
            Ok
          </button>
          <br></br>
        </div>
      </div>}
    </div>
  )
}

export default PagoPage
