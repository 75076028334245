import React, { ReactNode, useEffect, useState } from "react";
import { convertirBinarioADecimal } from "../utils";
import { useJuegoRepaso } from "../hooks/useJuegoRepaso";
import { TiposJuegos } from "../models/models";

const OpcionesRespuesta = ({ pregunta }: { pregunta: ReactNode }) => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setVisible(false); 
  }, [pregunta]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        margin: "5%",
        flexDirection:"row"
      }}
    >
      <h3
        style={{
          background: visible ? "" : "black",  // Cambia el fondo al hacer clic
          color: visible ? "black" : "white",  // Cambia el color del texto al hacer clic
          fontSize: "2em",
          margin: "auto",
        }}
      >
        {convertirBinarioADecimal(pregunta)}
      </h3>
      <button onClick={() => setVisible(!visible)}>
        {!visible ? "View" : "Not"} {/* Cambia el texto del botón */}
      </button>
    </div>
  );
};

export default OpcionesRespuesta;
