import estiloJuegos from "../../../../../../theme/Juegos.module.css";
import style from "../../../../../../theme/programas/calculo/Game1.module.css";

import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";

import { test_general } from "../../../../../../datos/DatosTests";
import { nomProgSumas1 } from "../../../../../../datos/nombresBD";
import { useDato } from "../../../../../../modelos/auth";
import {
  newActivity,
  changePrograma,
  changeCondicion,
} from "../../../../../../redux/actividad/reducerActividad";
import { initialNeuronas } from "../../../../../../redux/auth/reducerAuth";
import {
  terminadoJuego,
  changePuntaje,
} from "../../../../../../redux/juegoSimultaneo/reducerJuegoSimultaneo";
import { useAppSelector, useAppDispatch } from "../../../../../../redux/store";
import { useJuegos } from "../../../../../../repositorio/controlador/useJuegos";
import DosBotonesColumna from "../../../../../components/DosBotonesColumna";
import Selector from "../../../../../components/Selector";
import { obtenerNeuronas } from "../../../../../utils/neuronasUtil";
import PartidaSumas from "./juegoSumas/components/PartidaSumas";
import Loading from "../../../../../components/Loading";
import ProgressBar from "../../componentes/ProgressBar";

const JuegoSumas = () => {
  const userId = useAppSelector((state) => state.auth.userId);

  const esJuego = useAppSelector((state) => state.activity.id === test_general);
  const idActividad = useAppSelector((state) => state.activity.id);
  const esEntrenamiento = useAppSelector(
    (state) => state.activity.name === "entrenamiento"
  );
  const idEntrenamiento = useAppSelector(
    (state) => state.entrenamiento.current_entrenamiento?.id
  );

  const neuronas = useAppSelector((state) => state.auth.neuronas);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { GuardarRegistroSumas, getCalificacionGeneral } = useJuegos();

  const { state } = useDato();

  const titulo = "Sumas de un digito";
  const MAXPARTIDAS = 5;
  const CAMBIONIVELBASE = 6;
  const NIVELINICIAL = 6;
  const arregloNumeros = Array.from({ length: 50 }, (_, index) => ({
    value: index + 1,
    name: index + 1,
  }));

  const sumaTotal = useRef<number>(0);
  const diferenciaTotal = useRef<number>(0);

  const [estado, setEstado] = useState<number>(0);
  const [partida, setPartida] = useState<number>(0);
  const [cambio, setCambio] = useState<number>(0);
  const [nivel, setNivel] = useState<number>(NIVELINICIAL);
  const [nivelGanado, setNivelGanado] = useState<number>(1);
  const [puntaje, setPuntaje] = useState<number>(0);
  const [cargado, setCargado] = useState<boolean>(false);
  const [nivelSeleccionado, setNivelSeleccionado] = useState<number | null>(
    null
  );

  const seleccionarNivel = (nuevoNivel: number) => {
    setNivelSeleccionado(nuevoNivel);
    setNivel(nuevoNivel);
  };

  const getNuevoNivelRacha = (resultado: number) => {
    let nuevoCambio = cambio;

    if (resultado === 1) {
      setNivelGanado(nivel);
      nuevoCambio =
        nuevoCambio < CAMBIONIVELBASE ? CAMBIONIVELBASE : nuevoCambio * 1.5;
    } else {
      // Reducción de nivel más agresiva en caso de respuesta incorrecta
      nuevoCambio = -nivel * 0.25; // Reducir el nivel por un valor fijo de 1
    }

    let nuevoNivel = nivel + nuevoCambio;
    nuevoNivel = nuevoNivel < 1 ? 1 : nuevoNivel; // El nivel no puede ser menor que 1

    return [nuevoNivel, nuevoCambio];
  };

  const GuardarNeuronasCalificacion = (nivelGuardado: number) => {
	let neuronasNuevas = obtenerNeuronas(nomProgSumas1, sumaTotal.current, diferenciaTotal.current);
	console.log("Neuronas nuevas calculadas:", neuronasNuevas);

    const neuronasTotales = neuronas + neuronasNuevas;
    dispatch(initialNeuronas({ cantidad: neuronasTotales }));

    console.log(`Guardando Nivel: ${nivelGuardado}`); // Verificar que el nivel guardado es el correcto
    if (idActividad !== "juego_linea") {
      GuardarRegistroSumas(
        userId,
        { puntaje: Math.round(neuronasNuevas), nivel: nivelGuardado },
        state.nombres,
        state.pais
      );
    } else {
      dispatch(
        terminadoJuego({
          ultimoResultado: Math.round((neuronasNuevas / 190) * 100),
          tiempo: 0,
          finalizado: true,
        })
      );
    }

    setPuntaje(neuronasNuevas);
  };

  async function HandleBackButtonClick(): Promise<void> {
    // Guardar neuronas y nivel en BD antes de volver
    if (partida >= MAXPARTIDAS) {
        await GuardarNeuronasCalificacion(nivel);
    }

    if (esEntrenamiento) {
      await dispatch(
        newActivity({ id: idEntrenamiento, name: "entrenamiento" })
      );
      await dispatch(changePrograma({ id: idEntrenamiento }));
      dispatch(changeCondicion({ condicion: 10 }));
      navigate(-2);
    } else {
      if (idActividad === "juego_linea") {
        dispatch(changePuntaje({ verPuntaje: true }));
      } else {
        navigate(-1);
      }
    }
}

  const Constructor = () => {
    if (
      idActividad !== "juego_linea" &&
      nivelSeleccionado === null &&
      partida === 0
    ) {
      getCalificacionGeneral(userId, nomProgSumas1).then((calificacion) => {
        if (calificacion && calificacion.nivel) {
          console.log(`Nivel desde BD: ${calificacion.nivel}`);
          setNivel(calificacion.nivel); // Solo al inicio
          setNivelGanado(calificacion.nivel);
        } else {
          setNivel(6); // Nivel por defecto
          setNivelGanado(1);
        }
        setCargado(true);
      });
    } else {
      setCargado(true);
    }
  };

  const HandlePlay = () => {
    setEstado(1);
  };

const onReinicio = (diferencia: number, suma: number) => {
    sumaTotal.current += suma;
    diferenciaTotal.current += diferencia;

    const [nuevoNivel, nuevaRacha] = getNuevoNivelRacha((suma - diferencia) / suma);

    // Actualizar nivel solo si no ha sido seleccionado manualmente
    if (nivelSeleccionado === null) {
        console.log(`Nuevo Nivel Calculado: ${nuevoNivel}`);	
        setNivel(nuevoNivel);

        // Actualizar nivelGanado al nivel más alto alcanzado durante el juego
        if (nuevoNivel > nivelGanado) {
            setNivelGanado(nuevoNivel);
        }
    }

    setCambio(nuevaRacha);

    // Verificar si hemos alcanzado el máximo de partidas
    if (partida + 1 >= MAXPARTIDAS) {
        // Calcular y mostrar las neuronas nuevas inmediatamente
        GuardarNeuronasCalificacion(nivel);
    }

    // Actualizar el estado de la partida
    setPartida((prev) => prev + 1);
    setEstado(2); // Cambiar estado para mostrar la siguiente pantalla
};


const onSiguiente = () => {
    if (partida >= MAXPARTIDAS) {
        GuardarNeuronasCalificacion(nivel);

        if (idActividad === 'juego_linea') HandleBackButtonClick();
        sumaTotal.current = 0;
        diferenciaTotal.current = 0;
        setCambio(0);
        setPartida(0);
        setEstado(0);
    } else {
        setEstado(1);
    }
};



  useEffect(() => {
    Constructor();
  }, [Constructor, state._usuarioDato.correo]);

  return (
    <div
      id="page"
      style={idActividad === "juego_linea" ? { height: "100%" } : {}}
    >
      {(idActividad !== "juego_linea" || estado === 0) && (
        <div
          className={
            estado === 0
              ? estiloJuegos.toolbarCalculoMatematico
              : estiloJuegos.toolbar_default
          }
        >
          {esJuego && (
            <div
              className={estiloJuegos.backJuegos}
              onClick={HandleBackButtonClick}
            ></div>
          )}
          <h1
            className={`title ${estiloJuegos.tituloJuegos}`}
            style={estado !== 0 ? { fontSize: "1.25rem" } : {}}
          >
            {titulo}
          </h1>
        </div>
      )}
      {estado === 0 && (
        <div className={estiloJuegos.contenedorIconoMatematico}>
          <img
            src="/assets/componentes/Juegos/CÁLCULO MATEMÁTICO.svg"
            className={estiloJuegos.icono}
            alt="Ícono de cálculo matemático"
          />
        </div>
      )}
      <div id="content_default" style={{ maxHeight: "84svh" }}>
        {estado === 0 && (
          <div className={estiloJuegos.primeraPantalla}>
            <div
              className={estiloJuegos.descripcionJuego}
              style={{ margin: "auto" }}
            >
              Suma en tiempo real cada número que aparezca.
            </div>

            {(state._usuarioDato.correo === "showsupercerebros@gmail.com" ||
              state._usuarioDato.correo === "juanpabloduque@gmail.com") && (
              <div>
                <Selector
                  setSelection={seleccionarNivel}
                  selection={nivel}
                  style_contain={{
                    border: "1px solid var(--color-Pink-Light)",
                  }}
                  opciones={arregloNumeros}
                />
              </div>
            )}

            <button
              className={estiloJuegos.buttonEmpezar}
              style={{ margin: "auto" }}
              onClick={HandlePlay}
            >
              EMPEZAR
            </button>

            {esJuego && (
              <button
                id="button_bordes_default"
                onClick={() => navigate("/my/juegos/ranking")}
              >
                RANKING
              </button>
            )}
          </div>
        )}

        {estado === 1 && !cargado && <Loading isOpen={true} />}

        {estado > 0 && estado <= 2 && (
          <ProgressBar tamaño={MAXPARTIDAS} posicion={partida} />
        )}

        {cargado && (
          <PartidaSumas estado={estado} nivel={nivel} onReinicio={onReinicio} />
        )}

        {estado === 2 && (
          <>
            {partida >= MAXPARTIDAS && (
              <p className={style.subMensaje} style={{ fontSize: "3svh" }}>
                Tu Puntaje: <span>{puntaje}</span>
              </p>
            )}

            {idActividad === "juego_linea" ? (
              <button
                className={estiloJuegos.buttonEmpezar}
                style={{ width: "95%" }}
                onClick={onSiguiente}
              >
                {partida >= MAXPARTIDAS ? "FINALIZAR" : "SIGUIENTE"}
              </button>
            ) : (
              <DosBotonesColumna
                textoSuperior={
                  partida < MAXPARTIDAS ? "SIGUIENTE" : "JUGAR DE NUEVO"
                }
                textoInferior={
                  esJuego
                    ? "VOLVER"
                    : partida < MAXPARTIDAS
                    ? "RENDIRSE"
                    : "FINALIZAR"
                }
                onClickSuperior={onSiguiente}
                onClickInferior={HandleBackButtonClick}
                style={{ width: "80%", margin: "3svh 0 4svh 0" }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default JuegoSumas;