import { createSlice } from '@reduxjs/toolkit';
import { ActividadRedux } from './models/ActividadRedux';
import { reducerActividad } from './actions/recucerActividad';

const initialState: ActividadRedux = {
    name: null,
    id: null,
}

export const authSliceActivity = createSlice({
    name: 'activity',
    initialState,
    reducers: reducerActividad
});

export const { guardarSubtitulo, addParametroBloqueo, addTipoPregunta, changeCondicion, changePrograma, checkOutActivity, newActivity, newTorneo} = authSliceActivity.actions;