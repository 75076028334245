
export const niveles = [[0, 0, 0, 0],
[1, 10, 30, 120]];

export const calificacionPalabras = (nroNivel, respuesta) => {
    const respuestaTotal = niveles[nroNivel][1];
    return Math.floor((respuesta * 10) / respuestaTotal);
}

export const palabras = [
    "todo","tina","toma","moto","mano","nada","mono","tono","nota","tema","mina",
    "dona","dato","dedo","dama","nido","duna","timo","moda","manta","mato","tono",
    "mina","doma","dona","toma","meta","ñandú","tonta","día","tina","dime","manía",
    "tona","doña","adn","timón","nimio","nanda","monte","nene","mamá","mando",
    "manto","tendí","ten","tendón","tendí","mente","mino","mondé","mónada","tienta",
    "tándem","menú","tonto","anónima","antón","dañó","diman","tonta","tándem",
    "tanteo","tendón","maté","monte","ondea","mandato","diente","don","mina",
    "diente","endo","den","amando","tantea","tinto","teína","nomada","ande","denota",
    "minan","tono","madame","adonde","ten","tante","adonde","mañana","denuedo",
    "medan","donan","todo","onde","mono","denota","manda","adn","dote","denota",// indices 0-99 nivel 1
    /*------------------------------*/
    "reloj","regla","largo","jarrón","loro","gallo","gol","jarra","gorra","rojo","rima","llora",
    "ligero","gorila","relajo","riego","lloro","gel","gloria","llegar","logro","rallar","relajar",
    "gorgojo","gargajo","rollo","ramita","juramento","jaleo","rallar","júramelo","gorrión","lago","llenar",
    "general","lograr","leer","llaga","jarra","lujo","grillar","llamear","jolgorio","llano","ligar",
    "relleno","girar","lila","jalar","rogar","gajo","galleta","alargar","garganta","grano","roer",
    "grillar","golear","lograr","gritar","regar","llamar","lagrimear","tigre","tragar","aligerar","grulla",
    "rollo","alegría","arreglar","lograr","relatar","jarro","delirio","delineado","allegar","girar","allegar",
    "jeringa","gallina","real","alegre","gelatina","jaleo","gorjear","gil","relajo","llagar","llegar",
    "galla","arrollar","arrojar","allegar","allegro","gallardo","regar","regir","jerga","jalar","jalaron",// indices 100-199 nivel 2
    /*------------------------------*/
    "casa","café","vaso","pizza","beso","jefe","vaso","beso","cepa","paz","pez",
    "foco","cabo","pez","cepa","base","capa","pesa","sofá","cava","papa","pavo",
    "copa","sopa","paso","caso","boca","saco","peso","paz","baza","feo","cabo","paso",
    "foca","sopa","vaca","seco","cepo","cabo","pico","zapato","pesa","fase","cima",
    "piso","foso","buzo","besa","casa","piso","pasa","copa","cifra","saca","pez",
    "base","vaso","cepo","casa","zapato","foco","vaca","base","pesa","pasa","zafa",
    "café","cepa","foca","caso","cabo","pazo","peso","cima","sopla","vacía","pesa",
    "pieza","sopa","fresa","zoco","café","cepa","besa","capa","foso","cabo","pico",
    "pesa","fase","vacío","soca","sofoco","bota","pavo","pizza","cabra","copa","cepa",// indices 200-299 nivel 3
    //indices 0-299 nivel 4
];