import { Route } from "react-router-dom";
import PagoPage from "../PagoPage";
import PoliticasPrivacidad from "./componentes/politicas_privacidad/PoliticasPrivacidad";
import DatosPersonalesPage from "./DatosPersonalesPage";
import EditarUsuarioPage from "./EditarUsuarioPage";
import SettingsPage from "./SettingsPage";
import React from "react";

const useRutasPerfil = () =>{
    
    return(
        <React.Fragment>
            {/* datosUsuario */}
            <Route path="settings" element={<SettingsPage />}/>	
            <Route path="editarUsuario" element={<EditarUsuarioPage />}/>
            <Route path="datosPersonales" element={<DatosPersonalesPage />}/>
            <Route path="PoliticasPrivacidad" element={<PoliticasPrivacidad />}/>
        </React.Fragment>
    );
}

export default useRutasPerfil;