import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

// estilos importados
import stylePrograma from "../../../../../../theme/admin/programa.module.css";
import styleVistaPrograma from "../../../../../../theme/entrenamiento/componentes/VistaPrograma.module.css";
import estiloJuegos from "../../../../../../theme/Juegos.module.css";
import '../../../../../../theme/entrenamiento/entrenamiento.css';

import { test_general } from '../../../../../../datos/DatosTests';
import { nomProgSuperCerebrosDice } from '../../../../../../datos/nombresBD';
import { Actividad } from '../../../../../../modelos/entrenamiento/Actividad';
import { newActivity, changePrograma, addParametroBloqueo, changeCondicion } from '../../../../../../redux/actividad/reducerActividad';
import { useAppSelector, useAppDispatch } from '../../../../../../redux/store';



interface Props {
  handleBack:() => Promise<void>;
  setLoading:React.Dispatch<React.SetStateAction<boolean>>;
  urlRedirect:string;
  actividad:Actividad;
  firstTime:(0|1|2);
  GuardarRegistro: () => Promise<void>;
}

const VistaDeJuego:React.FC<Props> = (props) => {
  const idEntrenamientoEstatico = useAppSelector((state) => state.entrenamiento.current_entrenamiento?.id);
  const confirmacion = useAppSelector((state) => state.activity.confirmacion);
  const dispatch = useAppDispatch();  
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      ComponentWillUnmount()
    }
  }, [])    
  
  const ComponentWillUnmount = async() => {
    console.log("desmontado video")
    const largo = document.getElementById('el-body').clientHeight ;
    const ancho = document.getElementById('el-body').clientWidth ;
    if((largo*3/4) >= ancho){
      document.getElementById('el-body').style.background = '#ffffff' ;
    }else{
      document.getElementById('el-body').style.background = 'linear-gradient(180deg, #45245F 0%, #D74285 100%)' ;
    }
  }
  

  const HandleClickPrograma = async() => { 
    dispatch(newActivity({id:test_general, name:'entrenamiento'}))
    dispatch(changePrograma({id:nomProgSuperCerebrosDice}))
    dispatch(addParametroBloqueo({tiene_bloqueo:0}))
    dispatch(changeCondicion({condicion:0}))
    if(props.firstTime === (0|2)){
      Guardado()
    }
    if(typeof(props.actividad?.ruta) === 'string') navigate(props.actividad?.ruta);
  }

  const Guardado = async() => {
    dispatch(newActivity({id:"entrenamiento", name:'entrenamiento'}))
    dispatch(changePrograma({id:idEntrenamientoEstatico}))
    props.GuardarRegistro();
  }
  const FunctionSalir = async() => {
    dispatch(newActivity({id:"entrenamiento", name:'entrenamiento'}))
    dispatch(changePrograma({id:idEntrenamientoEstatico}))
    return props.handleBack();
  }
    
    
  return (
    <>
      <div className={`${estiloJuegos.toolbarCalculoFecha} ${styleVistaPrograma.bannerPrograma}`}>
        <div style={{display:'flex'}}>
          <button className={`${stylePrograma.backButton} ${styleVistaPrograma.backButton}`} onClick={FunctionSalir}/>
          <h1 className={`${styleVistaPrograma.title} ${estiloJuegos.tituloJuegos}`} > {props.actividad?.titulo} </h1>
        </div>
      </div>
      <div className={`${estiloJuegos.contenedorIconoFecha} ${styleVistaPrograma.contenedorPrograma}`}>
          <img src={'/assets/icon/icon_juegos.svg'} className={styleVistaPrograma.juegosIcono}></img>
        </div>
      <div className={stylePrograma.content}>
        <div className={estiloJuegos.primeraPantalla}>
          <p className={estiloJuegos.descripcionJuego}>{props.actividad.descripcion}</p>
          <button className={`${estiloJuegos.buttonEmpezar} ${styleVistaPrograma.buttonEmpezar}`}  onClick={HandleClickPrograma}>
            {confirmacion ? 'JUGAR DE NUEVO' :'EMPEZAR'} 
          </button>
          <button onClick={FunctionSalir} className={styleVistaPrograma.buttonVolver}>
            volver
          </button>
          </div>    
      </div>
    </>
  )
}

export default VistaDeJuego
