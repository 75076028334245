import "../../../../../theme/main.css";
import style from "../../../../../theme/ranking.module.css";
import "../../../../../theme/ranking.module.css";


import { MedallaRanking } from "./MedallaRanking";
import { BannerTablaNeuronas } from "./BannerTablaNeuronas";
import { Calificacion } from "../../../../../modelos/Calificacion";
import { useAppSelector } from "../../../../../redux/store";

interface Props {
    rankingData: Calificacion[];
    noMostrarResultado:Boolean;
    lectura:Boolean;
    type: string;
    setOpenDetalle: (boolean) => void;
    setSelectCalificacion: (value:Calificacion) => void;
    tipo?:string;
}

export const RankingNeuronas:React.FC<Props> = (props) => {
    const userId = useAppSelector((state) => state.auth.userId);
    const userIndex = props.rankingData.findIndex((item) => item.id === userId);

    const handleClick = (value:Calificacion) => {
        props.setSelectCalificacion(value);
        props.setOpenDetalle(true);
    }

    return(
        <>
            {props.tipo === 'TOTAL' || props.tipo === 'SEMANAL' && <BannerTablaNeuronas tipo={props.tipo}/>}
            <div className={style.titulos}>
                <p>RANK</p>
                <p>NOMBRES</p>
                <p>RESULTADO</p>
            </div>
            {props.rankingData.length !== 0 ? (
                <>
                {userIndex+1>10 ?
                <>
                    {props.rankingData.slice(0,5).map((item,index) => (
                        <div className={`${style.filaRanking} ${index === 0 || index === 2 ||index === 1 ? style.filaRanking_morado : ''}`} key={index} onClick={() => handleClick(item)}>
                            {index === 0 || index === 2 || index === 1 ? 
                                <MedallaRanking position={index+1}/>
                                :<p className={[style.rankNumeral,"table-col"].join(" ")}>#{index+1}</p>
                            }
                            <p className={style.tableCol}>{item.nombre}</p>
                            <p className={style.tableCol}>{(item.total).toFixed(0)}</p>
                            {index === 0 && <br></br>}
                        </div>
                    ))
                    }
                    <div className={style.tresPuntos}>...</div>
                    
                    
                    {props.rankingData.slice(userIndex-1,userIndex+2).map((item,index) => (
                        <div className={`${style.filaRanking} ${(userIndex+index == userIndex+1) ? style.filaRanking_rosado : ''}`} key={index} onClick={() => handleClick(item)}>               
                            <p className={[style.rankNumeral,"table-col"].join(" ")}>#{userIndex+index}</p>
                            <div className="table-col">{item.nombre}</div>
                            <div className="table-col">{(item.total).toFixed(0)}</div>
                            {index === 0 && <br></br>}
                        </div>
                    ))
                    }
                </>
                :<>{props.rankingData.slice(0,10).map((item,index) => (
                        <div className={`${style.filaRanking} ${index === 0 || index === 2 ||index === 1 ? style.filaRanking_morado : ''} ${(index === userIndex) ? style.filaRanking_rosado : ''}`} key={index} onClick={() => handleClick(item)} >
                            {index === 0 || index === 2 || index === 1 ? 
                                <MedallaRanking position={index+1}/>
                                :<p className={[style.rankNumeral,"table-col"].join(" ")}>#{index+1}</p>
                            }
                            <div className={style.tableCol} >{item.nombre}</div>
                            <div className={style.tableCol} >{(item.total).toFixed(0)}</div>
                            {index === 0 && <br></br>}
                        </div>
                    ))
                    }
                </>
                }
                </>
            ) : "No hay ranking aún"}
        </>
            
    );
}

export default RankingNeuronas;