import styles from '../theme/Repaso.module.css';

interface SwitchButtonProps {
  isActive: boolean;
  onToggle: (state: boolean) => void;
}

const SwitchButton: React.FC<SwitchButtonProps> = ({ isActive, onToggle }) => {
  const handleClick = () => {
    onToggle(!isActive);
  };

  return (
    <div className={styles.switchContainer} onClick={handleClick}>
      <div className={`${styles.switch} ${isActive ? styles.active : ''}`}>
        <div className={styles.circle}></div>
      </div>
      <span className={styles.label}>{isActive ? 'Test' : 'Repaso'}</span>
    </div>
  );
};

export default SwitchButton;
