import React, { Component } from 'react';
import '../../../../../../theme/Juegos.module.css';

interface VideoPlayerProps {
  videoUrl: string | string[];
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setNeuronasGanadas?: React.Dispatch<React.SetStateAction<number>>;
  autoplay?: boolean;
  poster?: string;
  preload?: string;
  adelantar?: boolean;
}

interface State {
  currentPosition: number;
  timeBeforeSeek: number | null;
}

class VideoPlayerIOs extends Component<VideoPlayerProps, State> {
  private videoRef: React.RefObject<HTMLVideoElement>;

  constructor(props: VideoPlayerProps) {
    super(props);
    this.state = {
      currentPosition: 0,
      timeBeforeSeek: null,
    };
    this.videoRef = React.createRef();
  }

  handleTimeUpdate = () => {
    if (this.videoRef.current) {
      this.setState({ currentPosition: this.videoRef.current.currentTime });
    }
  };

  handleSeeking = () => {
    if (this.videoRef.current) {
      this.setState({ timeBeforeSeek: this.videoRef.current.currentTime });
    }
  };
  
  handleSeeked = () => {
    this.setState({ timeBeforeSeek: null });
  };

  playComponent = (): void => {
    this.videoRef.current?.play();
  };

  pauseComponent = (): void => {
    this.videoRef.current?.pause();
  };

  componentWillUnmount(): void {
    if (this.videoRef.current) {
      this.videoRef.current.pause();
      this.videoRef.current.src = '';
    }
    if (this.props.setLoading) {
      this.props.setLoading(true);
      setTimeout(() => {
        this.props.setLoading(false);
      }, 1000);
    }
  }

  componentDidUpdate(prevProps: VideoPlayerProps) {
    if (this.props.videoUrl !== prevProps.videoUrl) {
      if (this.videoRef.current) {
        this.videoRef.current.pause();
        this.videoRef.current.load();
        this.videoRef.current.play();
      }
    }
  }

  handleAdelantar = () => {
    if (this.videoRef.current && this.videoRef.current.currentTime !== 0 && !this.props.adelantar) {
      this.videoRef.current.currentTime = 0;
      console.log('No se permite adelantar el video.');
    }
  };

  capturarInfoVideo = (): number => {
    if (this.videoRef.current) {
      let porcentaje = Number(
        ((this.videoRef.current.currentTime / this.videoRef.current.duration) * 100).toFixed(0)
      );
      porcentaje = isNaN(porcentaje) ? 0 : porcentaje;
      this.props.setNeuronasGanadas?.(porcentaje);
      return porcentaje;
    } else {
      return 0;
    }
  };

  render() {
    return (
      <div style={{ position: 'relative', margin: '0%', padding: '0%', alignItems: 'center', justifyContent: 'center', display: 'flex', height: '100%' }}>
        <video
          autoPlay={this.props.autoplay !== false}
          ref={this.videoRef}
          controls
          controlsList="nodownload"
          onSeeked={this.handleAdelantar}
          onTimeUpdate={this.handleTimeUpdate}
          onSeeking={this.handleSeeking}
          poster={this.props.poster ?? ""}
          preload={this.props.preload ?? ""}
          style={{ maxWidth: '100%', height: '100%' }}
          playsInline
        >
          {Array.isArray(this.props.videoUrl) ? (
            this.props.videoUrl.map((url, index) => <source key={index} src={url} type="video/mp4" />)
          ) : (
            <source src={this.props.videoUrl} type="video/mp4" />
          )}
          Tu navegador no soporta la reproducción de video.
        </video>
      </div>
    );
  }
}

export default VideoPlayerIOs;
