import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'
import TestMemoriaNumerosInicial from './programas/TestMemoriaNumerosInicial'
import TestMemoriaPalabrasInicial from './programas/TestMemoriaPalabrasInicial'
import TestSumas1 from './programas/TestSumas1'
import TestAdminPage from './TestAdminPage'
import TestInicialPage from './TestInicialPage'
import TestPage from './TestPage'

export const useRoutesTest = () => {
  return (
    <Fragment>
      <Route path="TestInicialPage/:idEntidad" element={<TestInicialPage />}/>							
      <Route path="TestPage" element={<TestPage />}/>							
      <Route path="TestAdminPage/:nombre" element={<TestAdminPage />}/>								
      <Route path="TestMemoriaPalabrasInicial" element={<TestMemoriaPalabrasInicial />}/>							
      <Route path="TestMemoriaNumerosInicial" element={<TestMemoriaNumerosInicial />}/>												
      <Route path="TestSumas1" element={<TestSumas1 />}/>					
    </Fragment>
  )
}
