import { useEffect, useLayoutEffect, useState } from "react";
import { Actividad } from "../../../../../../modelos/entrenamiento/Actividad";
import "../../../../../../theme/entrenamiento/entrenamiento.css";
import style from "../../../../../../theme/entrenamiento/entrenamientoActividades.module.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  EncuentraMenorARegistro,
  EstadosSesion,
  useProxyEntrenamiento,
} from "../../utils/UtilsEntrenamiento";
import Modal from "../../../../../components/Modal";
import ActividadBox from "../components/ActividadBox";
import NavigateComponent from "../../../../../components/Navigate";
import { ChangeRedux_finishSesion } from "../../../../../../redux/entrenamiento/reducerEntrenamiento";
import { useAppSelector, useAppDispatch } from "../../../../../../redux/store";
type Props = {
  idEntrenamiento: string;
  id: string;
};

const GeneralPage = () => {
  const { id, idEntrenamiento: idEntrenamientoURL } = useParams<Props>();
  const cargarProxy = useProxyEntrenamiento(idEntrenamientoURL, id);
  const finishSesion = useAppSelector(
    (state) => state.entrenamiento?.finishSesion
  );
  const currentEntrenamiento = useAppSelector(
    (state) => state.entrenamiento.current_entrenamiento
  );

  console.log(currentEntrenamiento.programas, "programs");

  const currentRegistroUsuarios = useAppSelector(
    (state) => state.entrenamiento?.current_registro_usuario
  );
  const currentSesionRedux = useAppSelector(
    (state) => state.entrenamiento?.current_sesion
  );
  const currentActividadesRedux = useAppSelector(
    (state) => state.entrenamiento?.current_actividades
  );

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [showFinish, setShowFinish] = useState<boolean>(
    finishSesion === Number(id)
  );
  const [loading, setLoading] = useState(!currentActividadesRedux);
  const [sesionList, setSesionList] = useState([]);
  useEffect(() => {
    console.log("🚀 ~ sesionList:", sesionList);
  }, [sesionList]);

  useLayoutEffect(() => {
    if (currentEntrenamiento) {
      setSesionList(
        currentEntrenamiento.programas
          .map((element) => {
            console.log(
               "TEST FUNCTION"
            )
            return {
              ...element,
              completado: EncuentraMenorARegistro(
                element.sesion + 1,
                0,
                currentRegistroUsuarios
              )
            };
          })
          .sort((a, b) => a.sesion - b.sesion) // Ordenar por número de sesión de menor a mayor
      );
    }
  }, [currentEntrenamiento]);

  useLayoutEffect(() => {
    cargarProxy();
    setShowFinish(finishSesion === Number(id));
  }, []);

  // Con la captura de info del url se determina si la sesion es completa, apenas se inicia o esta en progreso y asi da el acceso
  const Consulta = () => {

    setLoading(false);
  };
  
  console.log("🚀 ~ Consulta ~ sesionList:", sesionList)

  // Función de volver hacia atrás
  const HandleGoBakc = () => {
    return navigate(`/my/entrenamiento/${idEntrenamientoURL}`);
  };

  //Esta determina la redireccion a la sesion correspondiente a la seleccionada
  const HandleClick = (element: Actividad, index: number) => {
    if (element.completado) {
      return navigate(`/my/entrenamiento/${idEntrenamientoURL}/${id}/${index}`);
    }
  };

  useEffect(() => {
    if (currentSesionRedux === parseInt(id)) {
      if (currentActividadesRedux?.length === 1) {
        navigate(`0`);
      } else {
        Consulta();
      }
    }
  }, [currentSesionRedux, currentRegistroUsuarios]);

  const BundleShowFinish = () => {
    dispatch(ChangeRedux_finishSesion(null));
    HandleGoBakc();
  };

  console.log("show ", finishSesion === Number(id));
  useEffect(() => {
    setShowFinish(finishSesion === Number(id));
  }, [finishSesion]);

  return (
    <div id="page">
      <div
        className="contenedor_actividades fondo"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="contenedor_titulo_actividad">
          <button
            className="backButtonEntrenamiento"
            onClick={HandleGoBakc}
          ></button>
          <h1 className="dia" style={{ fontSize: "3em", lineHeight: "105%" }}>
            {" "}
            TODOS{" "}
          </h1>
        </div>
        <div
          className=""
          style={{
            marginTop: "3svh",
            flex: "1",
            display: "flex",
            flexDirection: "column",
          }}
        >

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "5% 10%",
            }}
            className={style.fuente_activity}
          >
            {sesionList.map((item, index) => {
              return (
                <NavigateComponent ruta={item.ruta} onclick={() => null} style={{    display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column"
              }}>
                  <ActividadBox
                    onClick={() => null}
                    status={item.completado}
                    title={
                      !item.nombre ? `Actividad ${index + 1}` : item?.nombre
                    }
                  ></ActividadBox>
                </NavigateComponent>
              );
            })}
            {sesionList?.length > 1 && (
              <button
                className={style.ButtonFinalizar_activity}
                style={
                  EncuentraMenorARegistro(
                    Number(id) + 1,
                    sesionList.length,
                    currentRegistroUsuarios
                  ) === EstadosSesion.COMPLETADO
                    ? { background: "var(--color-purple-dark)", color: "white" }
                    : {}
                }
                onClick={HandleGoBakc}
              >
                VOLVER
              </button>
            )}
          </div>
        </div>
        {showFinish && (
          <Modal
            className={`${style.animationSliceCenter} ${style.contentModal}`}
            onClickOutside={BundleShowFinish}
            open={showFinish}
          >
            <div className={style.imagenSCVolando} style={{ padding: "10%" }}>
              {currentRegistroUsuarios.completadoEntrenamiento
                      ? "Completaste la sesión ¡Sigue así!"
                      : "Completaste el entrenamiento ¡Felicidades!"}
              <button
                id="button_rosado_default"
                className={style.buttonModal}
                onClick={BundleShowFinish}
              >
                {currentRegistroUsuarios.completadoEntrenamiento
                  ? "Finalizar"
                  : "Siguiente Sesión"}
              </button>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default GeneralPage;
