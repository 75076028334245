import { Outlet, useNavigate, useParams } from "react-router-dom";

import { useEffect } from "react";
import { useAppSelector } from "../../../../../redux/store";
import Loading from "../../../../components/Loading";

type Param = {
    idEntrenamiento: string
}

const LayoutEntrenamiento = () => {
    const {idEntrenamiento} = useParams<Param>();

    const navigate = useNavigate();
    
    const loading_redux = useAppSelector((state) => state.entrenamiento.cargando);
    const asignados = useAppSelector((state) => state.entrenamiento.entrenamientos_user);
    const registros = useAppSelector(state => state.entrenamiento.registros_usuario);

    useEffect(() => {
        if(!loading_redux){
            if(!(asignados?.includes(idEntrenamiento))){
                navigate(`promocion/${idEntrenamiento}`);
            }else if(!(registros?.find(registro => registro.idEntrenamiento === idEntrenamiento))){
                navigate(`firstTime/${idEntrenamiento}`);
            }
        }
    }, [loading_redux])

    return (
        <>
        <Loading isOpen={loading_redux}/>
        {!loading_redux && <Outlet/>}
        </>
    )
}

export default LayoutEntrenamiento;